import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  matchPath,
} from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { FaChevronCircleUp } from 'react-icons/fa'
import TagManager from 'react-gtm-module'
import styled, { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from 'react-query'
import qs from 'qs'

import { GlobalStyles } from './global'
import { webhookErr } from './services/error'
import { getUser, setJWT } from './services/users'
import { useTheme, tryParseJSON } from './games/controller'
import can from './services/rbac'
import GameContext, { GameContextProvider } from './GameContext'
import { ModalContextProvider } from './contexts/ModalContext'
import { SocketProvider } from './contexts/SocketContext'
import { UserContextProvider } from './contexts/UserContext'
import './i18n'

import AdAnchor from './components/Ads/AdAnchor'
import Footer from './components/Footer'
import GetDetails from './components/GetDetails'
import Loading from './components/Loading'
import MobileNav from './components/Navbar/MobileNav'
import Navbar from './components/Navbar'
import PopupModal from './components/Popup/PopupModal'
import WebNotification from './components/Notifications/WebNotification'
import AchievementPopup from './components/Achievements/AchievementPopup'

// Home
import TraderieHome from './games/traderie/Home'
import Home from './pages/Home'
import HotItems from './pages/HotItems'
import SeeAll from './pages/SeeAll'
import Leaderboard from './pages/Leaderboard'
import AkrewPro from './pages/AkrewPro'
import AkrewProSuccess from './pages/AkrewPro/AkrewProSuccess'
import GameSuggestion from './pages/Home/GameSuggestion'

// Items
import OffersPage from './pages/OffersPage'
import Listing from './pages/Listing'
import Product from './pages/Product'
import ItemCreate from './pages/Product/ItemCreate'
import Search from './pages/Search'
import UserSearch from './pages/Search/UserSearch'
import CreateListingPage from './pages/CreateListingPage'
import Values from './pages/Values'

// User
import Chat from './pages/Chat'
import ResetPassword from './pages/Login/ResetPassword'
import ResendVerification from './pages/Login/ResendVerification'
import ForgotForm from './pages/Login/ForgotForm'
import Login from './pages/Login'
import OtpLogin from './pages/Login/OtpLogin'
import AppLogin from './pages/Login/AppLogin'
import CompleteProfile from './pages/Login/CompleteProfile'
import Logout from './pages/Login/Logout'
import Notifications from './pages/Notifications'
import Signup from './pages/Login/Signup'
import VerifyEmail from './pages/Login/VerifyEmail'
import MergeAccount from './pages/Login/MergeAccount'
import DeleteAccount from './pages/Login/DeleteAccount'
import Unsubscribe from './pages/Login/Unsubscribe'
import MultiFactor from './pages/MultiFactor'
import EditProfile from './pages/EditProfile'
import Achievements from './pages/Achievements'
import HowPointsWork from './pages/Shop/HowPointsWork'
import Avatar from './pages/Avatar'

// Help
import Article from './pages/Help/Articles/Article'
import CreateArticle from './pages/Help/Articles/CreateArticle'
import Moderators from './pages/Moderators'
import Page404 from './pages/Page404'

// Shop
import Shop from './pages/Shop'
import ShopItem from './pages/Shop/ShopItem'

import Comps from './pages/Comps'

import './index.css'
import 'flexboxgrid/css/flexboxgrid.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

// User
const Profile = lazy(() =>
  import(/*webpackChunkName: "Profile"*/ './pages/Profile')
)

const Help = lazy(() => import(/*webpackChunkName: "Help"*/ './pages/Help'))

const Admin = lazy(() => {
  if (can('ADMIN:PANEL')) {
    return import(/*webpackChunkName: "Admin"*/ './pages/Admin')
  } else {
    return null
  }
})

// Admin
const LoadAdminPanel = ({ routePrefix }) => {
  if (can('ADMIN:PANEL') && Admin) {
    return (
      <Suspense fallback={Loading}>
        <Route path={`${routePrefix}/admin`} component={Admin} />
      </Suspense>
    )
  } else {
    return null
  }
}

// Events
const Events = lazy(() =>
  import(/*webpackChunkName: "Events"*/ './pages/Events')
)

// Custom
const WinFairLose = lazy(() =>
  import(/*webpackChunkName: "WinFairLose"*/ './pages/WinFairLose')
)
const Upgrades = lazy(() =>
  import(/*webpackChunkName: "Upgrades"*/ './pages/Upgrades')
)

const appJWTData = (event) => {
  let { data } = event
  const routeEvent = tryParseJSON(data)
  if (routeEvent.jwttoken) {
    try {
      setJWT(routeEvent.jwttoken)
    } catch (error) {
      // console.log(error)
    }
  }
}

const StyledApp = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
`

const StyledAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media only screen and (max-width: 600px) {
    margin-top: ${(props) => !props.isApp && '50px'};
  }
`

function ErrorFallback({ error, resetErrorBoundary }) {
  return <Page404 resetErrorBoundary={resetErrorBoundary} />
}

const errorHandler = (error, info) => {
  return webhookErr(error.toString(), info.componentStack.substring(0, 1000))
}

function App({ match, location }) {
  useTranslation()
  const [theme, toggleTheme] = useTheme()
  const user = getUser()

  const [questModalOpen, setQuestModalOpen] = useState(false)

  let routePrefix = match.path
  if (routePrefix === '/') routePrefix = ''

  const query = qs.parse(location.search.substring(1))
  if (query.overwolf === 'true') {
    window.localStorage.setItem('overwolf', true)
  }

  const isApp = window.localStorage.getItem('app')
  const isIOS = window.localStorage.getItem('platform')

  useEffect(() => {
    if (isApp) {
      if (isIOS === 'true') {
        window.addEventListener('message', (event) => {
          appJWTData(event)
        })
      } else {
        document.addEventListener('message', (event) => {
          appJWTData(event)
        })
      }
    }

    window.addEventListener('scroll', () => {
      const scrollTop = document.getElementById('scroll-top')
      if (scrollTop) {
        scrollTop.style.visibility = 'hidden'
        if (window.innerWidth < 600) {
          if (window.scrollY > 800) scrollTop.style.visibility = 'visible'
        } else {
          if (window.scrollY > 1200) scrollTop.style.visibility = 'visible'
        }
      }
    })

    TagManager.initialize({
      gtmId:
        process.env.REACT_APP_DEPLOY === 'traderie'
          ? 'GTM-WWJJPQ8'
          : 'GTM-TZXMBR3',
    })
  }, [isApp, isIOS])

  useEffect(() => {
    // Track local storage for completed quests
    window.addEventListener('storageQuestComplete', () => {
      if (
        localStorage.getItem('questCompleted') &&
        process.env.REACT_APP_CAPTCHA_ENV === 'production'
      ) {
        setQuestModalOpen(true)
      }
    })
  }, [])

  // Unmount AchievementModal after opened
  useEffect(() => {
    if (questModalOpen) {
      setQuestModalOpen(false)
    }
  }, [questModalOpen])

  const mobileChat = matchPath(location.pathname, {
    path: `${routePrefix}/chat/:to?/:tab?`,
    exact: true,
  })

  const innerChat = matchPath(location.pathname, {
    path: [`${routePrefix}/chat/:to`],
    exact: true,
  })

  const appLogin =
    isApp &&
    matchPath(location.pathname, {
      path: `${routePrefix}/login`,
      exact: true,
    })

  const mobileLogin = matchPath(location.pathname, {
    path: `${routePrefix}/login`,
    exact: true,
  })

  const mobileSignUp = matchPath(location.pathname, {
    path: `${routePrefix}/signup`,
    exact: true,
  })
  return (
    <SocketProvider>
      <ThemeProvider theme={theme}>
        <UserContextProvider>
          <ModalContextProvider>
            <GlobalStyles />
            <GameContext.Consumer>
              {({ games, game, loadingGame }) => {
                if (
                  games.length > 0 &&
                  ![
                    ...games.map((game) => game.schema.replace('_', '')),
                    'nookazon',
                  ].includes(match.url.substring(1))
                )
                  routePrefix = ''

                return loadingGame || !game || games.length === 0 ? (
                  <Loading />
                ) : (
                  <StyledApp>
                    <StyledAppContainer isApp={isApp} gameName={game.name}>
                      <Navbar
                        toggleTheme={toggleTheme}
                        theme={theme}
                        mobileChat={mobileChat}
                        match={match}
                      />
                      <GetDetails />
                      <ErrorBoundary
                        onError={errorHandler}
                        FallbackComponent={ErrorFallback}
                        key={
                          location.pathname.split('/')[
                            routePrefix === '' ? 1 : 2
                          ]
                        }
                      >
                        <Switch
                          location={location}
                          key={
                            location.pathname.split('/')[
                              routePrefix === '' ? 1 : 2
                            ]
                          }
                        >
                          <Redirect
                            from='/:url*(/+)'
                            to={location.pathname.slice(0, -1)}
                          />
                          <Route
                            exact
                            path='/'
                            component={
                              process.env.REACT_APP_DEPLOY === 'traderie'
                                ? TraderieHome
                                : Home
                            }
                          />
                          <Route path='/error' component={Page404} />
                          <Route exact path='/akrewpro' component={AkrewPro} />
                          <Route
                            path='/akrewpro/success'
                            component={AkrewProSuccess}
                          />
                          <Route path='/login/otp' component={OtpLogin} />
                          <Route path='/login/app' component={AppLogin} />
                          <Route path='/login' component={Login} />
                          <Route path='/signup' component={Signup} />
                          <Route
                            path='/forgot-username'
                            render={(routeProps) => (
                              <ForgotForm type='Username' {...routeProps} />
                            )}
                          />
                          <Route
                            path='/forgot-password'
                            render={(routeProps) => (
                              <ForgotForm type='Password' {...routeProps} />
                            )}
                          />
                          <Route
                            path='/verify-email'
                            render={(routeProps) => (
                              <ResendVerification {...routeProps} />
                            )}
                          />
                          <Route
                            exact
                            path={`${routePrefix}/`}
                            render={(routeProps) => (
                              <Home {...routeProps} toggleTheme={toggleTheme} />
                            )}
                          />

                          <Route exact path={`${routePrefix}/latest`}>
                            <Redirect to={match.url} />
                          </Route>
                          <Route
                            exact
                            path={`${routePrefix}/hot`}
                            component={HotItems}
                          />
                          <Route
                            exact
                            path={`${routePrefix}/products/:type?/:category?`}
                            component={Search}
                          />
                          <Route
                            path={`${routePrefix}/listings/create`}
                            component={CreateListingPage}
                          />
                          <Route
                            path={`${routePrefix}/listings/:selling?`}
                            component={SeeAll}
                          />
                          <Route
                            path={[
                              `${routePrefix}/about`,
                              `${routePrefix}/faq`,
                              `${routePrefix}/report-users`,
                              `${routePrefix}/getting-started`,
                              `${routePrefix}/trading-guide`,
                              `${routePrefix}/submit-feedback`,
                              `${routePrefix}/updates`,
                              `${routePrefix}/community-guidelines`,
                              `${routePrefix}/privacypolicy`,
                              `${routePrefix}/termsofservice`,
                              `${routePrefix}/traderie-achievements`,
                              `${routePrefix}/stock-listings`,
                              `${routePrefix}/advertise`,
                              `${routePrefix}/robloxverify`,
                            ]}
                            component={Help}
                          />
                          <Route
                            exact
                            path={`${routePrefix}/guides`}
                            component={Help}
                          />
                          <Route
                            path={[
                              `${routePrefix}/guides/create`,
                              `${routePrefix}/guides/edit`,
                            ]}
                            render={(routeProps) => (
                              <CreateArticle {...routeProps} />
                            )}
                          />
                          <Route
                            path={`${routePrefix}/guides/:slug`}
                            render={(routeProps) => <Article {...routeProps} />}
                          />
                          {can('DATA:SUGGEST_ITEMS') && (
                            <Route
                              path={`${routePrefix}/product/suggest/:id?`}
                              component={ItemCreate}
                            />
                          )}
                          <Route
                            path={`${routePrefix}/product/:id`}
                            component={Product}
                          />
                          <Route
                            path={`${routePrefix}/listing/:id`}
                            component={Listing}
                          />
                          <Route
                            path={`${routePrefix}/offers`}
                            component={OffersPage}
                          />
                          <Redirect
                            from={`${routePrefix}/cart`}
                            to={`${routePrefix}/offers`}
                          />

                          <Route
                            path={[
                              `${routePrefix}/giveaways`,
                              `${routePrefix}/contests`,
                              `${routePrefix}/givingtuesday`,
                            ]}
                            component={Events}
                          />
                          {game.Creators && (
                            <Route
                              path={`${routePrefix}/creators`}
                              component={game.Creators}
                            />
                          )}
                          <Route
                            path={`${routePrefix}/moderators`}
                            component={Moderators}
                          />
                          <Route
                            path={`${routePrefix}/leaderboard`}
                            component={Leaderboard}
                          />
                          {user && (
                            <Route
                              path={`${routePrefix}/suggest`}
                              component={GameSuggestion}
                            />
                          )}
                          <Route
                            path={`${routePrefix}/activate/:activation_hash`}
                            component={VerifyEmail}
                          />
                          <Route
                            path={`${routePrefix}/forgotPassword/:forgotpwd_hash`}
                            component={ResetPassword}
                          />
                          <Route
                            path={`${routePrefix}/merge_account/:merge_hash`}
                            component={MergeAccount}
                          />
                          <Route
                            path={`${routePrefix}/complete-profile`}
                            component={CompleteProfile}
                          />
                          <Route
                            path={`${routePrefix}/delete_account/:delete_hash`}
                            component={DeleteAccount}
                          />
                          <Route
                            path={`${routePrefix}/unsubscribe/:email`}
                            component={Unsubscribe}
                          />
                          <Route
                            path={`${routePrefix}/logout`}
                            component={Logout}
                          />
                          <Route
                            path={`${routePrefix}/profile/:user?`}
                            component={Profile}
                          />
                          {user && (
                            <Redirect
                              from={`${routePrefix}/settings`}
                              to={`${routePrefix}/profile/${user.id}/settings`}
                            />
                          )}
                          {user && (
                            <Route
                              path={`${routePrefix}/multifactor`}
                              component={MultiFactor}
                            />
                          )}
                          {user && (
                            <Redirect
                              from={`${routePrefix}/reviews`}
                              to={`${routePrefix}/profile/${user.id}/reviews`}
                            />
                          )}
                          <Route
                            path={`${routePrefix}/chat/:to?`}
                            component={Chat}
                          />
                          <Route
                            path={`${routePrefix}/users`}
                            component={UserSearch}
                          />
                          <Route
                            path={`${routePrefix}/notifications`}
                            component={Notifications}
                          />
                          <Route
                            path={`${routePrefix}/edit-profile`}
                            render={(routeProps) => (
                              <EditProfile {...routeProps} />
                            )}
                          />
                          <Route
                            path={`${routePrefix}/avatar`}
                            component={Avatar}
                          />
                          <Route
                            path={`${routePrefix}/achievements`}
                            component={Achievements}
                          />
                          <Route
                            exact
                            path={`${routePrefix}/shop/:type?`}
                            render={(routeProps) => <Shop {...routeProps} />}
                          />
                          <Route
                            exact
                            path={`${routePrefix}/shop/item/:id?`}
                            render={(routeProps) => (
                              <ShopItem {...routeProps} />
                            )}
                          />
                          {game.has('COMPS:ENABLED') && (
                            <Route
                              path={`${routePrefix}/${game.getText('comp')}s`}
                              component={Comps}
                            />
                          )}
                          <Route
                            path={`${routePrefix}/howpointswork`}
                            component={HowPointsWork}
                          />
                          {game.has('ITEMS:VALUE_LIST') && (
                            <Route
                              path={`${routePrefix}/values/:type?`}
                              component={Values}
                            />
                          )}
                          {game.has('ITEMS:VALUE_LIST') && (
                            <Route
                              path={`${routePrefix}/wfl`}
                              component={WinFairLose}
                            />
                          )}
                          {game.has('PLUGIN:UPGRADES') && (
                            <Route
                              path={`${routePrefix}/upgrades`}
                              component={Upgrades}
                            />
                          )}
                          <LoadAdminPanel routePrefix={routePrefix} />
                          <Route path='*'>
                            <Page404 />
                          </Route>
                        </Switch>
                      </ErrorBoundary>
                    </StyledAppContainer>
                    {!mobileChat && (
                      <div style={{ textAlign: 'center' }}>
                        <FaChevronCircleUp
                          onClick={() => {
                            window.scrollTo(0, 0)
                          }}
                          id='scroll-top'
                          className='scroll-to-top'
                        />
                      </div>
                    )}
                    {!mobileChat && !appLogin && !isApp ? (
                      <Footer location={location} />
                    ) : (
                      <div
                        style={{
                          marginTop: mobileChat ? 0 : 60,
                        }}
                      ></div>
                    )}
                    {!innerChat && <MobileNav isApp={isApp} />}
                    {!isApp && <PopupModal />}
                    {questModalOpen && <AchievementPopup />}
                    {process.env.NODE_ENV !== 'development' && <AdAnchor />}
                  </StyledApp>
                )
              }}
            </GameContext.Consumer>
          </ModalContextProvider>
        </UserContextProvider>
        <WebNotification />
      </ThemeProvider>
    </SocketProvider>
  )
}

// query client that caches data for 30 seconds
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 30000,
    },
  },
})

function AppWrapper() {
  const path =
    process.env.REACT_APP_DEPLOY === 'traderie' ? [`/:game`, '/'] : '/'

  return (
    <React.StrictMode>
      <Suspense fallback={''}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Switch>
              <Route
                path={path}
                render={(routeProps) => (
                  <GameContextProvider match={routeProps.match}>
                    <App {...routeProps} />
                  </GameContextProvider>
                )}
              />
            </Switch>
          </Router>
        </QueryClientProvider>
      </Suspense>
    </React.StrictMode>
  )
}

export default AppWrapper
