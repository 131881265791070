import React from 'react'
import { Trans } from 'react-i18next'
import qs from 'qs'

import Listings from '../../components/Listings/Listings'
import { StyledTab } from '../../components/Styled'

const ListingsHome = ({ game, routePrefix, match, location, history }) => {
  const { url } = match
  const query = qs.parse(location.search.substring(1))
  const { page } = query

  return (
    <>
      {game.has('LISTINGS:AUCTIONS') && (
        <span className='home-tabs'>
          <StyledTab
            to={`${url}`}
            isActive={() => {
              return !page
            }}
            style={{ padding: '10px 64px' }}
          >
            <Trans i18nKey='listings' />
          </StyledTab>
          <StyledTab
            to={`${routePrefix}/listings/auctions?orderBy=endtime-asc`}
            isActive={() => {
              return page === 'auctions'
            }}
            style={{ padding: '10px 64px' }}
          >
            <Trans i18nKey='auctions' />
          </StyledTab>
        </span>
      )}
      {/* @ts-ignore */}
      <Listings
        location={location}
        history={history}
        filters={[
          'free',
          'makeOffer',
          'rating',
          'sort',
          'types',
          'properties',
          'create',
        ]}
        home={true}
      />
    </>
  )
}

export default ListingsHome
