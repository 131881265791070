import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Trans } from 'react-i18next'
import { FaCheckCircle, FaEnvelopeOpenText } from 'react-icons/fa'

import GameContext from '../../GameContext'
import AdSlot from '../../components/AdSlot'
import Offers from '../../components/Offers'
import { StyledTab } from '../../components/Styled'

import './style.css'

const OffersPage = ({ match }) => {
  const { game, routePrefix } = useContext(GameContext)
  const { url } = match

  return (
    <div className='cart'>
      <Helmet>
        <title>
          Offers | {game.getTitle()} | {game.getSite()}
        </title>
        <meta
          name='description'
          content={`View all of your ${game.getSite()} offers`}
        />
      </Helmet>
      <AdSlot
        name='pb-slot-incontent-1'
        divId='cart-ad-1'
        style={{ marginTop: 0 }}
      />
      <div className='container'>
        <div>
          <StyledTab
            to={`${routePrefix}/offers/made/open`}
            isActive={(match, location) => {
              if (
                (!match && location.pathname.includes('offers/made')) ||
                match
              )
                return true
              return false
            }}
          >
            <FaEnvelopeOpenText style={{ verticalAlign: 'middle' }} />
            &nbsp;&nbsp;
            <Trans i18nKey='navOffersMade' />
          </StyledTab>
          <StyledTab
            to={`${routePrefix}/offers/received/open`}
            isActive={(match, location) => {
              if (
                (!match && location.pathname.includes('offers/received')) ||
                match
              )
                return true
              return false
            }}
          >
            <FaCheckCircle style={{ verticalAlign: 'middle' }} />
            &nbsp;&nbsp;
            <Trans i18nKey='navOffersReceived' />
          </StyledTab>
        </div>
        <Route path={`${url}`} exact>
          <Redirect to={`${url}/made/open`} />
        </Route>
        <Route exact path={`${url}/made`}>
          <Redirect to={`${url}/made/open`} />
        </Route>
        <Route exact path={`${url}/received`}>
          <Redirect to={`${url}/received/open`} />
        </Route>
        <Route
          path={`${routePrefix}/offers/made/:status?/:type?`}
          render={(routeProps) => (
            <Offers isCart isOffersPage url='/offers/made' {...routeProps} />
          )}
        />
        <Route
          path={`${routePrefix}/offers/received/:status?`}
          render={(routeProps) => (
            <Offers
              isSeller
              isOffersPage
              url='/offers/received'
              {...routeProps}
            />
          )}
        />
      </div>
      <AdSlot name='pb-slot-incontent-2' divId='cart-ad-2' />
    </div>
  )
}

export default OffersPage
