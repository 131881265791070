import React, { useContext } from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import {
  HiChatAlt2,
  HiHome,
  HiShoppingBag,
  HiLogin,
  HiUser,
} from 'react-icons/hi'

import GameContext from '../../../GameContext'
import { getUser } from '../../../services/users'

import { webAppPushToApp } from '../index'
import { StyledNavLink, ThemeDiv } from '../../Styled'
import NotificationView from '../../Notifications'
import Pfp from '../../User/Pfp'

const StyledMobileNav = styled(ThemeDiv)`
  display: ${({ isApp }) => (isApp ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 996;
  height: 50px;
  max-height: 50px;

  @media only screen and (max-width: 600px) {
    display: flex;
  }
`

export const MobileNavLink = styled(StyledNavLink).attrs({
  activeClassName: 'mobile-nav-active',
})`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 11px;
  line-height: 1;
  height: 100%;
  justify-content: center;
  &.mobile-nav-active {
    color: #3fc9b3;
  }
`

const MobileNav = ({ isApp }) => {
  const { routePrefix, game } = useContext(GameContext)
  const user = getUser()
  const isOverwolf = localStorage.getItem('overwolf')

  if (game.name === 'Traderie' && user) {
    return null
  }

  return (
    <StyledMobileNav isApp={isApp}>
      <MobileNavLink
        to={isOverwolf ? 'diablo4' : `${routePrefix}`}
        exact
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }}
      >
        <HiHome style={{ fontSize: 30 }} />
        <div>
          <Trans i18nKey='home' />
        </div>
      </MobileNavLink>
      {user && (
        <>
          <MobileNavLink
            id='mobileOffers'
            to={`${routePrefix}/offers`}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
              window.dataLayer.push({
                event: 'offers',
                eventProps: {
                  category: 'View Offers',
                  action: 'View Offers',
                },
                userId: user ? user.id : undefined,
                email: user ? user.email : undefined,
              })
            }}
          >
            <HiShoppingBag style={{ fontSize: 30 }} />
            <div>
              <Trans i18nKey='offers' />
            </div>
          </MobileNavLink>
          <NotificationView />
          <MobileNavLink
            to={`${routePrefix}/chat`}
            onClick={() => {
              window.scrollTo(0, 0)
            }}
          >
            <HiChatAlt2 style={{ fontSize: 30 }} />
            <div>
              <Trans i18nKey='dms' />
            </div>
          </MobileNavLink>
          <MobileNavLink
            to={`${routePrefix}/profile/${user.id}`}
            onClick={() => {
              window.scrollTo(0, 0)
            }}
          >
            <Pfp
              user={user}
              size={user?.profile_img ? undefined : '24px'}
              noBadge
              noFrame
            />
            <div style={{ marginTop: '3px', marginBottom: '-2px' }}>
              <Trans i18nKey='profile' />
            </div>
          </MobileNavLink>
        </>
      )}
      {!user && (
        <>
          <MobileNavLink
            to={`/login`}
            onClick={() => {
              isApp && webAppPushToApp('login')
              if (routePrefix) localStorage.setItem('loginReroute', routePrefix)
              window.scrollTo(0, 0)
            }}
          >
            <HiLogin style={{ fontSize: 30 }} />
            <div style={{ marginTop: '-5px' }}>
              <Trans i18nKey='logIn' />
            </div>
          </MobileNavLink>
          {!isApp && (
            <MobileNavLink
              to={`/signup`}
              onClick={() => {
                if (routePrefix)
                  localStorage.setItem('loginReroute', routePrefix)
                window.scrollTo(0, 0)
              }}
            >
              <HiUser style={{ fontSize: 30 }} />
              <div>
                <Trans i18nKey='signUp' />
              </div>
            </MobileNavLink>
          )}
        </>
      )}
    </StyledMobileNav>
  )
}

export default MobileNav
