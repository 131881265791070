import React, { useContext, useEffect, useState } from 'react'
import { itemHas } from 'item-mode'
import { useHistory, useLocation } from 'react-router-dom'
import lozad from 'lozad'
import styled from 'styled-components'
import { DateTime, Duration } from 'luxon'

import { StyledLink } from '../../Styled'

import ListingDate from './ListingDate'
import ListingIcon from './ListingIcon'
import ListingSeller from './ListingSeller'
import ListingProductInfo from './ListingProductInfo'

import GameContext from '../../../GameContext'
import { getUser } from '../../../services/users'
import { getItemName } from '../../../services/items'
import { calculate } from '../../../services/wfl'
import { WflResult } from '../../WinFairLose'

import './style.css'

const ListingItemDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${(props) =>
    props.vertical || props.isMobile ? 'column' : ''};
  position: relative;
`

const StyledWflLink = styled(StyledLink)`
  color: ${({ theme }) => theme.btnBackground};
  text-decoration: underline;
`

export const getListingDate = (date) => {
  let listingDate = DateTime.fromISO(date)
  return DateTime.now().diff(listingDate).as('days') > 2
    ? listingDate.toFormat('DDD')
    : listingDate.toRelative()
}

export const getTimeLeft = (date) => {
  let duration
  if (date) {
    duration = Duration.fromMillis(
      DateTime.fromISO(date).diffNow().as('milliseconds')
    ).toFormat("h'h' m'm' ss's'")
  } else {
    duration = 0
  }
  return duration <= 0 ? 'AUCTION OVER' : `Time Left: ${duration}`
}

const isMobile = window.innerWidth <= 600

const { observe } = lozad()

const ListingItem = ({
  gallery,
  hideContact,
  hideItemImgs,
  hideItemInfo,
  isChat,
  isListingPage,
  listing,
  listView,
  noClick,
  offer,
  offerIdx,
  product,
  profileLink,
  showDetails,
  showRecipe,
  slider,
  updateOffers,
  vertical,
  myProfile,
  adminPanel,
  suggestion,
}) => {
  const context = useContext(GameContext)
  const currUser = getUser()
  const location = useLocation()
  const history = useHistory()
  const [wflResult, setWflResult] = useState('')
  if (offer) listing = offer.listing
  const { item, listing_items, seller, selling } = listing
  const isBuyer =
    offer && offer.buyer && offer.buyer.id + '' === (getUser() || {}).id
  const isSeller = seller?.id + '' === currUser?.id
  const isOffer = offer !== undefined
  const propImgSrc =
    listing.properties &&
    listing.properties.find((prop) => prop.type === 'image')
  const propImage = propImgSrc

  const [counter, setCounter] = useState(getTimeLeft(listing.end_time))

  useEffect(() => {
    observe()
    setWflResult(calculate([listing?.item], offer?.prices))
  }, [])

  useEffect(() => {
    if (listing.end_time) {
      const timeLeft = getTimeLeft(listing.end_time)
      if (timeLeft !== 'AUCTION OVER') {
        setTimeout(() => setCounter(getTimeLeft(listing.end_time)), 1000)
      } else {
        setCounter(timeLeft)
      }
    }
  }, [listing.end_time, counter])

  const { routePrefix, game } = context
  const itemName = getItemName(item, listing.diy, listing.properties)
  const liveListing = itemHas(item.mode, 'LISTINGS:LIVE')
  const noPrice = itemHas(item.mode, 'ITEMS:NO_PRICING')
  const isCompactView = game.has('LISTINGS:COMPACT')

  return (
    <ListingItemDiv
      className={`${propImage && !isChat && 'listing-item-link-gallery'}`}
      style={{
        // backgroundColor: selected ? 'rgb(125, 125, 125, 0.1)' : '',
        cursor:
          profileLink || listing.wishlist_id || offer || noClick
            ? ''
            : 'pointer',
      }}
      onClick={(event) => {
        if (!profileLink) {
          if (listing.wishlist_id || offer || noClick)
            return event.preventDefault()

          const url = `${routePrefix}/listing/${listing.id}${
            listing.end_time ? '?auction' : selling ? '' : '?lookingFor'
          }`
          if (event.ctrlKey || event.metaKey) {
            window.open(url, 'blank')
          } else {
            event.preventDefault()
            history.push(url, { listingInfo: listing })
          }
        }
      }}
      isMobile={isMobile}
      vertical={vertical}
      listingActive={listing.active}
    >
      {!hideItemInfo && ((!isMobile && isCompactView) || !isCompactView) && (
        <div className={isOffer ? 'offers-only-info' : ''}>
          <span className='listing-item-body-col-1'>
            {!isCompactView &&
              (product
                ? propImage ||
                  (!game.has('ITEMS:NO_PRODUCT_IMGS') && !hideItemImgs)
                : suggestion
                ? !hideItemImgs
                : true) && (
                <ListingIcon
                  item={item}
                  listing={listing}
                  gallery={gallery}
                  listView={listView}
                  propImgSrc={propImage ? propImgSrc : ''}
                />
              )}
            {(!offer || (isBuyer && !hideItemInfo) || adminPanel) && (
              <ListingSeller
                user={seller}
                location={history.location}
                listView={listView}
                noLink={profileLink ? false : true}
                showDetails={showDetails}
                slider={slider}
                vertical={vertical}
              />
            )}
            {isCompactView && (
              <span
                style={{ display: 'block', height: slider ? '8px' : '24px' }}
              />
            )}
            {isCompactView && !myProfile && (
              <ListingDate
                isCompactView={isCompactView}
                counter={counter}
                offer={offer}
                listing={listing}
                slider={slider}
              />
            )}
          </span>
          {game.has('OFFERS:WFL') && isSeller && offer && offer.prices && (
            <div className='trade-value'>
              <span>
                Trade Value: <WflResult result={wflResult} />
              </span>
              <StyledWflLink
                to={{
                  pathname: `${routePrefix}/wfl`,
                  state: {
                    yourItems: listing?.listing_items
                      ? [...listing.listing_items]
                      : [listing.item],
                    theirItems: listing.prices
                      ? [...listing.prices]
                      : [...offer.prices],
                  },
                }}
                className='wfl-link'
                aria-label={itemName}
              >
                Check this trade on our W/F/L
              </StyledWflLink>
            </div>
          )}
        </div>
      )}
      <ListingProductInfo
        item={item}
        itemName={itemName}
        listing={listing}
        listingItems={listing_items}
        offer={offer}
        offerIdx={offerIdx}
        isCompactView={isCompactView}
        isListingPage={isListingPage}
        isOffer={isOffer}
        hideContact={hideContact}
        hideItemInfo={hideItemInfo}
        listView={listView}
        liveListing={liveListing}
        noPrice={noPrice}
        product={product}
        selling={selling}
        showDetails={showDetails}
        showRecipe={showRecipe}
        slider={slider}
        updateOffers={updateOffers}
      />
      {(!offer || (isBuyer && !hideItemInfo) || adminPanel) &&
        isCompactView &&
        isMobile && (
          <ListingSeller
            user={seller}
            location={history.location}
            listView={listView}
            noLink={true}
            showDetails={showDetails}
            slider={slider}
            vertical={vertical}
          />
        )}
      {((isMobile && isCompactView) || !isCompactView) && !myProfile && (
        <ListingDate
          counter={counter}
          offer={offer}
          listing={listing}
          slider={slider}
        />
      )}
    </ListingItemDiv>
  )
}

export default ListingItem
