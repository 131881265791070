import React from 'react'

import Modal from '../../Modal'

const ListingModActions = ({ listing, markListing, completeListing }) => {
  return (
    <div className='listing-actions'>
      {listing.end_time && (
        <div className='offer-action-btn-container'>
          <Modal
            title='Delete Troll Listing'
            onConfirm={() => {
              markListing(listing, true)
            }}
            label='Confirm'
            buttonLabel='Delete Troll Listing'
            btnClass='btn-alt'
            body={
              <div>
                Please ensure that this is a troll auction and should be
                deleted.
              </div>
            }
            onCancel={() => {}}
            keyDownEnable
          />
        </div>
      )}
      {!listing.end_time && (
        <button
          onClick={() => {
            completeListing(listing)
          }}
          className='btn-alt'
          aria-label='Complete Listing'
        >
          Complete Listing
        </button>
      )}
    </div>
  )
}

export default ListingModActions
