import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaYoutube } from 'react-icons/fa'
import ImageGallery from 'react-image-gallery'

import AdSlot from '../../components/AdSlot'
import CoverSlider from '../../components/Home/CoverSlider'
import Helmet from '../../components/Helmet'
import HomeCategories from '../../components/Home/HomeCategories'
import LatestListings from '../../components/Home/LatestListings'

const Home = ({ game }) => {
  let { game: gameName } = useParams()

  return (
    <div className='home'>
      <Helmet data={{ game: game.name }}>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: 'https://traderie.com',
            logo: 'https://cdn.nookazon.com/traderie/icons/traderie_icon.png',
          })}
        </script>
      </Helmet>
      <h1 style={{ display: 'none' }}>Trade {game.name} Items</h1>
      <CoverSlider
        game={gameName}
        twitchChannels
        ytChannels={['UC_Y31LRRYW5UIZCWXRddkFg', 'UCm-b1KZGernPve-s_s7l75w']}
        giveaway
      />
      <div className='home-section'>
        <div className='container'>
          <AdSlot
            name='pb-slot-incontent-1'
            divId='adoptme-home-ad-1'
            slots={[
              'pb-slot-incontent-1',
              'pb-slot-incontent-2',
              'pb-slot-incontent-3',
              'pb-slot-incontent-4',
            ]}
          />
          <LatestListings />
        </div>
      </div>
      <HomeCategories />
      <div className='home-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-md-3' style={{ marginBottom: 15 }}>
              <a
                href='https://www.youtube.com/watch?v=a1oKKeZs1uE'
                target='blank'
              >
                <img
                  src='https://cdn.nookazon.com/adoptme/home/megan_video.png'
                  alt='megan video'
                />
              </a>
            </div>
            <div className='col-xs-12 col-md-9' style={{ marginBottom: 15 }}>
              <ImageGallery
                items={[
                  {
                    original:
                      'https://cdn.nookazon.com/adoptme/home/meganplays.png',
                    description: (
                      <Link
                        to='/adoptme/profile/1571183335/listings'
                        style={{ color: 'white' }}
                        className='streamer-link'
                        aria-label='Megan Plays'
                      >
                        <img
                          src='https://cdn.nookazon.com/adoptme/roles/creator_badge.svg'
                          alt='badge'
                          className='role-badge'
                          style={{ width: 75, height: 75 }}
                        />
                        Megan Plays
                      </Link>
                    ),
                  },
                  {
                    original:
                      'https://cdn.nookazon.com/adoptme/home/elvebredd.png',
                    description: (
                      <div className='streamer-link'>
                        <img
                          src='https://cdn.nookazon.com/adoptme/roles/creator_badge.svg'
                          alt='badge'
                          className='role-badge'
                          style={{ width: 75, height: 75 }}
                        />
                        <div>
                          <Link
                            to='/adoptme/profile/66211950/listings'
                            style={{ color: 'white', textDecoration: 'none' }}
                            aria-label='Elvebredd'
                          >
                            Elvebredd
                          </Link>
                          <div>
                            <a
                              href='https://www.youtube.com/channel/UC_Y31LRRYW5UIZCWXRddkFg'
                              target='blank'
                              style={{ color: '#ff0000' }}
                            >
                              <FaYoutube />
                            </a>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
                autoPlay
                slideInterval={5000}
                showThumbnails={false}
                showFullscreenButton={false}
                lazyLoad={true}
                showPlayButton={false}
                sizes='16x16'
              />
            </div>
          </div>
          <AdSlot name='pb-slot-incontent-2' divId='adoptme-home-ad-2' />
        </div>
      </div>
    </div>
  )
}

export default Home
