import React, { useContext } from 'react'
import styled from 'styled-components'
import { HiUserCircle } from 'react-icons/hi'
import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'
// import { resizedImg } from '../../services/images'

import { StyledLink } from '../Styled'
import Image from '../Image'
import UserStatus from './UserStatus'
import Badge from './Badge'

// const DEFAULT_SIZE = 40
const DEFAULT_WIDTH = 32

const StyledPfp = styled.div`
  display: flex;
  margin-top: 5px;
`

const StyledProfileIcon = styled.div`
  border-radius: 50%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  overflow: hidden;
`

const UserStatusContainer = styled.span`
  position: absolute;
  right: -5px;
  top: ${({ chat }) => (chat ? '35px' : '20px')};
  z-index: 2;
  padding: 2px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.bodyAlt};
`

const PfpImage = ({
  user,
  size,
  game,
  style,
  noBadge,
  leaderboardBadge,
  overrideBadge,
  noFrame,
  overrideFrame,
  chat,
}) => {
  const { PfpIcon } = game.icons
  const { user: currUser } = useContext(UserContext)

  const { username, profile_img, customization } = user
  let { status } = user
  if (overrideBadge) user.badge_id = overrideBadge

  // const imgUrl = resizedImg(profile_img, DEFAULT_SIZE)
  // const resizingUrl = resizedImg(profile_img, DEFAULT_SIZE, true)

  const hasFrame = (!noFrame && customization?.frame) || overrideFrame
  const frameWidth = (size * 120) / 90
  const frameOffset = (-1 * (frameWidth - size)) / 2
  const isOverwolf = localStorage.getItem('overwolf')

  let muted
  if (user.roles) {
    muted = user.roles.find((role) => role.title === 'muted-user')
  }

  if (user && currUser) {
    if (muted && Number(user.id) === Number(currUser.id)) {
      status = currUser.status
    }
  }

  return (
    <StyledPfp style={style}>
      <div
        style={{
          position: 'relative',
          marginRight: 5 + (hasFrame ? -1 * frameOffset : 0),
        }}
      >
        <StyledProfileIcon size={size}>
          {profile_img ? (
            <Image
              src={profile_img}
              alt={`${username} profile`}
              style={{ objectFit: 'cover' }}
            />
          ) : PfpIcon ? (
            <PfpIcon
              size={'2em'}
              style={{ marginRight: '8px', fontSize: size || '2em' }}
            />
          ) : (
            <HiUserCircle
              size={'2em'}
              style={{ marginRight: '8px', fontSize: size || '2em' }}
            />
          )}
        </StyledProfileIcon>
        {hasFrame && (
          <div
            style={{
              position: 'absolute',
              top: frameOffset,
              left: frameOffset,
              width: frameWidth,
              zIndex: 1,
            }}
          >
            <img
              draggable={!isOverwolf}
              src={overrideFrame || customization.frame}
              alt='profile frame'
            />
          </div>
        )}
        {leaderboardBadge && (
          <div
            style={{
              position: 'absolute',
              top: 50,
              left: 60,
              height: 35,
              width: 35,
              zIndex: 1,
            }}
          >
            <Badge user={user} size={35} />
          </div>
        )}
        {status && (
          <UserStatusContainer chat={chat}>
            <UserStatus status={status} style={{ fontSize: 11 }} noLabel />
          </UserStatusContainer>
        )}
      </div>
      {!noBadge && <Badge user={user} size={30} width={size} />}
    </StyledPfp>
  )
}

const Pfp = (props) => {
  const { user, size, noLink, chat } = props
  if (!user) return null

  const { game, routePrefix } = useContext(GameContext)
  const width = size || DEFAULT_WIDTH

  return noLink ? (
    <PfpImage {...props} game={game} size={width} />
  ) : (
    <StyledLink
      to={`${routePrefix}/profile/${user.id}/${game.getProfileDefault()}`}
    >
      <PfpImage {...props} game={game} size={width} chat={chat} />
    </StyledLink>
  )
}

export default Pfp
