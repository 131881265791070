import React, { useEffect, useContext } from 'react'
import { FaSign, FaHandshake, FaPeopleCarry, FaPlus } from 'react-icons/fa'
import { Helmet } from 'react-helmet'
import { SkeletonTheme } from 'react-loading-skeleton'
import { ThemeContext } from 'styled-components'
import { Trans } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import lozad from 'lozad'
import qs from 'qs'

import UserContext from '../../contexts/UserContext'

import { getGames } from '../../services/games'
import { trackQuest } from '../../services/quests'
import {
  StyledLink,
  StyledButtonLink,
  StyledHyperLink,
} from '../../components/Styled'
import AdSlot from '../../components/AdSlot'
import Loading from '../../components/Loading'
import TradeCounter from '../../components/Games/TradeCounter'
import GameBlock from '../../components/Games/GameBlock'

const Home = () => {
  const { observe } = lozad()

  const location = useLocation()
  const query = qs.parse(location.search.substring(1))

  const { data, isFetching } = useQuery(
    ['games', query.search],
    async () => {
      const res = await getGames({ search: query.search, active: true })
      if (res.error) return
      return res.games
    },
    { staleTime: Infinity }
  )

  useEffect(() => {
    observe()
    window.scrollTo(0, 0)
  }, [observe])

  const isApp = window.localStorage.getItem('app')
  const theme = useContext(ThemeContext)
  const { user } = useContext(UserContext)

  // Nookazon hard coded trades
  let trades = 26025846
  if (data)
    data.forEach((game) => {
      trades += parseInt(game?.metrics?.trades || 0)
    })

  return (
    <div className='home' style={{ marginTop: isApp ? '50px' : '' }}>
      <Helmet>
        <title>Trade Video Game Items | Traderie.com</title>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: 'https://traderie.com',
            logo: 'https://cdn.nookazon.com/traderie/icons/traderie_icon.png',
          })}
        </script>
        <meta
          name='description'
          content='Find in-game trades on on Traderie.com. Trade items from Animal Crossing: New Horizons, Adopt Me!, Royale High, Murder Mystery 2, Diablo 2 Resurrected, and much more!'
        />
      </Helmet>
      <div className='home-section'>
        {(query.search === undefined || query.search === '') && (
          <div className='container home-banner'>
            <h2>
              <Trans i18nKey='slogan' />
            </h2>
            <TradeCounter game={{ metrics: { trades } }} />
            <div style={{ marginTop: 25 }}>
              <StyledLink
                className='btn'
                to={
                  user
                    ? localStorage.getItem('loginReroute') || '/getting-started'
                    : '/signup'
                }
              >
                <Trans i18nKey='homeStartTrading' />
              </StyledLink>
            </div>
          </div>
        )}
      </div>
      <AdSlot
        name='pb-slot-incontent-1'
        divId='traderie-home-ad-1'
        slots={[
          'pb-slot-incontent-1',
          'pb-slot-incontent-2',
          'pb-slot-incontent-3',
          'pb-slot-incontent-4',
        ]}
        mobile
      />
      <div className='home-section'>
        <div className='container'>
          {isFetching && <Loading />}
          <h1 style={{ display: 'none' }}>Home</h1>
          <SkeletonTheme
            color={theme.bodyAlt}
            highlightColor={theme.skeletonLoading}
          >
            <div className='row home-links'>
              {data &&
                data.map((game) => {
                  return <GameBlock game={game} key={game.schema} />
                })}
              {!isApp && (
                <div className='col-xs-12 col-sm-4 col-md-3'>
                  <StyledHyperLink
                    href={`https://nookazon.com/`}
                    className='home-wide-card'
                    onClick={() => {
                      window.scrollTo(0, 0)
                      if (user) trackQuest('gamesVisited', null, 'public')
                    }}
                    aria-label='Animal Crossing: New Horizons'
                  >
                    <img
                      data-src='https://cdn.nookazon.com/traderie/home/nookazon.jpg'
                      alt='latest'
                      className='home-card-img lozad'
                    />
                    <div>Animal Crossing: New Horizons</div>
                  </StyledHyperLink>
                </div>
              )}
              <div className='col-xs-12 col-sm-4 col-md-3'>
                <StyledLink
                  to='/suggest'
                  className='home-wide-card'
                  onClick={() => localStorage.setItem('suggestReroute', '/')}
                  aria-label="Don't see the game you're looking for? Suggest a new game"
                >
                  <div className='home-suggest'>
                    <div>
                      <span>
                        <Trans i18nKey='suggestNewGame' />{' '}
                      </span>
                      <FaPlus style={{ verticalAlign: 'middle' }} />
                    </div>
                  </div>
                </StyledLink>
              </div>
            </div>
          </SkeletonTheme>
        </div>
      </div>
      <div className='home-section'>
        <div className='container'>
          <AdSlot
            name='pb-slot-incontent-2'
            divId='traderie-home-ad-2'
            mobile={false}
          />
          {(query.search === undefined || query.search === '') && (
            <>
              <h1 style={{ textAlign: 'center', fontSize: 35 }}>
                <Trans i18nKey='whatTraderie' />
              </h1>
              <div className='row'>
                <div
                  className='col-xs-12 col-sm-4'
                  style={{ marginBottom: 15 }}
                >
                  <div className='hiw-card'>
                    <FaSign style={{ fontSize: 100 }} />
                    <h2>
                      <Trans i18nKey='tradeFinder' />
                    </h2>
                    <div>
                      <Trans i18nKey='tradeFinderDesc' />
                    </div>
                  </div>
                </div>
                <div
                  className='col-xs-12 col-sm-4'
                  style={{ marginBottom: 15 }}
                >
                  <div className='hiw-card'>
                    <FaHandshake style={{ fontSize: 100 }} />
                    <h2>
                      <Trans i18nKey='howWorks' />
                    </h2>
                    <div>
                      <Trans i18nKey='howWorksDesc' />
                    </div>
                    <div></div>
                  </div>
                </div>
                <div
                  className='col-xs-12 col-sm-4'
                  style={{ marginBottom: 15 }}
                >
                  <div className='hiw-card'>
                    <FaPeopleCarry style={{ fontSize: 100 }} />
                    <h2>
                      <Trans i18nKey='inGameTrading' />
                    </h2>
                    <div>
                      <Trans i18nKey='inGameTradingDesc' />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className='home-more-help'>
            <h2>
              <Trans i18nKey='needMoreHelp' />
            </h2>
            <div>
              <StyledButtonLink
                to='/getting-started'
                aria-label='Getting Started'
              >
                <Trans i18nKey='gettingStarted' />
              </StyledButtonLink>
            </div>
          </div>
          <AdSlot name='pb-slot-incontent-3' divId='traderie-home-ad-3' />
        </div>
      </div>
    </div>
  )
}

export default Home
