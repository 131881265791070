import React, { useState } from 'react'
import { HiX } from 'react-icons/hi'

import AdSlot from '../AdSlot'

import './style.css'

const AdAnchor = () => {
  const [openVideo, setOpenVideo] = useState(true)
  const [openAnchor, setOpenAnchor] = useState(true)

  if (!openVideo && !openAnchor) return null

  return (
    <div className='ae-fixed'>
      {openVideo && (
        <div className='ae-video'>
          <AdSlot
            name='pb-slot-outstream'
            divId='outstream-player-div'
            noMessage
          />
        </div>
      )}
      {openAnchor && (
        <div className='ae-anchor'>
          <AdSlot
            name='pb-slot-anchor'
            divId='anchor-ad'
            containerStyle={{ margin: '0 auto' }}
          />
          <div
            className='ae-anchor-close'
            onClick={() => {
              setOpenAnchor(false)
            }}
          >
            <HiX />
          </div>
        </div>
      )}
    </div>
  )
}

export default AdAnchor