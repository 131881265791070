import React, { useEffect, useContext, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import GameContext from '../GameContext'
import { UserContext } from '../contexts'
import can from '../services/rbac'
import tracker from '../services/tracker'

import { webAppPushToApp } from '../components/Navbar'
import { ThemeDiv } from './Styled'

const mobileWidth = 600
const tabletWidth = 992
const isMobile = window.innerWidth < mobileWidth

const SlotStyle = styled.div<{
  height: number
  width: number
  noMinWidth: boolean
}>`
  margin: 10px auto;
  position: relative;
  min-height: ${(props) => (props.height ? props.height : '25vh')};
  width: ${(props) => props.width || 'min(60vw, 100%)'};

  @media only screen and (max-width: 600px) {
    min-height: ${(props) => props.height || '10vh'};
    width: ${(props) => (props.noMinWidth ? '' : props.width || '100%')};
    background-color: unset;
  }
`

const SlotContainer = styled(ThemeDiv)<{
  height: number
  width: number
  noMinWidth: boolean
}>`
  border-radius: 10px;
  height: ${({ height }) => (height ? '100%' : '')};
  margin-bottom: 8px;
  min-height: ${({ height }) => height || '25vh'};
  min-width: ${({ width, noMinWidth }) =>
    noMinWidth ? '' : width || 'min(60vw, 100%)'};
  position: relative;

  @media only screen and (max-width: 600px) {
    /* background-color: unset; */
    min-width: ${(props) => props.width || '100%'};
  }
`

const StyledSlotMessage = styled.div<{ height: number; width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;

  @media only screen and (max-width: 600px) {
    background-color: unset;
  }
`

const SlotRemoveAds = styled(Link)`
  display: block;
  text-align: right;
  color: ${({ theme }) => theme.text};
`

const StyledAd = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
`

const isApp = window.localStorage.getItem('app')
const isIOS = window.localStorage.getItem('platform')

const app = process.env.REACT_APP_DEPLOY || 'traderie'
const reactApp = app[0].toUpperCase() + app.slice(1)

interface AdSlotProps {
  alwaysShow?: boolean
  completed?: any
  containerStyle?: any
  divId?: string
  index?: number
  name: string
  mobile?: boolean
  noMessage?: boolean
  noRefresh?: boolean
  onError?: any
  onLoaded?: any
  noMinWidth?: any
  onNoBid?: any
  refreshLimit?: any
  renderVisibleOnly?: boolean
  setLoading?: any
  setShowVideoClose?: any
  style?: any
  tablet?: boolean
  type?: any
  'data-display-type'?: string
}

declare global {
  interface Window {
    googletag: any
    tude: any
    ads: any
  }
}

const types = {
  'pb-slot-right-1': {
    height: '250px',
    width: '300px',
  },
  'pb-slot-right-2': {
    height: '600px',
    width: '300px',
  },
  'pb-slot-left-1': {
    height: '600px',
    width: '160px',
  },
  'pb-slot-left-2': {
    height: '600px',
    width: '160px',
  },
  'pb-slot-incontent-1': {
    height: '90px',
    width: '728px',
    mobile: {
      height: '250px',
      width: '300px',
    },
  },
  'pb-slot-incontent-2': {
    height: '90px',
    width: '728px',
    mobile: {
      height: '250px',
      width: '300px',
    },
  },
  'pb-slot-incontent-3': {
    height: '90px',
    width: '728px',
    mobile: {
      height: '250px',
      width: '300px',
    },
  },
  'pb-slot-incontent-4': {
    height: '90px',
    width: '728px',
    mobile: {
      height: '250px',
      width: '300px',
    },
  },
  'pb-slot-square-1': {
    height: '250px',
    width: '300px',
  },
  'pb-slot-square-2': {
    height: '250px',
    width: '300px',
  },
  'pb-slot-anchor': {
    height: '90px',
    width: '728px',
    mobile: {
      height: '50px',
      width: '320px',
    },
  },
  'pb-slot-outstream': {},
}

const AdSlot = (props: AdSlotProps) => {
  let {
    alwaysShow,
    containerStyle,
    divId,
    name,
    mobile,
    noMessage,
    noMinWidth,
    noRefresh,
    style,
    tablet,
  } = props

  const adRef = useRef(null)
  const { game } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const location = useLocation()
  const isOverwolf = localStorage.getItem('overwolf')

  const isActive = () => {
    if (alwaysShow) return true
    if (can('ADS:HIDE') || game.has('ADS:DISABLED')) {
      window.ads = false
      return false
    }
    if (mobile === undefined && tablet === undefined) return true
    return (
      (tablet &&
        window.innerWidth <= tabletWidth &&
        window.innerWidth > mobileWidth) ||
      (tablet === false && window.innerWidth >= tabletWidth) ||
      (mobile && isMobile) ||
      (mobile === false && window.innerWidth >= mobileWidth)
    )
  }

  const loadAds = () => {
    if (!isActive() || (!alwaysShow && !adRef.current)) return
    window.tude = window.tude || { cmd: [] }
    if (name === 'pb-slot-outstream') {
      if (window.tude.requestOutstreamAd)
        window.tude.cmd.push(function () {
          window.tude.requestOutstreamAd({
            baseDivId: 'pb-slot-outstream',
            divId: 'outstream-player-div',
            playerOptions: {
              // optional, see below
              controlBarPosition: 'over',
            },
          })
        })
    } else {
      window.tude.cmd.push(function () {
        window.tude.refreshAdsViaDivMappings([
          {
            divId: divId,
            baseDivId: name,
          },
        ])
      })
    }
  }

  const destroySlots = () => {
    if (
      window.googletag &&
      window.googletag.getUnits &&
      typeof window.googletag.getUnits === 'function'
    ) {
      const ads = window.googletag.getSlots()
      window.googletag.destroySlots(ads)
    }
  }

  // Destroy ads if user logs in or switches games
  useEffect(() => {
    if (can('ADS:HIDE')) {
      destroySlots()
    }
  }, [JSON.stringify(user)])

  useEffect(() => {
    if (!noRefresh) loadAds()
    return () => {
      if (!noRefresh) destroySlots()
    }
  }, [location.pathname, location.search])

  if (isOverwolf || !isActive() || !divId || !types[name]) return <></>

  let { height, width } = types[name]
  height = height === '90px' && window.innerWidth < 600 ? '50px' : height

  return noMessage ? (
    <div id={divId} ref={adRef}></div>
  ) : (
    <SlotStyle
      style={containerStyle}
      height={height}
      width={width}
      className='cool-slot'
      noMinWidth={noMinWidth}
    >
      <SlotContainer height={height} width={width} noMinWidth={noMinWidth}>
        <StyledSlotMessage height={height} width={width}>
          {reactApp} is supported by ads
        </StyledSlotMessage>
        <StyledAd style={style}>
          <div id={divId} ref={adRef}></div>
        </StyledAd>
      </SlotContainer>
      <SlotRemoveAds
        to='/akrewpro'
        onClick={() => {
          isApp && isIOS !== 'true' && webAppPushToApp('akrewpro')
          try {
            if (user)
              tracker.track('Clicked Ad Remove Link', {
                from_page: location.pathname,
                user_id: user.id,
              })
          } catch {}
        }}
      >
        Join Akrew Pro to remove ads!
      </SlotRemoveAds>
    </SlotStyle>
  )
}

export default AdSlot
