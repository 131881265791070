import React from 'react'
import { FaGavel, FaCrosshairs, FaBan, FaLink } from 'react-icons/fa'
import { HiOutlineSparkles, HiUser } from 'react-icons/hi'
import { IoMdCart } from 'react-icons/io'
import { itemHas, getModeLabel } from 'item-mode'
import { MdLocalOffer, MdInfo } from 'react-icons/md'
import { Switch, Route } from 'react-router-dom'
import { Trans } from 'react-i18next'
import qs from 'qs'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import can from '../../services/rbac'
import http from '../../services/http'
import { getUser } from '../../services/users'
import { upperCaseEachWord } from '../../services/validate'
import { getSlug } from '../../services/items'
import { hasFinishedTutorial } from '../../services/tutorial'

import {
  StyledTab,
  StyledLink,
  ThemeDiv,
  StyledButtonLink,
} from '../../components/Styled'
import AddToCatalog from '../../components/Items/AddToCatalog'
import AddToWishlist from '../../components/Items/AddToWishlist'
import AdSlot from '../../components/AdSlot'
import Alert from '../../components/Alert'
import Button from '../../components/inputs/Button'
import CreateListing from '../../components/Listings/CreateListing'
import EditInput from '../../components/inputs/EditInput'
import GoBack from '../../components/GoBack'
import Helmet from '../../components/Helmet'
import ItemTags from '../../components/Items/ItemTags'
import LatestCatalog from '../../components/Catalog/LatestCatalog'
import Listings from '../../components/Listings/Listings'
import Recipe from '../../components/Items/Recipe'
import ItemIcon from '../../components/Items/ItemIcon'
import VariantSelect from '../../components/Items/VariantSelect'
import Modal from '../../components/Modal'
import Tutorial from '../../components/Tutorial'

import DiyToggle from './DiyToggle'
import EditItemImg from './EditItemImg'
import EditProperties from './EditProperties'
import EditTags from './EditTags'
import FindGroup from './FindGroup'
import ItemDescription from './ItemDescription'
import ItemInfo from './ItemInfo'
import ItemLock from './ItemLock'
import ItemToggle from './ItemToggle'
import NotificationToggle from './NotificationToggle'
import PriceHistory from './PriceHistory'
import ProductSkeleton from './ProductSkeleton'
import ProductValue from './ProductValue'
import RecentTrades from './RecentTrades'
import Recipes from './Recipes'

import './style.css'

const ProductPage = styled.div`
  display: flex;
  @media only screen and (min-width: 600px) {
    align-items: flex-start;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const ProductContainer = styled.div`
  margin-bottom: 20px;
  padding: ${({ theme }) => theme.productPadding};
  border-radius: ${({ theme }) => theme.productBorderRadius};
  background-image: ${({ theme, type }) =>
    theme.productBg ? theme.productBg(type) : ''};
  background-position: right;
  background-repeat: no-repeat;
  @media only screen and (min-width: 600px) {
    position: sticky;
    top: 20px;
    max-width: 350px;
    margin-right: 20px;
  }
`

const ProductInfoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  margin-bottom: 10px;
  color: ${({ theme, type }) =>
    theme.productDescription ? theme.productDescription(type) : ''};
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

const ProductImg = styled(ItemIcon)`
  width: ${({ theme }) => theme.productImageWidth || 300}px;
  height: ${({ theme }) => theme.productImageWidth || 300}px;
  padding: 15px;
  border-radius: 20px;
  margin: auto;
  margin-bottom: 5px;
  background-color: ${({ theme }) =>
    theme.productImgBackground || theme.bodyAlt};
`

const ValueSection = styled(ThemeDiv)`
  padding: 15px;
  border-radius: 20px;
`

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.text};
`

const VariantName = styled.div`
  color: ${({ theme }) => theme.productDescription};
`

const StyledValueLink = styled(StyledLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.btnBackground};
`

const diyVariant = {
  img: 'https://cdn.nookazon.com/nookazon/icons/recipe.png',
  active: true,
  name: 'DIY',
}

const anyVariant = {
  img: 'https://cdn.nookazon.com/nookazon/icons/AnyVariant.png',
  active: true,
  name: 'Any Variant',
}

const isApp = window.localStorage.getItem('app')

class Product extends React.Component {
  state = {
    loading: false,
    item: this.props.location.itemInfo || {},
    variant: {},
    adding: this.props.location.state?.adding || false,
    subscribed: false,
    groupSearch: false,
    valueProps: {},
    prices: '',
    currAlert: '',
    modalOpen: false,
    isTourRunning:
      localStorage.getItem('currentTutorial') === 'Listing' &&
      !hasFinishedTutorial('Listing') &&
      !localStorage.getItem('tutorialSkipped'),
  }

  static contextType = GameContext

  scrollRef = React.createRef()

  componentDidMount = () => {
    const { location, match } = this.props
    const user = getUser()

    if (
      location.itemInfo &&
      location.itemInfo.type &&
      'properties' in location.itemInfo
    ) {
      let qsParams = {
        id: match.params.id,
        tags: '',
      }
      http
        .get(`/itemTagsRecipe`, qsParams, {
          signal: this.abortController.signal,
        })
        .then((res) => {
          const newItem = {
            ...this.state.item,
            tags: res.tags,
            recipe: res.recipe,
          }
          if (newItem.variants) {
            const removeIndex = newItem.variants.findIndex(
              (variant) => variant.name === 'All Variants'
            )
            if (removeIndex !== -1) newItem.variants.splice(removeIndex, 1)
          }
          this.setState({ item: newItem })
          this.redirect(newItem)
        })
    } else {
      this.getItem()
    }

    if (user && match.params.id && !isNaN(match.params.id)) {
      http
        .get('/items/subscriptionStatus', { item: match.params.id })
        .then((res) => {
          if (res.error) return this.setState({ currAlert: res.error })
          this.setState({ subscribed: res.length > 0 })
        })
    }
    window.scrollTo(0, 0)
  }

  componentDidUpdate = (prev) => {
    let { params } = this.props.match
    if (prev.match.params.id !== params.id) {
      this.getItem()
    }
  }

  componentWillUnmount = () => this.abortController.abort()
  abortController = new window.AbortController()

  getItem = () => {
    const { game } = this.context
    let { params } = this.props.match

    let qsParams = {
      id: params.id,
      variants: '',
      tags: '',
      properties: '',
      adding: false,
      info: true,
      credits: true,
    }

    let user = getUser()
    if (user) {
      qsParams.user = user.id
      if (game.has('ITEMS:CATALOG')) qsParams.catalog = user.id
    }

    this.setState({ loading: true, variant: {} })
    http
      .get(`/items`, qsParams, { signal: this.abortController.signal })
      .then((res) => {
        let item = res.items[0]
        if (game.has('LISTINGS:DIY') && item.diy === true) {
          if (!item.variants) item.variants = []
          item.variants.push(diyVariant)
        }
        if (
          game.has('ITEMS:ANY_VARIANT') &&
          item.variants &&
          item.variants.length > 1
        )
          item.variants.push(anyVariant)
        this.receiveItem(item)
        this.setVariant(item.variants)
        this.redirect(item)
      })
  }

  redirect = (newItem) => {
    const { game } = this.context
    if (game.has('TRADING:DISABLED')) {
      const { match, history } = this.props
      const slug = newItem.slug || getSlug(newItem.name)
      history.replace(`${match.url}/${slug}`)
    }
  }

  receiveItem = (item) => {
    this.setState({ item, loading: false })
  }

  toggleSubscriptionStatus = () => {
    this.setState({ subscribed: !this.state.subscribed })
  }

  setVariant = (variants) => {
    if (variants && this.props.location.search !== '') {
      let query = qs.parse(this.props.location.search.substring(1))

      let selectedVariant = variants.filter(
        (v) => v.id === parseInt(query.variant)
      )
      if (selectedVariant.length > 0)
        this.setState({ variant: selectedVariant[0] })
    }
  }

  close = () => {
    this.setState({ adding: false })
  }

  updateItemState = (update) => {
    this.setState({ item: { ...this.state.item, ...update } })
  }

  updateItem = ({ name, buyPrice, sellPrice, description, createdAt }) => {
    let { item } = this.state
    let payload = { item: item.id }
    if (name) payload.name = name
    if (buyPrice) payload.buyPrice = buyPrice
    if (sellPrice) payload.sellPrice = sellPrice
    if (description || description === '') payload.description = description
    if (createdAt) payload.createdAt = createdAt
    return http.put('/items/edit', payload).then((res) => {
      if (res.error) this.setState({ currAlert: `Error: ${res.error}` })
    })
  }

  updateVariant = ({ name }) => {
    let { variant } = this.state
    let payload = { variant: variant.id }
    if (name) payload.name = name
    return http.put('/items/variants/edit', payload).then((res) => {
      if (res.error) this.setState({ currAlert: `Error: ${res.error}` })
    })
  }

  closeGroupSearch = (id) => {
    this.setState({ groupSearch: false })
    if (id && id !== '') return http.del('/offers', { id })
  }

  setValueProps = (props) => {
    this.setState({ valueProps: props })
  }

  removeAlert = () => {
    let { match, history } = this.props
    let { currAlert } = this.state
    this.setState({ currAlert: '' })
    if (currAlert.includes('Error: ')) {
      history.push(match.url)
    }
  }

  render = () => {
    const { match, location, history } = this.props
    const { game, routePrefix } = this.context
    let currUser = getUser()

    const { url } = match
    const {
      currAlert,
      loading,
      item,
      adding,
      variant,
      subscribed,
      groupSearch,
      modalOpen,
      isTourRunning,
    } = this.state
    const gameType = game.types.find((t) => t.label === item.type) || {}

    let {
      id,
      name,
      description,
      diy,
      variants,
      obtained,
      // set,
      prices,
      recipe,
      tags,
      active,
      properties,
      mode,
      slug,
      created_at,
      updated_at,
    } = item

    const liveListings = itemHas(mode, 'LISTINGS:LIVE')
    const desTop = itemHas(mode, 'ITEMS:DESCRIPTION_TOP')
    const noWishlist = itemHas(mode, 'ITEMS:NO_WISHLIST')
    const noLookingFor = itemHas(mode, 'ITEMS:NO_LOOKING_FOR')
    const noRecentTrades = itemHas(mode, 'ITEMS:NO_RECENT_TRADES')
    const noNote = itemHas(mode, 'ITEMS:NO_NOTE')
    if (!slug && item.name) slug = getSlug(item.name)

    let listingFilters = [
      'free',
      'makeOffer',
      'amount',
      'price',
      'sort',
      'rating',
    ]
    let canTouchTrade = item.tags
      ? item.tags.find((tag) => tag.tag === 'catalog')
      : false
    if (canTouchTrade) {
      listingFilters.push('touchTrade')
    }
    if (diy) {
      listingFilters.push('diy')
    }
    if (diy || (obtained && obtained.includes('Crafting'))) {
      listingFilters.push('materialsRequired')
    }
    if (variants) listingFilters.push('variants')
    if (properties) listingFilters.push('properties')
    let tutorialSteps = []
    if (item.variants?.length > 0) {
      tutorialSteps.push({
        target: '.product-variants',
        content:
          'If you want to sell a variant of this item, click the one you want to sell here.',
        disableBeacon: true,
        placement: 'bottom',
        spotlightClicks: true,
      })
    }
    tutorialSteps.push({
      target: '#add_listing_button',
      content: 'Click here to add your own listing',
      disableBeacon: true,
      placement: 'bottom',
      spotlightClicks: true,
    })

    return (
      <div className='product'>
        <Helmet
          data={{
            item: upperCaseEachWord(item.name) || '',
            created_at,
            updated_at,
          }}
        />
        <AdSlot
          name='pb-slot-incontent-1'
          divId='product-ad-1'
          data-display-type='hybrid-banner'
        />
        {loading && <ProductSkeleton />}
        {!loading && id && (
          <div className='container'>
            {isApp && <GoBack history={history} />}
            <ProductPage>
              <ProductContainer
                type={
                  item.tags &&
                  (item.tags.find((obj) => obj.category === 'type') || {}).tag
                }
              >
                <div className='product-info'>
                  <div className='product-display'>
                    <div className='product-img-container'>
                      <ProductImg item={item} variant={variant} productPage>
                        {can('ITEMS:EDIT_IMAGE') && (
                          <EditItemImg
                            item={item}
                            variant={variant}
                            receiveItem={this.receiveItem}
                          />
                        )}
                      </ProductImg>
                      {can('ITEMS:EDIT_VARIANTS') &&
                      Object.keys(variant).length > 0 &&
                      variant.name !== 'DIY' ? (
                        <EditInput
                          initValue={variant.name}
                          confirm={(value) =>
                            this.updateVariant({ name: value })
                          }
                          placeholder='Variant Name'
                          centerText={true}
                          className='item-variant-edit'
                        />
                      ) : (
                        <VariantName
                          style={{
                            textAlign: 'center',
                            textTransform: 'capitalize',
                            fontWeight: 'bold',
                          }}
                        >
                          &nbsp;{variant.name}
                        </VariantName>
                      )}
                      {Object.keys(variant).length > 0 && !variant.active && (
                        <div
                          style={{ fontWeight: 'bold', textAlign: 'center' }}
                        >
                          <FaBan />
                          &nbsp;
                          <Trans i18nKey='itemNonTrade' />
                        </div>
                      )}
                    </div>
                    <ProductInfoContainer type={item.type}>
                      <div style={{ display: 'flex' }}>
                        {can('ITEMS:EDIT') ? (
                          <EditInput
                            initValue={name}
                            confirm={(value) =>
                              this.updateItem({ name: value })
                            }
                            placeholder='Name'
                            className='item-name-edit'
                          />
                        ) : (
                          <span className='product-name'>
                            <h1>{name}</h1>
                          </span>
                        )}
                        {can('ITEMS:EDIT_CREATED_AT') && (
                          <StyledButton
                            className='btn-icon'
                            style={{ width: 50, height: 50, fontSize: 25 }}
                            onClick={() => {
                              this.setState({ modalOpen: true })
                            }}
                          >
                            <HiOutlineSparkles />
                          </StyledButton>
                        )}
                        {modalOpen && (
                          // @ts-ignore
                          <Modal
                            open={modalOpen}
                            onCancel={() => {
                              this.setState({ modalOpen: false })
                            }}
                            onConfirm={() => {
                              this.updateItem({ createdAt: true })
                              this.setState({ modalOpen: false })
                            }}
                            title={'Highlight Item'}
                            body={
                              <>
                                <p>
                                  Are you sure you want to move this item to the
                                  front page?
                                </p>
                              </>
                            }
                            label={<Trans i18nKey='yes' />}
                          />
                        )}
                        {can('ITEMS:TOGGLE') && (
                          <ItemToggle item={item} onToggle={this.getItem} />
                        )}
                        {can('ITEMS:EDIT_VARIANTS') &&
                          game.has('LISTINGS:DIY') && (
                            <DiyToggle item={item} onToggle={this.getItem} />
                          )}
                        {currUser && (
                          <NotificationToggle
                            item={item}
                            subscribed={subscribed}
                            onToggle={this.toggleSubscriptionStatus}
                          />
                        )}
                      </div>
                      {variants && (
                        <VariantSelect
                          variants={variants}
                          variant={variant}
                          onChange={(value) => {
                            this.setState({ variant: value })
                            const currQuery = qs.parse(
                              location.search.substring(1)
                            )
                            delete currQuery['variant']
                            history.push({
                              search: qs.stringify({
                                ...currQuery,
                                variant: value.id,
                              }),
                            })
                          }}
                          product={game.has('ITEMS:CUSTOMIZATION')}
                          catalog={item.catalog}
                          theme='light'
                        />
                      )}
                      <ItemTags
                        item={item}
                        tags={tags}
                        schema={game.schema}
                        getItem={this.getItem}
                      />
                      {can('ITEMS:EDIT_TAGS') && (
                        <EditTags item={item} getItem={this.getItem} />
                      )}
                      {can('PROPERTIES:EDIT_PROPERTIES') && (
                        <EditProperties
                          item={item}
                          getItem={this.getItem}
                          updateItemState={this.updateItemState}
                        />
                      )}
                      {!can('ITEMS:EDIT') && can('DATA:SUGGEST_ITEMS') && (
                        <div>
                          <StyledButtonLink
                            to={`${routePrefix}/product/suggest/${item.id}`}
                            style={{ display: 'inline-block', marginBottom: 8 }}
                          >
                            Suggest an edit
                          </StyledButtonLink>
                        </div>
                      )}
                      {obtained && (
                        <div className='product-obtained'>
                          <Trans i18nKey='obtainedFrom' />: {obtained}
                        </div>
                      )}
                      {recipe && <Recipe recipe={recipe} />}
                      {!desTop && (
                        <ItemDescription
                          description={description}
                          updateItem={this.updateItem}
                        />
                      )}
                    </ProductInfoContainer>
                  </div>
                </div>
                {active && game.has('ITEMS:PRICE_HISTORY') && (
                  <ValueSection>
                    <h3 className='product-value-header'>Value</h3>
                    <ProductValue
                      isApp={isApp}
                      item={item}
                      prices={this.state.prices || prices}
                      updateItem={this.updateItem}
                      variant={variant}
                      values={gameType.values}
                      valueProps={this.state.valueProps}
                      setValueProps={this.setValueProps}
                    />
                    <PriceHistory
                      item={item}
                      variant={variant}
                      valueProps={this.state.valueProps}
                      setPrices={(prices) => {
                        this.setState({ prices })
                      }}
                    />
                  </ValueSection>
                )}
                <AdSlot
                  name='pb-slot-square-1'
                  divId='product-ad-2'
                  mobile={false}
                  containerStyle={{
                    alignItems: 'flex-start',
                    marginLeft: 0,
                    maxHeight: 650,
                  }}
                />
              </ProductContainer>
              <div style={{ flex: 1 }}>
                {desTop && (
                  <ItemDescription
                    description={description}
                    updateItem={this.updateItem}
                    mode={mode}
                    properties={properties}
                  />
                )}
                {!game.has('TRADING:DISABLED') && !active && (
                  <ItemLock item={item} />
                )}
                {active && !adding && currUser && (
                  <div className='product-action-bar'>
                    {liveListings && (
                      <div className='add-listing-container'>
                        <button
                          onClick={() => {
                            this.setState({ groupSearch: true })
                          }}
                          aria-label='Make offer'
                        >
                          <Trans i18nKey={getModeLabel(mode, 'makeOffer')} />
                        </button>
                      </div>
                    )}
                    {!game.has('TRADING:DISABLED') && (
                      <div className='add-listing-container'>
                        <button
                          onClick={() => {
                            if (
                              Object.keys(variant).length > 0 &&
                              !variant.active
                            ) {
                              this.setState({
                                alert: (
                                  <Trans i18nKey='nontradeableVariation' />
                                ),
                              })
                            } else {
                              this.setState({
                                adding: true,
                                isTourRunning: false,
                              })
                              window.dataLayer.push({
                                event: 'createListing',
                                eventProps: {
                                  category: 'Create Listing',
                                  action: 'Initiate Add Listing',
                                },
                                userId: currUser ? currUser.id : undefined,
                                email: currUser ? currUser.email : undefined,
                              })
                            }
                          }}
                          id='add_listing_button'
                          aria-label='Add listing'
                        >
                          <Trans i18nKey={getModeLabel(mode, 'addListing')} />
                        </button>
                      </div>
                    )}
                    {!noWishlist && (
                      <>
                        <div className='add-listing-container'>
                          <AddToCatalog
                            item={item}
                            variant={variant}
                            receiveItem={this.receiveItem}
                          />
                        </div>
                        <div className='add-listing-container'>
                          <AddToWishlist item={item} variant={variant} />
                        </div>
                      </>
                    )}
                  </div>
                )}
                {active && adding && (
                  <CreateListing
                    autoLookingFor={!location.pathname.includes('/buying')}
                    item={item}
                    variant={variant}
                    close={this.close}
                    history={history}
                    product
                  />
                )}
                {!adding && (
                  <>
                    {!game.has('TRADING:DISABLED') &&
                      game.has('ITEMS:VALUE_LIST') && (
                        <StyledValueLink
                          to={`${routePrefix}/values${
                            game.ui?.values?.type_filter ? '/' + item.type : ''
                          }`}
                          target='_blank'
                          aria-label='Values'
                        >
                          Check our Value List
                        </StyledValueLink>
                      )}
                    <div className='product-listings'>
                      {gameType.notice && (
                        <div className='notice'>
                          <b>
                            <Trans i18nKey='notice' />:
                          </b>
                          &nbsp;
                          <Trans i18nKey={gameType.notice} />
                        </div>
                      )}
                      {!game.has('TRADING:DISABLED') && !noNote && (
                        <div className='product-note'>
                          {liveListings ? (
                            <Trans i18nKey='liveListingNote' />
                          ) : (
                            <Trans i18nKey='productNote' />
                          )}
                        </div>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          maxWidth: '100%',
                          overflowX: 'scroll',
                        }}
                      >
                        {!game.has('TRADING:DISABLED') && active && (
                          <>
                            {game.has('ITEMS:CATALOG_TAG') && (
                              <StyledTab to={`${url}/catalog`}>
                                <HiUser className='product-tab-text' />
                                &nbsp;
                                <span className='product-tab-text'>
                                  <Trans i18nKey='players' />
                                </span>
                              </StyledTab>
                            )}
                            <StyledTab
                              to={{
                                pathname: `${url}`,
                                search: location.search,
                              }}
                              exact
                            >
                              <IoMdCart className='product-tab-text' />
                              &nbsp;
                              <span className='product-tab-text'>
                                {liveListings ? (
                                  <Trans i18nKey='liveServices' />
                                ) : (
                                  <Trans
                                    i18nKey={getModeLabel(mode, 'selling')}
                                  />
                                )}
                              </span>
                            </StyledTab>
                            {!liveListings && (
                              <>
                                {game.has('LISTINGS:AUCTIONS') && (
                                  <StyledTab
                                    to={{
                                      pathname: `${url}/auctions`,
                                      search: location.search,
                                    }}
                                    id='auctions-tab'
                                  >
                                    <FaGavel className='product-tab-text' />
                                    &nbsp;
                                    <span className='product-tab-text'>
                                      <Trans i18nKey='auctions' />
                                    </span>
                                  </StyledTab>
                                )}
                                {!noLookingFor && (
                                  <StyledTab
                                    to={{
                                      pathname: `${url}/buying`,
                                      search: location.search,
                                    }}
                                  >
                                    <FaCrosshairs className='product-tab-text' />
                                    &nbsp;
                                    <span className='product-tab-text'>
                                      <Trans i18nKey='lookingFor' />
                                    </span>
                                  </StyledTab>
                                )}
                                {!noRecentTrades && (
                                  <StyledTab
                                    to={{
                                      pathname: `${url}/recent`,
                                      search: location.search,
                                    }}
                                  >
                                    <MdLocalOffer className='product-tab-text' />
                                    &nbsp;
                                    <span className='product-tab-text'>
                                      <Trans i18nKey='recentTrades' />
                                    </span>
                                  </StyledTab>
                                )}
                              </>
                            )}
                          </>
                        )}
                        <StyledTab to={{ pathname: `${url}/${slug}` }}>
                          <MdInfo className='product-tab-text' />
                          &nbsp;
                          <span className='product-tab-text'>
                            <Trans i18nKey='wiki' />
                          </span>
                        </StyledTab>
                        {game.has('ITEMS:RECIPES') && (
                          <StyledTab
                            to={`${url}/recipes`}
                            style={{
                              borderBottomLeftRadius: 20,
                              borderBottomRightRadius: 20,
                            }}
                          >
                            <FaLink className='product-tab-text' />
                            &nbsp;
                            <span className='product-tab-text'>
                              <Trans i18nKey={game.recipeLabel || 'recipes'} />
                            </span>
                          </StyledTab>
                        )}
                      </div>
                      <Switch>
                        <Route
                          exact
                          path={`${url}`}
                          render={(routeProps) => (
                            <Listings
                              {...routeProps}
                              item={item}
                              variant={variant}
                              filters={listingFilters}
                              gallery
                              product
                            />
                          )}
                        />
                        {game.has('LISTINGS:AUCTIONS') && (
                          <Route
                            path={`${url}/auctions`}
                            render={(routeProps) => (
                              <Listings
                                {...routeProps}
                                item={item}
                                variant={variant}
                                selling
                                auction
                                filters={listingFilters}
                                product
                              />
                            )}
                          />
                        )}
                        <Route
                          path={`${url}/buying`}
                          render={(routeProps) => (
                            <Listings
                              {...routeProps}
                              item={item}
                              variant={variant}
                              buying
                              filters={listingFilters}
                              product
                            />
                          )}
                        />
                        <Route
                          path={`${url}/recent`}
                          render={(routeProps) => (
                            <RecentTrades
                              {...routeProps}
                              recent={true}
                              item={item}
                              variant={variant}
                              product
                            />
                          )}
                        />
                        {game.has('ITEMS:RECIPES') && (
                          <Route
                            path={`${url}/recipes`}
                            render={(routeProps) => (
                              <Recipes
                                {...routeProps}
                                item={item}
                                variant={variant}
                              />
                            )}
                          />
                        )}
                        {game.has('ITEMS:CATALOG_TAG') && (
                          <Route
                            path={`${url}/catalog`}
                            render={(routeProps) => (
                              <LatestCatalog
                                {...routeProps}
                                item={item}
                                variant={variant}
                                list
                              />
                            )}
                          />
                        )}
                        <Route
                          path={`${url}/:slug`}
                          render={(routeProps) => (
                            <ItemInfo
                              {...routeProps}
                              item={item}
                              variant={variant}
                              routePrefix={routePrefix}
                            />
                          )}
                        />
                      </Switch>
                    </div>
                  </>
                )}
                {groupSearch && (
                  <FindGroup
                    routePrefix={routePrefix}
                    open={groupSearch}
                    onClose={this.closeGroupSearch}
                    item={item.id}
                  />
                )}
              </div>
            </ProductPage>
          </div>
        )}
        <AdSlot name='pb-slot-incontent-2' divId='product-ad-3' />
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
        {!loading && <Tutorial steps={tutorialSteps} run={isTourRunning} />}
      </div>
    )
  }
}

export default Product
