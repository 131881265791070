import React, { useState, useContext, useMemo } from 'react'
import { IoIosSquareOutline, IoIosCheckbox } from 'react-icons/io'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import http from '../../services/http'
import can from '../../services/rbac'
import Button from '../inputs/Button'
import GameContext from '../../GameContext'
import ModalContext from '../../contexts/ModalContext'
import { ThemeDiv, StyledSelect } from '../Styled'
import { getListingDate } from './ListingItem'

import ListingItem from './ListingItem'
import ListingActions from './ListingActions'
import ListingModActions from './ListingModActions'
import Alert from '../Alert'

const ListingContent = styled(ThemeDiv)`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: ${({ theme }) => theme.listingPadding || '15px'};
  height: 100%;
  border-radius: 20px;
  background-color: ${({ active, theme }) =>
    active === true
      ? theme.listingsBgColor || theme.bodyAlt
      : theme.bodyGreyed};

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const Listing = ({
  canSell,
  gallery,
  grid,
  hideItemImgs,
  history,
  horizontal,
  listing,
  listingActions,
  listView,
  openPatreon,
  product,
  profile,
  profileLink,
  set,
  showDetails,
  showRecipes,
  slider,
  suggestion,
  updateListings,
  wishlists,
  vertical,
}) => {
  const { active, selected, id, wishlist_id, end_time, completed } = listing
  const { game, routePrefix } = useContext(GameContext)
  const { openModal } = useContext(ModalContext)
  const { t } = useTranslation()
  const isCompactView = game.has('LISTINGS:COMPACT')
  const [currAlert, setAlert] = useState('')

  const isAuctionOver =
    !end_time ||
    (end_time &&
      DateTime.now().diff(DateTime.fromISO(end_time)).as('milliseconds') >=
        1000 * 60 * 60 * 48) ||
    completed

  const isList = isCompactView
    ? true
    : (listView && !horizontal) || slider === false

  const markListing = (listing, remove) => {
    const sellPayload = {
      listing: listing.id,
      selling: listing.selling,
      remove,
      endTime: end_time,
    }

    if (!listing.selling) {
      sellPayload.itemId = listing.item_id
      sellPayload.variantId = listing.variant_id
      sellPayload.diy = listing.diy
    }
    return http.post(`/sell`, sellPayload).then((res) => {
      if (res.error) return setAlert(res.error)

      updateListings({ remove: listing.id })
    })
  }

  const completeListing = (listing) => {
    openModal({
      title: 'Complete Listing',
      onCancel: () => {},
      onConfirm: () => {
        return http.post('/sell', { listing: listing.id }).then((res) => {
          if (res.error) return setAlert(res.error)
          updateListings({ remove: listing.id })
        })
      },
      body: (
        <>
          <p>Are you sure you want to complete this listing?</p>
        </>
      ),
      label: <Trans i18nKey='yes' />,
    })
  }

  const moveWishlistItem = (option) => {
    const wishlistId = option.value
    if (wishlistId !== wishlist_id) {
      return http
        .put('/listings/move', {
          listing: id,
          wishlist: wishlistId,
          currWishlist: wishlist_id,
        })
        .then((res) => {
          if (res.error) return setAlert(res.error)
          updateListings({ remove: id })
          // this.setState({ listings: res.listings })
        })
    }
  }

  let wishlistOptions = useMemo(() => {
    let moveWishlists = wishlists
      ? wishlists.map((wishlist) => ({
          value: wishlist.id,
          label: wishlist.name,
        }))
      : []

    moveWishlists.push({ value: null, label: 'Looking For' })
    return moveWishlists
  }, [wishlists])

  const removeAlert = () => {
    setAlert('')
  }

  return (
    <div
      id={listing.id}
      className={
        !slider
          ? `col-xs-12 col-sm-${listView ? 12 : 6} col-md-${
              (listView && !horizontal) || isCompactView ? 12 : grid || 6
            } fade listing-row`
          : 'fade listing-row'
      }
    >
      <ListingContent
        active={active}
        selected={selected}
        style={{ flexDirection: isList ? '' : 'column' }}
      >
        <ListingItem
          canSell={canSell}
          gallery={gallery}
          hideItemImgs={hideItemImgs}
          listing={listing}
          listView={isList}
          myProfile={profile && canSell && !completed}
          product={product}
          profileLink={profileLink}
          showDetails={showDetails}
          showRecipe={showRecipes}
          slider={slider}
          vertical={vertical}
          suggestion={suggestion}
        />
        {profile && canSell && !completed && (
          <div
            className='listing-action-bar'
            style={{
              display: listView ? '' : 'flex',
              marginTop: listView ? '' : 10,
              alignItems: 'center',
              flexWrap: listView ? '' : 'wrap',
            }}
          >
            {can('LISTINGS:MULTI') && listingActions && (
              <div
                className='listing-btn-container'
                style={{
                  marginRight: listView ? '' : 5,
                }}
              >
                <div
                  onClick={() =>
                    updateListings({
                      update: {
                        id: listing.id,
                        data: {
                          selected: !(listing.selected || false),
                        },
                      },
                    })
                  }
                  style={{
                    fontSize: 25,
                    lineHeight: 0,
                    cursor: 'pointer',
                    textAlign: 'right',
                  }}
                >
                  {listing.selected ? (
                    <IoIosCheckbox />
                  ) : (
                    <IoIosSquareOutline />
                  )}
                </div>
              </div>
            )}
            {isAuctionOver && (
              <div
                className='listing-btn-container'
                style={{
                  marginRight: listView ? '' : 10,
                }}
              >
                <Button
                  onClick={() => markListing(listing, false)}
                  label={`${listing.selling ? t('markSold') : t('markBought')}`}
                />
              </div>
            )}
            {(isAuctionOver ||
              parseInt(listing.total_offers) === 0 ||
              (completed &&
                DateTime.now()
                  .diff(DateTime.fromISO(listing.updated_at))
                  .as('hours') > 24)) && (
              <div
                className='listing-btn-container'
                style={{
                  marginRight: listView ? '' : 10,
                }}
              >
                <Button
                  className='btn-alt remove-listing'
                  onClick={() => markListing(listing, true)}
                  label={<Trans i18nKey='remove' />}
                />
              </div>
            )}
            <ListingActions
              history={history}
              listing={listing}
              listView={listView}
              openPatreon={openPatreon}
              profile={profile}
              set={set}
              updateListings={updateListings}
            />
            {wishlists && !listing.selling && (
              <StyledSelect
                className='move-wishlist-dropdown'
                isSearchable={false}
                onChange={moveWishlistItem}
                options={wishlistOptions}
                placeholder={t('move')}
                value=''
              />
            )}
          </div>
        )}
        {can('LISTINGS:MOD_ACTIONS') && !canSell && profile && (
          <ListingModActions
            listing={listing}
            markListing={markListing}
            completeListing={completeListing}
          />
        )}
        {profile && canSell && !completed && (
          <span
            className='listing-date'
            style={{ right: '16px', marginBottom: '2px' }}
          >
            {getListingDate(listing.updated_at)}
          </span>
        )}
        {suggestion && (
          <Button
            style={{ maxHeight: '40px' }}
            onClick={(e) => {
              window.dataLayer.push({
                event: 'suggestListingOffer',
                eventProps: {
                  category: 'Listing Suggestions',
                  action: 'Make Offer from Looking For Listing Suggestion',
                },
              })
              const url = `${routePrefix}/listing/${listing.id}`
              if (e.ctrlKey || e.metaKey) {
                window.open(url, 'blank')
              } else {
                e.preventDefault()
                history.push(url, { listingInfo: listing, offering: true })
              }
            }}
            label={t('makeOffer')}
          />
        )}
      </ListingContent>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default Listing
