import React from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

import http from '../../services/http'
import can from '../../services/rbac'
import {
  banUser,
  deleteUser,
  muteUser,
  SeniorModReviewString,
} from '../../services/admin'
import { forgotPassword } from '../../services/login'
import { deleteAllListings } from '../../services/listings'
import GameContext from '../../GameContext'
import Button from '../inputs/Button'

import ModReportModal from './ModReportModal'
import BanReasonModal from './ReportsTable/BanReasonModal'
import Alert from '../Alert'
import Modal from '../Modal'

const AdminButton = styled.button`
  margin-bottom: 5px;
  margin-right: 5px;
`

class AdminActions extends React.Component {
  state = {
    open: false,
    banReason: false,
    currAlert: '',
    loadingBanning: false,
    modalOpen: false,
    modalText: '',
    modalCategory: '',
  }

  static contextType = GameContext

  onSubmit = (category, modNotes) => {
    const { user } = this.props
    return http
      .post(`/reports/create`, {
        status: SeniorModReviewString,
        user_id: user.id,
        discord: user.discord || '',
        username: user.username,
        mod_notes: modNotes,
        category,
      })
      .then((res) => {
        this.setState({ open: false })
        if (res.error) {
          this.setState({ currAlert: res.error })
          return
        }
        this.setState({
          currAlert:
            'You just submitted a request to a senior mod to review your ban request.',
        })
        return
      })
  }

  banUserReason = (reason) => {
    let { user, getUserInfo } = this.props
    this.setState({ banReason: false, loadingBanning: true })
    banUser({
      userId: user.id,
      discordId: user.discord_id,
      reason: reason,
      ban: !user.banned,
    }).then((res) => {
      this.setState({ loadingBanning: false })
      if (res.error) {
        this.setState({
          currAlert: `Error in banning user ${user.id}: ${res.error}`,
        })
      } else {
        this.setState({
          currAlert: `You have ${
            user.banned ? 'unbanned' : 'banned'
          } this user`,
        })
      }
      this.setState({ currUser: '' })
      if (getUserInfo) {
        getUserInfo()
      } else {
        window.location.reload()
      }
    })
  }

  removeAlert = () => {
    this.setState({ currAlert: '' })
  }

  render = () => {
    let { user, history, className } = this.props
    let {
      open,
      banReason,
      currAlert,
      loadingBanning,
      modalOpen,
      modalText,
      modalCategory,
    } = this.state
    const { game, routePrefix } = this.context
    let muted
    if (user.roles)
      muted = user.roles.find((role) => role.title === 'muted-user')

    return (
      <div className={`user-edit-btn-bar ${className || ''}`}>
        {can('ADMIN:BAN_PERSON') && (
          <Button
            onClick={() =>
              user.banned || can('ADMIN:NO_NOTES')
                ? this.banUserReason()
                : this.setState({ banReason: true })
            }
            label={user.banned ? 'Unban User' : 'Ban User'}
            style={{
              marginRight: 5,
              marginBottom: 5,
            }}
            loading={loadingBanning}
          />
        )}
        {can('ADMIN:BAN_REQUEST') && !user.banned && (
          <AdminButton
            onClick={() =>
              this.setState({
                open: true,
              })
            }
            aria-label='Request Ban User'
          >
            Request Ban User
          </AdminButton>
        )}
        {can('ADMIN:FORGOT_PASSWORD') && (
          <AdminButton
            onClick={() =>
              forgotPassword(
                user.email,
                '',
                game.site,
                routePrefix,
                true,
                true
              ).then((res) => {
                if (res.error) {
                  this.setState({ currAlert: res.error })
                  return
                }
                this.setState({
                  currAlert: 'The user has been sent a Forgot Password email',
                })
              })
            }
          >
            Send Forgot Password Email
          </AdminButton>
        )}
        {can('ADMIN:DELETE_LISTINGS') && (
          <AdminButton
            onClick={() =>
              this.setState({
                modalOpen: true,
                modalText:
                  'Are you sure you want to delete all the listings for this user?',
                modalCategory: 'deleteAllListings',
              })
            }
          >
            Delete All Listings for User
          </AdminButton>
        )}
        {can('ADMIN:MUTE_USER') && (
          <AdminButton
            onClick={() =>
              muteUser({
                userId: user.id,
                mutedId: muted === undefined ? undefined : muted.role_id,
              }).then((res) => {
                if (res.error) {
                  this.setState({
                    currAlert: `Error in muting user ${user.id}: ${res.error}`,
                  })
                } else {
                  this.setState({
                    currAlert: `You have ${
                      muted === undefined ? 'muted' : 'unmuted'
                    } this user`,
                  })
                }
                this.setState({ currUser: '' })
              })
            }
            aria-label={muted === undefined ? 'Mute User' : 'Unmute User'}
          >
            {muted === undefined ? 'Mute User' : 'Unmute User'}
          </AdminButton>
        )}
        {can('USERS:DELETE') && (
          <AdminButton
            onClick={() => {
              this.setState({
                modalOpen: true,
                modalText:
                  'Are you sure you want to delete this user? If so, the account will be scheduled to be deleted in 3 days.',
                modalCategory: 'deleteUser',
              })
            }}
            aria-label='Delete User'
            style={{ background: 'red' }}
          >
            Delete User
          </AdminButton>
        )}
        {open && (
          <ModReportModal
            onClose={() =>
              this.setState({
                open: false,
              })
            }
            onSubmit={this.onSubmit}
            open={open}
          />
        )}
        {banReason && !user.banned && (
          <BanReasonModal
            onClose={() =>
              this.setState({
                banReason: false,
              })
            }
            onSubmit={this.banUserReason}
            open={banReason}
          />
        )}
        {modalOpen && (
          <Modal
            open={modalOpen}
            onCancel={() => {
              this.setState({ modalOpen: false })
            }}
            body={
              <div className='delete-modal-body'>
                <h2>{modalText}</h2>
                <button
                  style={{ backgroundColor: '#f5222d' }}
                  onClick={() => {
                    this.setState({ modalOpen: false })
                    switch (modalCategory) {
                      case 'deleteAllListings':
                        return deleteAllListings({
                          userId: user.id,
                        }).then((res) => {
                          if (res.error) {
                            this.setState({ currAlert: res.error })
                            return
                          }
                          this.setState({
                            currAlert:
                              "The user's listings for this game have been deleted",
                          })
                        })
                      case 'deleteUser':
                        return deleteUser({
                          user: user.id,
                        }).then((res) => {
                          if (res.error) {
                            this.setState({
                              currAlert: `Error in deleting user ${user.id}: ${res.error}`,
                            })
                          } else {
                            this.setState({
                              currAlert: `The user's account has been scheduled to be deleted in 3 days.`,
                            })
                          }
                          history.push(`${routePrefix}/admin/users/`)
                        })
                      default:
                        return ''
                    }
                  }}
                >
                  <Trans i18nKey='yes' />
                </button>
              </div>
            }
            noHeader
          />
        )}
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default AdminActions
