import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import GameContext from '../../GameContext'
import { getPropLabel } from '../../services/properties'

import { StyledSelect } from '../Styled'
import CheckboxFilter from '../inputs/CheckboxFilter'
import MinMaxFilter from './MinMaxFilter'
import DiabloMinMaxFilter from './MinMaxFilter/DiabloMinMaxFilter'
import MultiSelectFilter from './MultiSelectFilter'

const PropertyFilters = ({
  properties,
  query,
  updateQuery,
  location,
  defaultProp,
  getMinMaxValue,
  updateMinMaxValue,
  updatePropFilters,
  applyFilters,
  deleteFromFilter,
  overflow,
}) => {
  const { game } = useContext(GameContext)
  const { t } = useTranslation()

  const getSlicedItemProperties = () => {
    if (properties) {
      if (
        applyFilters ||
        defaultProp ||
        properties.filter((prop) => !prop.format?.group).length < 15
      )
        return properties

      let queryProperties = Object.keys(query)
        .filter((prop) => /prop_/.test(prop))
        .map((prop) => parseInt(prop.replace(/\D/g, '')))

      return properties.filter(
        (prop) =>
          (prop.format && prop.format.default) ||
          queryProperties.includes(prop.id)
      )
    }
    return []
  }

  const [currProps, setCurrProps] = useState(getSlicedItemProperties())

  useEffect(() => {
    if (applyFilters) setCurrProps(getSlicedItemProperties())
  }, [properties, applyFilters])

  const separatedProp = (prop) => {
    return prop.format ? !prop.format.default : true
  }

  let selectProps = properties.filter(
    (prop) =>
      separatedProp(prop) && !currProps.find((cProp) => cProp.id === prop.id)
  )

  return (
    <>
      {currProps
        .filter((currProp) => !currProp.format?.group)
        .map((prop) => {
          if (prop.type === 'multiselect')
            prop.options = currProps
              .filter((p) => p.format?.group?.name === prop.property)
              .map((p) => p.property)
          let propInitMax = `prop_${prop.id}Max`
          let propInitMin = `prop_${prop.id}Min`
          if (prop.property === 'Amount') {
            propInitMax = 'amountMax'
            propInitMin = 'amountMin'
          }
          switch (prop.type) {
            case 'string':
            case 'array':
            case 'multiselect':
              return (
                <MultiSelectFilter
                  initOptions={query[`prop_${prop.property}`]}
                  itemProperties={prop.options}
                  key={prop.property}
                  location={location}
                  name={prop.property}
                  property={prop}
                  style={{ marginRight: 5, marginBottom: 5 }}
                  sidebarStyle={applyFilters}
                  updatePropFilters={updatePropFilters}
                  updateQuery={updateQuery}
                  overflow={overflow}
                  currProps={currProps}
                  setCurrProps={setCurrProps}
                  onDelete={() => {
                    if (separatedProp(prop)) {
                      setCurrProps(
                        [...currProps].filter(
                          (p) => JSON.stringify(p) !== JSON.stringify(prop)
                        )
                      )
                    }
                  }}
                />
              )
            case 'number':
              let propType = prop.property
              if (game.schema === 'pokemonswordshield') {
                propType = prop.property
                  .split(' ')
                  .map((word) =>
                    word.length === 2
                      ? word.charAt(0).toUpperCase() +
                        word.charAt(1).toUpperCase()
                      : word.charAt(0).toUpperCase() + word.slice(1)
                  )
                  .join(' ')
              }

              return game.has('LISTINGS:STATS') ? (
                <DiabloMinMaxFilter
                  initMax={query[propInitMax]}
                  initMin={query[propInitMin]}
                  key={prop.property}
                  maxValue={getMinMaxValue(prop.property, prop.id, 'Max')}
                  minValue={getMinMaxValue(prop.property, prop.id, 'Min')}
                  property={prop}
                  propId={prop.id}
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                    overflowY: 'scroll',
                  }}
                  sidebarStyle={applyFilters}
                  type={prop.property}
                  updateValue={updateMinMaxValue}
                  query={query}
                  onClear={() => {
                    updateMinMaxValue(
                      { target: { value: null } },
                      prop.property,
                      prop.id,
                      'Min'
                    )
                    updateMinMaxValue(
                      { target: { value: null } },
                      prop.property,
                      prop.id,
                      'Max'
                    )
                    updateQuery({
                      [`prop_${prop.id}Min`]: null,
                      [`prop_${prop.id}Max`]: null,
                    })
                  }}
                  onDelete={() => {
                    // Reset all values to null
                    updateMinMaxValue(
                      { target: { value: null } },
                      prop.property,
                      prop.id,
                      'Min'
                    )
                    updateMinMaxValue(
                      { target: { value: null } },
                      prop.property,
                      prop.id,
                      'Max'
                    )
                    const update = {
                      [`prop_${prop.id}Min`]: null,
                      [`prop_${prop.id}Max`]: null,
                    }
                    updateQuery(update)
                    // Delete from separated props (not default)
                    if (separatedProp(prop)) {
                      setCurrProps(
                        [...currProps].filter(
                          (p) => JSON.stringify(p) !== JSON.stringify(prop)
                        )
                      )
                    }
                    // Delete props in sidebar
                    if (deleteFromFilter) deleteFromFilter(prop.id)
                  }}
                />
              ) : (
                <MinMaxFilter
                  initMax={query[propInitMax]}
                  initMin={query[propInitMin]}
                  key={prop.id}
                  location={location}
                  propId={prop.id}
                  prop={prop}
                  style={{ marginRight: 5, marginBottom: 5 }}
                  type={propType}
                  updateQuery={updateQuery}
                />
              )
            case 'bool':
              return prop.options ? (
                <MultiSelectFilter
                  initOptions={query[`prop_${prop.property}`]}
                  itemProperties={prop.options}
                  key={prop.property}
                  location={location}
                  name={prop.property}
                  property={prop}
                  updateQuery={updateQuery}
                  style={{ marginRight: 5, marginBottom: 5 }}
                />
              ) : (
                <CheckboxFilter
                  key={prop.property}
                  value={query[`prop_${prop.property}`]}
                  onChange={(value) => {
                    updateQuery({
                      [`prop_${prop.property}`]: value,
                    })
                  }}
                  label={_.upperFirst(prop.property)}
                  sidebarStyle={applyFilters}
                  updatePropFilters={updatePropFilters}
                />
              )
            default:
              return (
                <CheckboxFilter
                  key={prop.property}
                  value={query[`prop_${prop.property}`]}
                  onChange={(value) => {
                    updateQuery({
                      [`prop_${prop.property}`]: value,
                    })
                  }}
                  label={_.upperFirst(prop.property)}
                />
              )
          }
        })}
      {game.has('LISTINGS:STATS') && applyFilters && (
        <button
          id='sidebar-apply-filters-btn'
          style={{ marginTop: '10px' }}
          onClick={() => {
            applyFilters(currProps)
            window.dataLayer.push({
              event: 'filters',
              eventProps: {
                category: 'Filters',
                action: 'Apply Stats - Homepage',
              },
            })
          }}
          aria-label='Apply Filters'
        >
          {t('applyFilters')}
        </button>
      )}
      {selectProps.length > 0 && (
        <div style={{ width: 200, marginBottom: 5 }}>
          <StyledSelect
            options={selectProps.map((prop) => ({
              label: getPropLabel(prop),
              value: prop,
            }))}
            onChange={(prop) => {
              setCurrProps([...currProps, prop.value])
            }}
            value=''
            placeholder={t('moreFilters')}
          />
        </div>
      )}
    </>
  )
}

export default PropertyFilters
