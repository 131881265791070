import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { StyledGameDiv, StyledLink } from '../../Styled'
import GameContext from '../../../GameContext'
import { UserContext } from '../../../contexts'
import AdSlot from '../../AdSlot'

import Listings from '../../Listings/Listings'
import ItemSlider from '../ItemSlider'
import LatestCatalog from '../../Catalog/LatestCatalog'

import './style.css'

const LatestListings = ({ free, noForYou, latestSize }) => {
  const history = useHistory()
  const location = useLocation()
  const { game, routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)
  let hasLookingFor = game.has('LISTINGS:LOOKING_FOR')

  return (
    <div>
      {!game.has('HOME:NO_ITEMS') && (
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <ItemSlider
              title={
                <>
                  <Trans i18nKey='todayHotItems' />{' '}
                  <span role='img' aria-label='fire'>
                    🔥
                  </span>
                </>
              }
              hot
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <ItemSlider
              title={
                <>
                  <Trans i18nKey='newItems' />{' '}
                  <span role='img' aria-label='sparkle'>
                    ✨
                  </span>
                </>
              }
              params={{ orderBy: 'posted-desc', variants: true }}
            />
          </div>
        </div>
      )}
      {game.has('HOME:CATALOG') && (
        <>
          <StyledLink
            className='home-catalog'
            to={{
              pathname: user
                ? `${routePrefix}/profile/${user.id}/catalog`
                : '/signup',
              state: { from: location.pathname },
            }}
          >
            <h2>Start Your Catalog</h2>
            <img
              src='https://cdn.nookazon.com/traderie/home/catalog.png'
              alt='start catalog'
            />
          </StyledLink>
          <LatestCatalog />
        </>
      )}
      <div className='home-listings'>
        <div className='latest-section'>
          <StyledLink
            to={`${routePrefix}/listings/selling`}
            className='home-title-container'
            onClick={() => {
              window.scrollTo(0, 0)
              window.dataLayer.push({
                event: 'seeMore',
                eventProps: {
                  category: 'Home See More',
                  action: 'Latest Listings',
                },
                userId: user ? user.id : undefined,
                email: user ? user.email : undefined,
              })
            }}
            aria-label='See More Latest Listings'
          >
            <h2 className='home-header'>
              <Trans i18nKey='latestListings' />
            </h2>
            <StyledGameDiv className='home-see-all'>
              <Trans i18nKey='seeAll' />
            </StyledGameDiv>
          </StyledLink>
          <Listings
            grid={hasLookingFor ? 3 : 4}
            hideLoad
            hideView
            history={history}
            horizontal
            location={location}
            size={latestSize || 9}
            slider={game.has('LISTINGS:SLIDER')}
            vertical={hasLookingFor}
            forceShowDetails
          />
        </div>
        {game.has('LISTINGS:AUCTIONS') && (
          <div className='latest-section '>
            <StyledLink
              to={`${routePrefix}/listings/auctions?orderBy=endtime-asc`}
              className='home-title-container'
              onClick={() => {
                window.scrollTo(0, 0)
                window.dataLayer.push({
                  event: 'seeMore',
                  eventProps: {
                    category: 'Home See More',
                    action: 'Auctions',
                  },
                  userId: user ? user.id : undefined,
                  email: user ? user.email : undefined,
                })
              }}
              aria-label='See More Auctions'
            >
              <h2 className='home-header'>
                <Trans i18nKey='auctions' /> (<Trans i18nKey='endingSoon' />)
              </h2>
              <StyledGameDiv className='home-see-all'>
                <Trans i18nKey='seeAll' />
              </StyledGameDiv>
            </StyledLink>
            <Listings
              auction
              defaultSort='endtime-asc'
              grid={hasLookingFor ? 3 : 4}
              hideLoad
              hideView
              history={history}
              horizontal
              location={location}
              size={9}
              slider={hasLookingFor}
              vertical
            />
          </div>
        )}
        <AdSlot name='pb-slot-incontent-1' divId='latest-listings-ad' />
        {free && (
          <div className='latest-section'>
            <StyledLink
              to={`${routePrefix}/listings/selling?free=true`}
              className='home-title-container'
              onClick={() => {
                window.scrollTo(0, 0)
                window.dataLayer.push({
                  event: 'seeMore',
                  eventProps: {
                    category: 'Home See More',
                    action: 'Free Listings',
                  },
                  userId: user ? user.id : undefined,
                  email: user ? user.email : undefined,
                })
              }}
              aria-label='See More Free Listings'
            >
              <h2 className='home-header'>
                <Trans i18nKey='free' />
              </h2>
              <StyledGameDiv className='home-see-all'>
                <Trans i18nKey='seeAll' />
              </StyledGameDiv>
            </StyledLink>
            <Listings
              free
              grid={hasLookingFor ? 3 : 4}
              hideLoad
              hideView
              history={history}
              horizontal
              location={location}
              size={9}
              slider={hasLookingFor}
              vertical
            />
          </div>
        )}
        {game.has('LISTINGS:LOOKING_FOR') && (
          <div className='latest-section'>
            <StyledLink
              to={`${routePrefix}/listings/buying`}
              className='home-title-container'
              onClick={() => {
                window.scrollTo(0, 0)
                window.dataLayer.push({
                  event: 'seeMore',
                  eventProps: {
                    category: 'Home See More',
                    action: 'Looking For Listings',
                  },
                  userId: user ? user.id : undefined,
                  email: user ? user.email : undefined,
                })
              }}
              aria-label='See More Looking For Listings'
            >
              <h2 className='home-header'>
                <Trans i18nKey='lookingFor' />
              </h2>
              <StyledGameDiv className='home-see-all'>
                <Trans i18nKey='seeAll' />
              </StyledGameDiv>
            </StyledLink>
            <Listings
              buying
              grid={hasLookingFor ? 3 : 4}
              hideLoad
              hideView
              history={history}
              horizontal
              location={location}
              size={9}
              slider={hasLookingFor}
              vertical
            />
          </div>
        )}
        {user && !noForYou && (
          <div>
            <StyledLink
              to={`${routePrefix}/listings/foryou`}
              className='home-title-container'
              onClick={() => {
                window.scrollTo(0, 0)
                window.dataLayer.push({
                  event: 'seeMore',
                  eventProps: {
                    category: 'Home See More',
                    action: 'For You Listings',
                  },
                  userId: user ? user.id : undefined,
                  email: user ? user.email : undefined,
                })
              }}
              aria-label='See More For You Listings'
            >
              <h2 className='home-header'>
                <Trans i18nKey='forYou' /> {user.username}
              </h2>
              <StyledGameDiv className='home-see-all'>
                <Trans i18nKey='seeAll' />
              </StyledGameDiv>
            </StyledLink>
            <Listings
              grid={hasLookingFor ? 3 : 4}
              hideLoad
              hideView
              history={history}
              horizontal
              location={location}
              matching
              size={9}
              slider={hasLookingFor}
              vertical
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default LatestListings
