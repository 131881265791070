import React from 'react'
import { Helmet } from 'react-helmet'
import { Trans } from 'react-i18next'

import GameContext from '../../GameContext'
import http from '../../services/http'
import { getUser } from '../../services/users'
import AdSlot from '../../components/AdSlot'
import Items from '../../components/Items'
import { getWishlists } from '../../components/Items/AddToWishlist'

import './style.css'

class HotItems extends React.Component {
  state = {
    items: [],
    wishlists: [],
    loading: true,
  }

  static contextType = GameContext

  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    http.get(`/items/hot`).then((res) => {
      if (res.items) this.setState({ items: res.items, loading: false })
    })
    if (getUser()) {
      getWishlists(null).then((wishlists) => {
        this.setState({ wishlists })
      })
    }
  }

  render = () => {
    const { game } = this.context
    let { items, wishlists, loading } = this.state

    return (
      <div className='hot-items'>
        <Helmet>
          <title>
            Top {game.getTitle()} Items | Today's Hot Items | {game.getSite()}
          </title>
          <meta
            name='description'
            content={`The top 100 ${
              game.name
            } items traded everyday on ${game.getSite()}!`}
          />
        </Helmet>
        <AdSlot name='pb-slot-incontent-1' divId='hotitems-ad-1' />
        <div className='container'>
          <div className='home-title-container'>
            <h1 className='home-header' style={{ fontSize: 30 }}>
              <Trans i18nKey='todayHotItems' />{' '}
              <span role='img' aria-label='fire'>
                🔥
              </span>
            </h1>
          </div>
          <Items hot items={items} loading={loading} wishlists={wishlists} />
        </div>
        <AdSlot name='pb-slot-incontent-2' divId='hotitems-ad-1' />
      </div>
    )
  }
}

export default HotItems
