import React from 'react'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import { BsCheckCircleFill } from 'react-icons/bs'
import { FaArrowRight, FaDiscord } from 'react-icons/fa'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { Trans } from 'react-i18next'

import Alert from '../../components/Alert'
import Button from '../../components/inputs/Button'
import CancelExitSurvey from './CancelExitSurvey'
import Modal from '../../components/Modal'
import { Tag } from '../../components/common'

import GameContext from '../../GameContext'
import http from '../../services/http'
import tracker from '../../services/tracker'
import { getUser, setUser } from '../../services/users'
import { sale } from '../../services/sales'
import { upperCaseEachWord } from '../../services/validate'
import {
  createCheckoutSession,
  createPortalSession,
} from '../../services/stripe'

import './style.css'

const isApp = window.localStorage.getItem('app')
const isIOS = window.localStorage.getItem('platform')
const currentTheme = window.localStorage.getItem('theme')

const StyledTagLine = styled.h3`
  color: ${({ theme }) => theme.textSub};
  font-size: 18px;
  font-weight: normal;
`

const StyledSubText = styled.span`
  color: ${({ theme }) => theme.textSub};
`

const StyledTierCard = styled.div`
  background-color: ${({ theme }) =>
    currentTheme === 'light' ? theme.body : theme.bodyAlt};
  border: ${({ theme, popular }) =>
    popular ? `3px solid ${theme.primary}` : `1px solid ${theme.border}`};
  box-shadow: ${({ popular }) =>
    popular ? 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' : 'none'};
`

const StyledTierName = styled.h4`
  color: ${({ theme }) => theme.primary};
  font-weight: normal;
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 0px;
`

const StyledTierPrice = styled.span`
  font-weight: bold;
  font-size: 35px;
  margin-bottom: -10px;
  color: ${({ sale }) => (sale ? 'red' : 'none')};
  text-decoration: ${({ sale }) => (sale ? 'line-through' : 'none')};
`

const StyledPopularTag = styled.span`
  color: ${({ theme }) => theme.primary};
  font-size: 18px;
  background-color: ${({ theme }) =>
    currentTheme === 'light' ? theme.bodyAlt : theme.border};
  border-radius: 24px;
  align-self: center;
  padding: 0px 16px;
  margin-top: 8px;
`

const StyledHLine = styled.hr`
  height: 1px;
  background-color: #e5e7eb;
  border: none;
  margin-top: 12px;
`

const StyledAnnualPrice = styled.div`
  font-size: 16px;
  color: ${({ sale }) => (sale ? 'red' : 'none')};
  text-decoration: ${({ sale }) => (sale ? 'line-through' : 'none')};
`

const StyledShowMore = styled.span`
  color: ${({ theme }) => theme.primary};
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: ${({ theme }) => theme.primaryHover};
  }
`

const StyledBillTab = styled.span`
  color: ${({ active, theme }) => (active ? theme.primary : theme.text)};
  background-color: ${({ active, theme }) =>
    active ? theme.bodyAlt : theme.body};
  transition: all 0.3s;

  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.bodyAltHover : theme.bodyAlt};
  }
`

const StyledSubButton = styled(Button)`
  background-color: ${({ popular, theme }) =>
    !popular ? theme.border : theme.primary};
  color: ${({ popular, theme }) => (!popular ? theme.primary : theme.bodyAlt)};
  transition: all 0.3s;
  float: bottom;

  &:hover {
    background-color: ${({ popular }) =>
      !popular
        ? currentTheme === 'light'
          ? '#D1D5DB'
          : '#131415'
        : currentTheme === 'light'
        ? '#BAE8E5'
        : '#60BCB5'};
    color: ${({ popular }) =>
      !popular
        ? currentTheme === 'light'
          ? '#14998F'
          : '#60BCB5'
        : currentTheme === 'light'
        ? '#118077'
        : '#2C2E30'};
  }
`
// Benefits and descriptions are their associated nookazon-translator i8n keys
const tiers = {
  companion: {
    price: 3,
    points: 150,
    benefits: {
      adFree: 'adFreeDesc',
      listingCustomization: `listingCustomizationDesc`,
      customPFP: 'customPFPDesc',
      exclusiveDiscord: 'exclusiveDiscordDesc',
      emojiSub: `emojiSubDesc`,
    },
    monthlyPriceId: 'price_1J0SaxCX9CcTgNfGGN1IYnPy',
    yearlyPriceId: 'price_1J0SaxCX9CcTgNfG6WB7nPpI',
    devmonthlyPriceId: 'price_1IiRGlCX9CcTgNfGtUMcHkXz', //test
    // yearlyPriceId: 'price_1IiRGlCX9CcTgNfGQjlsyiCV', //test
  },
  ally: {
    price: 5,
    points: 150,
    benefits: {
      discounts: 'discountDesc',
      customPresets: 'customPresetsDesc',
      proBadge: 'badgeDesc',
      proRole: `proRoleDesc`,
    },
    monthlyPriceId: 'price_1J0SQZCX9CcTgNfGwjxe9cZb',
    yearlyPriceId: 'price_1J0SQZCX9CcTgNfGRfXBktBl',
    // monthlyPriceId: 'price_1IiRHDCX9CcTgNfGpONAu1ZX', //test
    // yearlyPriceId: 'price_1IiRHDCX9CcTgNfGm8WAEeih', //test
  },
  champion: {
    price: 10,
    points: 300,
    benefits: {
      refreshTime: 'refreshDesc12',
      relistAll: 'relistAllDesc',
      listingsPer: 'listingsPerDesc',
      proBadge: 'badgeDesc',
      proRole: `proRoleDesc`,
    },
    monthlyPriceId: 'price_1J0SRACX9CcTgNfGolGTuCLp',
    yearlyPriceId: 'price_1J0SRACX9CcTgNfG7yz4uFxi',
  },
  epic: {
    price: 25,
    points: 450,
    benefits: {
      customUrl: 'customUrlDesc',
      refreshTime: 'refreshDesc6',
      earlyAccess: `earlyAccessDesc`,
      proBadge: 'badgeDesc',
      proRole: `proRoleDesc`,
    },
    monthlyPriceId: 'price_1J0SRECX9CcTgNfGm6KartZP',
    yearlyPriceId: 'price_1J0SRECX9CcTgNfGHZAunZpA',
  },
  legendary: {
    price: 100,
    points: 600,
    benefits: {
      refreshTime: 'refreshDesc1',
      msgChannels: 'personalMsgDesc',
      proBadge: 'badgeDesc',
      proRole: `proRoleDesc`,
    },
    monthlyPriceId: 'price_1J0SRICX9CcTgNfGFaKTBOKu',
    yearlyPriceId: 'price_1J0SRICX9CcTgNfG6eClfiQU',
  },
}

const BillTabs = ({ monthly, onTabClick, t }) => {
  return (
    <span className='akrew-pro-tabs-container'>
      <StyledBillTab
        className={`akrew-pro-tab ${monthly ? '' : 'active'}`}
        active={!monthly}
        onClick={onTabClick}
      >
        {t('billYearly')}
        <Tag
          style={{
            fontSize: '14px',
            color: '#22C55E',
            width: '50px',
            border: 'none',
            backgroundColor: currentTheme === 'light' ? '#F0FDF4' : '#14532D',
            fontWeight: 'normal',
          }}
        >
          -16%
        </Tag>
      </StyledBillTab>
      <StyledBillTab
        className={`akrew-pro-tab ${monthly ? 'active' : ''}`}
        active={monthly}
        onClick={onTabClick}
      >
        {t('billMonthly')}
      </StyledBillTab>
    </span>
  )
}

class AkrewPro extends React.Component {
  static contextType = GameContext
  state = {
    manageLoading: false,
    cancelLoading: false,
    reactivateLoading: false,
    currPlan: {},
    tierName: '',
    monthly: false,
    canceledSub: false,
    appSignUp: false,
    showMore: false,
    currAlert: '',
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    let { history } = this.props
    const user = getUser()
    if (user) {
      this.backListener = history.listen((location, action) => {
        if (action === 'POP')
          tracker.track('Back Button Clicked', { user_id: user?.id })
      })
      http.get('/stripe/getUserInfo').then((res) => {
        if (res.err) this.setState({ currAlert: res.err })
        if (res.user) {
          user.roles = res.user.roles
          user.stripe_customer_id = res.user.stripe_customer_id
          user.stripe_subscription_status = res.user.stripe_subscription_status
          user.stripe_subscription_end = res.user.stripe_subscription_end
          user.stripe_cancel_reason = res.user.stripe_cancel_reason
          user.stripe_cancel_at = res.user.stripe_cancel_at
          setUser(user)
        }
      })
      this.getCurrentPlan(user)
    }
  }

  getCurrentPlan = (user) => {
    const tierNames = ['companion', 'ally', 'champion', 'epic', 'legendary']
    const currRole = user.roles
      ? user.roles.filter((r) => tierNames.includes(r.title))
      : []

    if (currRole.length > 0) {
      let plan = {}
      plan[currRole[0].title] = tiers[currRole[0].title]
      this.setState({ currPlan: plan })
    }
  }

  deleteSubscription = async (customerId, subscriptionEnd) => {
    let { t } = this.props
    let { currPlan } = this.state
    const response = await http.del('/stripe/deleteSubscription', {
      customer_id: customerId,
      action: 'cancel',
    })
    if (response.error) {
      this.setState({ cancelLoading: false })
      this.setState({ currAlert: t('cancelSubError') })
    } else {
      let user = getUser()
      if (user.roles) {
        let otherRoles = user.roles.filter(
          (r) => r.title !== Object.keys(currPlan)[0]
        )
        user.roles = otherRoles
        user.stripe_subscription_status = 'canceled'
        user.stripe_cancel_at = subscriptionEnd
        setUser(user)
      }
      this.setState({ canceledSub: true })
    }
  }

  reactivateSubscription = async (customerId) => {
    let { history, t } = this.props
    const { routePrefix } = this.context
    const response = await http.del('/stripe/deleteSubscription', {
      customer_id: customerId,
      action: 'reactivate',
    })
    if (response.error) {
      this.setState({ reactivateLoading: false })
      this.setState({ currAlert: t('reactivateError') })
    } else {
      let user = getUser()
      user.stripe_cancel_at = null
      setUser(user)
      history.push(`${routePrefix}`)
    }
  }

  closeExitSurvey = async () => {
    let { history } = this.props
    const { routePrefix } = this.context
    this.setState({ canceledSub: false })
    history.push(`${routePrefix}`)
  }

  handleSignUpClick = (tier, isMonthly, type) => {
    let user = getUser()
    if (isApp && isIOS !== 'true') {
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage)
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            akrewpro: tier[0],
            monthly: isMonthly,
          })
        )
    } else {
      this.setState({
        tierName: !isApp && tier[0],
        monthly: isMonthly,
        appSignUp: isApp && true,
      })
      tracker.track('Started Akrew Pro Signup', {
        tier_name: tier[0],
        user_id: user?.id,
      })
      if (type === 'year') {
        createCheckoutSession(
          tier[1].yearlyPriceId,
          'subscription',
          sale.on ? tier[1].points : null
        )
      } else {
        let key = 'monthlyPriceId'
        if (process.env.REACT_APP_STRIPE_ENV === 'development')
          key = 'dev' + key
        createCheckoutSession(
          tier[1][key],
          'subscription',
          sale.on ? tier[1].points : null
        )
      }
    }
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    const { game } = this.context
    const siteName = game.getSite()
    const currentTheme = window.localStorage.getItem('theme') || 'light'
    let {
      currAlert,
      manageLoading,
      cancelLoading,
      reactivateLoading,
      currPlan,
      tierName,
      monthly,
      canceledSub,
      appSignUp,
      showMore,
    } = this.state
    let { history, t } = this.props
    let currUser = getUser()
    const isOverwolf = localStorage.getItem('overwolf')

    return (
      <div>
        <Helmet>
          <title>Akrew Pro | {game.getSite()}</title>
          <meta
            name='description'
            content='Get the best of Nookazon & Traderie. An ad free experience, custom profile pictures, relist all capability, and more.'
          />
        </Helmet>
        <h1 style={{ display: 'none' }}>Akrew Pro</h1>
        <div className='patreon-banner'>
          {sale.on ? (
            <div className='patreon-banner-sale'>
              <img
                src={sale.banner}
                alt='akrew pro sale logo'
                style={{ maxHeight: '100%' }}
              />
            </div>
          ) : (
            <div className='patreon-banner-info'>
              <span>
                <img
                  src={`https://cdn.nookazon.com/patreon/akrewpro${currentTheme}.svg`}
                  style={{ marginTop: '24px' }}
                  alt='akrew pro logo'
                />
              </span>
              <h2
                style={{
                  fontSize: '38px',
                  marginTop: '16px',
                  marginBottom: '16px',
                }}
              >
                {t('akrewProHeader', { siteName })}
              </h2>
              <StyledTagLine>
                {t('akrewProTagLine')}
                {isOverwolf && (
                  <span>&nbsp;{t('akrewProOverwolfTagLine')}</span>
                )}
              </StyledTagLine>
            </div>
          )}
        </div>
        <BillTabs
          monthly={monthly}
          onTabClick={() => this.setState({ monthly: !monthly })}
          t={t}
        />
        <div className='patreon-page'>
          <div style={{ marginTop: 10 }}>
            {sale.on && (
              <span style={{ fontWeight: 'bold' }}>
                {t('akrewProSaleAbout')}
              </span>
            )}
          </div>
          <StyledShowMore
            onClick={() => this.setState({ showMore: !showMore })}
            className='akrew-pro-show-more-mobile'
          >
            {showMore ? <>{t('showLessPro')}</> : <>{t('showMorePro')} </>}
          </StyledShowMore>
          <div className='patreon-tiers'>
            {Object.entries(tiers).map((tier) => {
              const currPrice = tier[1].price * (1 - sale.amount)
              const popular = tier[0] === 'champion'
              return (
                <div key={tier[0]} className='tier-container'>
                  <img
                    src={`https://cdn.nookazon.com/badges/${tier[0]}.png`}
                    alt={tier[0]}
                    className='tier-icon'
                    style={{ maxWidth: 120 }}
                  />
                  <StyledTierCard className='tier-card' popular={popular}>
                    {popular && (
                      <StyledPopularTag>🔥 {t('mostPopular')}</StyledPopularTag>
                    )}
                    <span className='tier-card-header'>
                      <StyledTierName>
                        {upperCaseEachWord(tier[0])}
                      </StyledTierName>
                      <StyledTierPrice sale={sale.on}>
                        $
                        {monthly
                          ? tier[1].price
                          : ((tier[1].price * 10) / 12).toFixed(2)}
                      </StyledTierPrice>
                      {sale.on && (
                        <span className='tier-price'>
                          &nbsp;$
                          {currPrice % 1 === 0
                            ? currPrice
                            : parseFloat(currPrice).toFixed(2)}
                        </span>
                      )}
                      <StyledSubText style={{ marginLeft: '4px' }}>
                        / {t('month')}
                      </StyledSubText>
                      {!monthly && (
                        <StyledSubText style={{ display: 'block' }}>
                          {t('billAnnually', { price: tier[1].price * 10 })}
                        </StyledSubText>
                      )}
                      <StyledHLine />
                      {sale.on && (
                        <div>
                          <StyledAnnualPrice>
                            {t('salePriceYear', {
                              price: Math.round(currPrice * 10),
                            })}
                          </StyledAnnualPrice>
                          {t('saleCode', { code: sale.code })}
                          <div>
                            {t('receivePoints', { points: tier[1].points })}
                          </div>
                        </div>
                      )}
                    </span>
                    <ul className='tier-benefits'>
                      {Object.entries(tier[1].benefits).map((benefit) => {
                        return (
                          <li key={benefit[0]} className='tier-benefit-item'>
                            <BsCheckCircleFill className='tier-info-check' />
                            <span style={{ fontWeight: 'bold' }}>
                              {t(benefit[0], {
                                tier: upperCaseEachWord(tier[0]),
                              })}
                            </span>
                            {showMore && (
                              <>
                                :{' '}
                                {t(benefit[1], {
                                  tier: upperCaseEachWord(tier[0]),
                                })}
                              </>
                            )}
                          </li>
                        )
                      })}
                      {tier[0] !== 'companion' && (
                        <li className='tier-benefit-item'>
                          <BsCheckCircleFill className='tier-info-check' />
                          <span style={{ fontWeight: 'bold' }}>
                            {t('prevTiers')}
                          </span>
                        </li>
                      )}
                    </ul>
                    {tier[0] === 'companion' && (
                      <span className='patreon-discord'>
                        <FaDiscord style={{ fontSize: 15, color: '#7289da' }} />
                        &nbsp;{t('discordBenefits')}
                      </span>
                    )}
                    {currUser ? (
                      <span>
                        {isOverwolf ? (
                          <span className='tier-btn-bar'>
                            <Button
                              label={t('joinOnTraderie')}
                              className='patreon-btn'
                              onClick={() => {
                                window.open('https://traderie.com/akrewpro')
                              }}
                              loading={reactivateLoading}
                            />
                          </span>
                        ) : (
                          <span className='tier-btn-bar'>
                            {Object.keys(currPlan).length > 0 ? (
                              <>
                                {Object.keys(currPlan)[0] === tier[0] && (
                                  <>
                                    {currUser.stripe_cancel_at ? (
                                      <>
                                        <div
                                          style={{
                                            fontWeight: 'bold',
                                            color: 'red',
                                          }}
                                        >
                                          {t('benefitsEnd')}{' '}
                                          {DateTime.fromMillis(
                                            currUser.stripe_cancel_at * 1000
                                          ).toLocaleString(DateTime.DATE_FULL)}
                                        </div>
                                        <Button
                                          label={t('reactivateSubscription')}
                                          className='patreon-btn'
                                          id='reactivate-subscription'
                                          onClick={() => {
                                            this.setState({
                                              reactivateLoading: true,
                                            })
                                          }}
                                          loading={reactivateLoading}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          label={t('manageSubscription')}
                                          className='patreon-btn'
                                          id='manage-billing-form'
                                          onClick={() => {
                                            if (
                                              currUser.android_subscription_status ===
                                                'active' ||
                                              currUser.android_subscription_status ===
                                                'pending_cancellation'
                                            ) {
                                              this.setState({
                                                currAlert: t('googlePlayPro'),
                                              })
                                            } else {
                                              this.setState({
                                                manageLoading: true,
                                              })
                                              createPortalSession(
                                                currUser.stripe_customer_id
                                              )
                                              this.setState({
                                                manageLoading: false,
                                              })
                                            }
                                          }}
                                          loading={manageLoading}
                                        />
                                        <Button
                                          label={t('cancelSubscription')}
                                          className='patreon-btn patreon-btn-alt'
                                          id='cancel-subscription'
                                          onClick={() => {
                                            if (
                                              currUser.android_subscription_status ===
                                                'active' ||
                                              currUser.android_subscription_status ===
                                                'pending_cancellation'
                                            ) {
                                              this.setState({
                                                currAlert: t(
                                                  'googlePlayProCancel'
                                                ),
                                              })
                                            } else {
                                              this.setState({
                                                cancelLoading: true,
                                              })
                                            }
                                          }}
                                          loading={cancelLoading}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <StyledSubButton
                                  square
                                  label={
                                    <>
                                      {monthly ? (
                                        <>{t('monthlySub')}</>
                                      ) : (
                                        <>{t('yearlySub')}</>
                                      )}
                                      {popular && (
                                        <FaArrowRight
                                          style={{ marginLeft: '8px' }}
                                        />
                                      )}
                                    </>
                                  }
                                  className={`patreon-btn ${
                                    !popular ? 'patreon-btn-alt' : 'popular-btn'
                                  }`}
                                  id='checkout'
                                  onClick={() =>
                                    this.handleSignUpClick(
                                      tier,
                                      monthly,
                                      monthly ? 'month' : 'year'
                                    )
                                  }
                                  loading={tierName === tier[0] && !monthly}
                                  popular={popular}
                                />
                              </>
                            )}
                          </span>
                        )}
                      </span>
                    ) : (
                      <StyledSubButton
                        square
                        label={t('loginToJoin')}
                        onClick={() => {
                          window.scrollTo(0, 0)
                          history.push('/login')
                        }}
                        className='patreon-btn'
                        aria-label='Join'
                      ></StyledSubButton>
                    )}
                    {Object.keys(currPlan).length > 0 &&
                      Object.keys(currPlan)[0] === tier[0] &&
                      tier[0] !== 'companion' && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          {t('merchCode/')}{' '}
                          <div style={{ fontWeight: 'bold' }}>AKREWPRO10</div>
                        </div>
                      )}
                  </StyledTierCard>
                </div>
              )
            })}
          </div>
          <StyledShowMore
            onClick={() => this.setState({ showMore: !showMore })}
            className='akrew-pro-show-more-web'
          >
            {showMore ? <>{t('showLessPro')}</> : <>{t('showMorePro')}</>}
          </StyledShowMore>
          <div className='akrew-pro-page-footer'>{t('akrewProAbout')}</div>
          {canceledSub && (
            <CancelExitSurvey
              open={canceledSub}
              onClose={this.closeExitSurvey}
            />
          )}
          <Modal
            open={appSignUp}
            title={
              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                {monthly ? <>{t('monthlySub')}</> : <>{t('yearlySub')}</>}
              </div>
            }
            onConfirm={() => {
              this.setState({ appSignUp: false })
            }}
            label='Okay'
            style={{ width: 600 }}
            body={
              <div style={{ textAlign: 'center' }}>
                {t('akrewProAppSignup')}
              </div>
            }
          />
          {currUser && (
            <Modal
              open={cancelLoading}
              onCancel={() => {
                this.setState({ cancelLoading: false })
              }}
              body={
                <div className='delete-modal-body'>
                  <h2>
                    {t('proCancelCheck', {
                      date: DateTime.fromMillis(
                        parseInt(currUser.stripe_subscription_end) * 1000
                      ).toLocaleString(DateTime.DATE_FULL),
                    })}
                  </h2>
                  <button
                    style={{ backgroundColor: '#f5222d' }}
                    onClick={() => {
                      this.deleteSubscription(currUser.stripe_customer_id)
                      this.setState({ cancelLoading: false })
                    }}
                  >
                    <Trans i18nKey='yes' />
                  </button>
                </div>
              }
              noHeader
            />
          )}
          {currUser && (
            <Modal
              open={reactivateLoading}
              onCancel={() => {
                this.setState({ reactivateLoading: false })
              }}
              body={
                <div className='delete-modal-body'>
                  <h2>
                    {t('proActivateCheck', {
                      date: DateTime.fromMillis(
                        parseInt(currUser.stripe_subscription_end) * 1000
                      ).toLocaleString(DateTime.DATE_FULL),
                    })}
                  </h2>
                  <button
                    style={{ backgroundColor: '#f5222d' }}
                    onClick={() => {
                      this.reactivateSubscription(currUser.stripe_customer_id)
                      this.setState({ reactivateLoading: false })
                    }}
                  >
                    <Trans i18nKey='yes' />
                  </button>
                </div>
              }
              noHeader
            />
          )}
        </div>
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </div>
    )
  }
}

export default withTranslation()(AkrewPro)
