import React from 'react'
import { FaArrowLeft, FaDice } from 'react-icons/fa'
import { HiFlag, HiArchive, HiClipboardList } from 'react-icons/hi'
import { HiUserCircle } from 'react-icons/hi'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import can from '../../services/rbac'
import GameContext from '../../GameContext'
import { archiveConvo } from '../../services/messages'
import { requestDM } from '../../services/messages'

import Alert from '../../components/Alert'
import Button from '../../components/inputs/Button'
import Modal from '../../components/Modal'
import Pfp from '../../components/User/Pfp'
import Tooltip from '../../components/Tooltip'
import Username, { getRole } from '../../components/User/Username'
import Dropdown from '../../components/Navbar/Dropdown'
import Contacts from '../Profile/ProfileContact/Contacts'
import { StyledCounter } from './Conversations'
import { StyledLink, StyledHyperLink } from '../../components/Styled'

const StyledBack = styled(FaArrowLeft)`
  display: none;
  font-size: 20px;
  margin-right: 15px;
  vertical-align: middle;

  @media only screen and (max-width: 600px) {
    display: block;
  }
`

const Counter = styled(StyledCounter)`
  left: 15px;
  top: -20px;
`

const StyledAdminPanelLink = styled(StyledLink)`
  &:hover {
    border-radius: ${({ theme }) => theme.btnIconRadius};
    background-color: ${({ theme }) => theme.btnIconBackgroundHover};
  }
`

const isMobile = window.innerWidth <= 600

class ChatInfo extends React.Component {
  state = {
    btnDisabled: false,
    groupMembersOpen: false,
    archiveChatModal: false,
    currAlert: '',
  }
  static contextType = GameContext

  updateConvo = () => {
    let { convo, receiveConvos } = this.props
    this.setState({ btnDisabled: true })
    archiveConvo(convo.id).then((res) => {
      this.setState({ btnDisabled: false })
      if (res.error) {
        return this.setState({ currAlert: res.error })
      } else {
        this.setState({ archiveChatModal: false })
      }
      receiveConvos(res.conversation)
    })
  }

  getDropdownItems = (convo, canArchiveChat, hideReportFlag) => {
    const { openReport, history } = this.props
    const { routePrefix } = this.context

    let dropdownItems = []
    if (
      (!convo.mod_id || can('ADMIN:CHAT')) &&
      (convo.active || canArchiveChat)
    ) {
      dropdownItems.push({
        icon: <HiArchive style={{ color: '#838383', fontSize: 25 }} />,
        onClick: () => {
          this.setState({ archiveChatModal: true })
        },
        content: <Trans i18nKey='archiveChat' />,
      })
    }
    if (!hideReportFlag) {
      dropdownItems.push({
        icon: <HiFlag style={{ color: '#838383', fontSize: 25 }} />,
        onClick: openReport,
        content: <Trans i18nKey='reportUser' />,
      })
    }
    if (can('ADMIN:UNBLOCKABLE')) {
      dropdownItems.push({
        icon: <HiClipboardList style={{ color: '#838383', fontSize: 25 }} />,
        onClick: () => {
          history.push(`${routePrefix}/admin/users/${convo?.userInfo[0]?.id}`)
        },
        content: <Trans i18nKey='adminPanel' />,
      })
    }
    return dropdownItems
  }

  removeAlert = () => this.setState({ currAlert: '' })

  render = () => {
    const { game, routePrefix } = this.context
    const { convo, openReport, collapse, currUser, history } = this.props
    const { btnDisabled, currAlert, groupMembersOpen, archiveChatModal } =
      this.state

    if (Array.isArray(convo.userInfo)) {
      convo.userInfo = convo.userInfo.filter((user) => user.id !== currUser.id)
      convo.userInfo.map(
        (user) =>
          (user.user_role = user ? getRole(user.roles, game.schema) : {})
      )
    }
    let hideReportFlag = can('CHAT:HIDE_REPORT_FLAG', convo.userInfo)
    let canArchiveChat = convo.active === false && convo.requestToReopenUser

    const avatarPromo =
      !currUser?.profile_img?.includes('galacticgetaway') &&
      convo.userInfo[0]?.profile_img?.includes('galacticgetaway')

    return (
      <>
        <div className='chat-info'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledBack onClick={collapse} />
            {convo.userInfo?.length === 0 ? (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <HiUserCircle size={'1.5em'} style={{ marginRight: '8px' }} />
                <b>[DELETED USER]</b>
              </span>
            ) : convo.userInfo.length > 1 ? (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => this.setState({ groupMembersOpen: true })}
              >
                {convo.userInfo[0] && convo.userInfo[0].profile_img ? (
                  <img
                    src={convo.userInfo[0].profile_img}
                    alt='chat-pfp'
                    className='user-profile-img'
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <HiUserCircle className='user-profile-img' size={'1.5em'} />
                )}
                <div style={{ position: 'relative' }}>
                  <Counter>{`+${convo.userInfo.length - 1}`}</Counter>
                </div>
              </div>
            ) : (
              convo.userInfo[0] && (
                <>
                  <Pfp user={convo.userInfo[0]} size={50} chat={true} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Username
                      link
                      to={`${routePrefix}/profile/${convo.userInfo[0].id}/listings`}
                      user={convo.userInfo[0]}
                      styles={{ username: { marginTop: 2, fontSize: 18 } }}
                    />
                    <Contacts
                      game={game}
                      user={convo.userInfo[0]}
                      style={{
                        marginLeft: 2,
                        textDecoration: 'underline',
                      }}
                      offer
                      roblox
                      chat
                    />
                  </div>
                </>
              )
            )}
          </div>
          {avatarPromo && (
            <StyledLink
              style={{ marginLeft: '5px' }}
              className='btn avatar-profile-btn avatar-profile-desktop'
              to={`${routePrefix}/avatar`}
            >
              Get Your Own Avatar
            </StyledLink>
          )}
          {convo.listing_name && (
            <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
              {convo.listing_name}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
            }}
          >
            {/* {!can('MUTE:REQUEST_CHAT') && this.reopenButton()} */}
            {isMobile ? (
              <Dropdown
                items={this.getDropdownItems(
                  convo,
                  canArchiveChat,
                  hideReportFlag
                )}
              />
            ) : (
              <div style={{ display: 'flex', zIndex: 1 }}>
                {(!convo.mod_id || can('ADMIN:CHAT')) &&
                  (convo.active || canArchiveChat) && (
                    <Tooltip
                      text={<Trans i18nKey='archiveChat' />}
                      top='100%'
                      left='0'
                      style={{
                        background: 'rgb(0, 0, 0, 0.7)',
                      }}
                    >
                      <button
                        className='btn-icon'
                        onClick={() =>
                          this.setState({ archiveChatModal: true })
                        }
                        aria-label='Archive Chat'
                      >
                        <HiArchive style={{ color: '#838383', fontSize: 25 }} />
                      </button>
                    </Tooltip>
                  )}
                {!hideReportFlag && (
                  <Tooltip
                    text={<Trans i18nKey='reportUser' />}
                    top='100%'
                    left='0'
                    style={{ background: 'rgb(0, 0, 0, 0.7)' }}
                  >
                    <button
                      className='btn-icon'
                      onClick={openReport}
                      aria-label='Report User'
                    >
                      <HiFlag style={{ color: '#838383', fontSize: 25 }} />
                    </button>
                  </Tooltip>
                )}
                {can('ADMIN:UNBLOCKABLE') && (
                  <Tooltip
                    text={<Trans i18nKey='adminPanel' />}
                    top='100%'
                    left='0'
                    style={{ background: 'rgb(0, 0, 0, 0.7)' }}
                  >
                    <StyledAdminPanelLink
                      to={`${routePrefix}/admin/users/${convo?.userInfo[0]?.id}`}
                      className={
                        convo.userInfo?.length === 0
                          ? 'disabled-link'
                          : undefined
                      }
                      style={{ display: 'flex', padding: '6px' }}
                      aria-label='Admin Panel'
                    >
                      <HiClipboardList
                        style={{ color: '#838383', fontSize: 25 }}
                      />
                    </StyledAdminPanelLink>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </div>
        <Modal
          open={groupMembersOpen}
          onCancel={() => {
            this.setState({ groupMembersOpen: false })
          }}
          title={<Trans i18nKey='chatMembers' />}
          style={{ width: '600px', overflowY: 'auto' }}
          body={
            <>
              {convo.userInfo.map((user) => {
                return (
                  <div key={user.id} style={{ marginBottom: '10px' }}>
                    {user && (
                      <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Pfp user={user} />
                          <StyledLink
                            to={`${routePrefix}/profile/${user.id}/listings`}
                            aria-label={user.username}
                            style={{
                              color: user.user_role ? user.user_role.color : '',
                              marginTop: 10,
                            }}
                          >
                            <b>{user.username}</b>
                          </StyledLink>
                          <Button
                            onClick={() => {
                              requestDM({
                                id: `${user.id}${currUser.id}`,
                                toUsername: user.username,
                              }).then((res) => {
                                if (res.error) {
                                  return this.setState({ currAlert: res.error })
                                } else {
                                  this.setState({ groupMembersOpen: false })
                                  history.push(`${routePrefix}/chat/${user.id}`)
                                  history.go(0) // refresh chat page
                                }
                              })
                            }}
                            style={{ marginLeft: 'auto' }}
                          >
                            <Trans i18nKey='message' />
                          </Button>
                        </div>
                      </>
                    )}
                    {user.roblox_id &&
                      user.roblox_username &&
                      game.has('USERS:ROBLOX') && (
                        <StyledHyperLink
                          href={`https://www.roblox.com/users/${user.roblox_id}/profile`}
                          style={{
                            marginLeft: 5,
                            marginTop: 2,
                            textDecoration: 'underline',
                          }}
                          target='blank'
                          aria-label={user.roblox_username}
                        >
                          {user.roblox_username}
                        </StyledHyperLink>
                      )}
                  </div>
                )
              })}
            </>
          }
        />
        <Modal
          open={archiveChatModal}
          onConfirm={this.updateConvo}
          onCancel={() => {
            this.setState({ archiveChatModal: false })
          }}
          title={<Trans i18nKey='archiveChat' />}
          btnClass='btn-icon'
          disabled={btnDisabled}
          label={<Trans i18nKey='yes' />}
          body={
            <div>
              <div>{<Trans i18nKey='archiveChatBody' />}</div>
            </div>
          }
          style={{
            minWidth: '50%',
          }}
          keyDownEnable
        />
        {currAlert !== '' && (
          <Alert onClick={this.removeAlert} alert={currAlert} />
        )}
      </>
    )
  }
}

export default ChatInfo
