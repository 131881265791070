import React, { useRef } from 'react'
import { withRouter } from 'react-router'
import { matchPath } from 'react-router-dom'
// import { slide as Menu } from 'react-burger-menu'
import { Trans, useTranslation } from 'react-i18next'
import { FaDiscord } from 'react-icons/fa'
import { MdVerified, MdClear } from 'react-icons/md'
import {
  HiArchive,
  HiBadgeCheck,
  HiIdentification,
  HiPencilAlt,
  HiSearch,
  HiSpeakerphone,
  HiShoppingCart,
  HiUser,
} from 'react-icons/hi'
import styled from 'styled-components'
import qs from 'qs'
import { components } from 'react-select'
import { useClickAway } from 'react-use'
import { DateTime } from 'luxon'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'
import { TraderieLong } from '../../games/icons'
import i18n from '../../i18n'
import { getUser } from '../../services/users'
import can from '../../services/rbac'
import { trackQuest } from '../../services/quests'
import { hasFinishedTutorial } from '../../services/tutorial'

import BannerSlider from '../BannerSlider'
import Help from '../Help'
import {
  StyledButtonLink,
  StyledLink,
  StyledSearch,
  StyledSearchBtnContainer,
  StyledSearchSelect,
  StyledThemeNav,
  StyledThemeNavExternal,
  ThemeDiv,
} from '../Styled'
import Dropdown from './Dropdown'
import GameDropdown from './GameDropdown'
import NotificationView from '../Notifications'
import Pfp from '../User/Pfp'
import { tryParseJSON } from '../../games/controller'
import NavDropdown from './NavDropdown'
import GoBack from '../GoBack'
import Tutorial from '../Tutorial'

import './style.css'

const StyledNavBar = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.navBg || theme.body};
  border-bottom: ${({ theme }) => theme.navBorderBottom};
  box-shadow: ${({ theme }) => theme.navBoxShadow};
`

const StyledNavTop = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-top: 15px;

  @media only screen and (max-width: 600px) {
    justify-content: unset;
    background: ${({ theme }) => theme.bodyAlt};
    padding: 6px;
    position: fixed;
    width: ${({ isApp }) => (isApp ? '90%' : '100%')};
    left: ${({ isApp }) => (isApp ? '5%' : '')};
    top: 0;
    z-index: 9999;
    height: 50px;
    max-height: 50px;
  }
`

const StyledNavLink = styled(StyledButtonLink)`
  padding: 5px 10px !important;
  margin-right: ${({ create }) => create && '10px'};

  @media only screen and (max-width: 600px) {
    display: ${({ create }) => create && 'none'};
  }
`

const StyledLoginLink = styled(StyledLink)`
  &:hover {
    color: ${({ theme }) => theme.navLogin};
  }
`

const StyledTypeBar = styled.div`
  overflow: hidden;
  border-bottom: ${({ theme }) => theme.typeBorder};
  padding: ${({ theme }) => theme.navTypePadding};

  .nav-bottom {
    justify-content: ${({ theme, items }) =>
      theme.navBottomJustify || (items < 5 ? 'unset' : 'space-between')};
  }
`

const StyledCloseBtnContainer = styled(ThemeDiv)`
  padding: ${({ theme }) => theme.searchBtnContainerPadding || '4px 5px 4px 0'};
  line-height: 0;
  color: ${({ theme }) => theme.color};

  @media only screen and (max-width: 600px) {
    background: ${({ theme, isApp }) => (isApp ? theme.bodyAlt : theme.body)};
  }
`

const StyledBannerSlider = styled.div`
  display: flex;
  justify-content: center;
`

const StyledNavIcons = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex: ${(props) => props.inGame && '1'};
  }
`

const StyledNavProfileIcon = styled.div`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`

export const webAppPushToApp = (route) => {
  if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage)
    window.ReactNativeWebView.postMessage(route)
}

const searchOptions = [
  {
    label: (
      <>
        <HiArchive style={{ verticalAlign: 'middle' }} />
        &nbsp;
        <span>
          <Trans i18nKey='items' />
        </span>
      </>
    ),
    value: 'item',
  },
  {
    label: (
      <>
        <HiUser style={{ verticalAlign: 'middle' }} />
        &nbsp;
        <span>
          <Trans i18nKey='user' />
        </span>
      </>
    ),
    value: 'username',
  },
  {
    label: (
      <>
        <FaDiscord style={{ verticalAlign: 'middle' }} />
        &nbsp;
        <span>Discord</span>
      </>
    ),
    value: 'discord',
  },
]

const searchIcons = {
  item: <HiArchive style={{ verticalAlign: 'middle' }} />,
  username: <HiUser style={{ verticalAlign: 'middle' }} />,
  discord: <FaDiscord style={{ verticalAlign: 'middle' }} />,
}

const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>
    <span className='search-select-value search-select-value-desktop'>
      {data.label}
    </span>
    <span className='search-select-value search-select-value-mobile'>
      {searchIcons[data.value]}
    </span>
  </components.SingleValue>
)

const isApp = window.localStorage.getItem('app')
const isIOS = window.localStorage.getItem('platform')
const isMobile = window.innerWidth <= 600
const isTablet = window.innerWidth < 1024

const SearchBar = ({
  game,
  history,
  onChange,
  onChangeType,
  onClear,
  onFocusChange,
  search,
  searching,
  searchFocused,
  setSearchFocused,
  type,
  value,
}) => {
  const { t } = useTranslation()
  const searchFocusRef = useRef(null)

  useClickAway(searchFocusRef, () => {
    if (searchFocusRef.current && searchFocused && setSearchFocused)
      setSearchFocused(false)
  })

  const hasTypeSearch =
    !game.has('NAVBAR:NO_SEARCH_TYPE') && game.name !== 'Traderie'

  return (
    <form className='search' onSubmit={search} ref={searchFocusRef}>
      {hasTypeSearch && searchFocused && (
        <ThemeDiv
          style={{
            padding: '5px 5px',
            borderTopLeftRadius: 30,
            borderBottomLeftRadius: 30,
          }}
        >
          <StyledSearchSelect
            classNamePrefix={'Select'}
            key={window.localStorage.getItem('defaultLanguage')}
            value={type}
            components={{ SingleValue }}
            options={
              getUser()
                ? searchOptions
                : [{ label: <Trans i18nKey='items' />, value: 'item' }]
            }
            onChange={onChangeType}
            className='search-type-input'
            isSearchable={false}
            styles={{
              dropdownIndicator: (provided) => ({
                ...provided,
                color: 'white',
                paddingLeft: 0,
              }),
              indicatorSeparator: () => ({ display: 'none' }),
              menu: (provided, state) => ({ ...provided, width: 120 }),
              valueContainer: (provided) => ({
                ...provided,
                justifyContent: 'center',
              }),
              control: (base) => ({
                ...base,
                border: 0,
                boxShadow: 'none',
              }),
            }}
          />
        </ThemeDiv>
      )}
      <StyledSearch
        mobileHome={game.name === 'Traderie'}
        value={value}
        onChange={onChange}
        placeholder={
          game.name === 'Traderie'
            ? t('search') + ' ' + t('games')
            : hasTypeSearch
            ? searchFocused
              ? t('search') + ' ' + t(type.value) || type.value
              : t('search') + '...'
            : t('search') + '...'
        }
        className='search-input'
        style={{
          borderTopLeftRadius: hasTypeSearch && searchFocused ? '' : 30,
          borderBottomLeftRadius: hasTypeSearch && searchFocused ? '' : 30,
          paddingLeft: hasTypeSearch ? '' : 20,
        }}
        onFocus={onFocusChange}
      />
      {search !== '' && searching && game.name === 'Traderie' && (
        <StyledCloseBtnContainer isApp={isApp}>
          <button
            type='submit'
            value='Clear'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'transparent',
              height: 40,
              width: 40,
              padding: 0,
              minWidth: 0,
            }}
            aria-label='Clear Search'
          >
            <MdClear
              style={{ fontSize: 16 }}
              onClick={() => {
                onClear()
                history.push({ search: '' })
              }}
            />
          </button>
        </StyledCloseBtnContainer>
      )}
      <StyledSearchBtnContainer mobileHome={game.name === 'Traderie'}>
        <button
          type='submit'
          value='Submit'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 40,
            width: 40,
            padding: 0,
            minWidth: 0,
            borderRadius: '50%',
          }}
          aria-label='Submit Search'
        >
          <HiSearch style={{ fontSize: 25 }} />
        </button>
      </StyledSearchBtnContainer>
    </form>
  )
}

const TraderieLogo = ({ onClick, className }) => {
  const isOverwolf = localStorage.getItem('overwolf')
  let reroute = '/'
  if (isOverwolf) reroute = '/diablo4'
  return (
    <StyledLink
      to={reroute}
      style={{ marginRight: 10 }}
      onClick={onClick}
      className={className}
      aria-label='traderie'
    >
      <TraderieLong
        style={{
          verticalAlign: 'middle',
          fontSize: 25,
          marginLeft: isMobile ? '10px' : 0,
        }}
        alt='traderie'
      />
    </StyledLink>
  )
}

const offerTutorialSteps = [
  {
    target: '.home-section',
    content: "Welcome! Let's get started on your first offer!",
    disableBeacon: true,
    placement: 'center',
    spotlightClicks: true,
  },
  {
    target: isMobile ? '#mobilenav' : '#navbar',
    content: 'Click here and search for the name of an item you want!',
    disableBeacon: true,
    spotlightClicks: true,
    placement: 'bottom',
  },
]

const listingTutorialSteps = [
  {
    target: '.home-section',
    content: "Let's get started on your first listing!",
    disableBeacon: true,
    placement: 'center',
    spotlightClicks: true,
  },
  {
    target: isMobile ? '#mobilenav' : '#navbar',
    content: 'Click here and search for the name of an item you want to sell!',
    disableBeacon: true,
    spotlightClicks: true,
    placement: 'bottom',
  },
]

class Navbar extends React.Component {
  state = {
    searchType: searchOptions[0],
    search: '',
    helpOpen: false,
    searchFocused: false,
    searching: false,
    isTourRunning:
      !hasFinishedTutorial(localStorage.getItem('currentTutorial')) &&
      !localStorage.getItem('tutorialSkipped'),
  }
  static contextType = GameContext

  componentDidMount = () => {
    let { location } = this.props
    const query = qs.parse(location.search.substring(1))
    if (query.search && query.search !== '')
      this.setState({ search: query.search, searching: true })
    if (isApp) {
      if (isIOS === 'true') {
        window.addEventListener('message', (event) => {
          this.appHistoryPush(event)
        })
      } else {
        document.addEventListener('message', (event) => {
          this.appHistoryPush(event)
        })
      }
    }
  }

  appHistoryPush = (event) => {
    let { history } = this.props
    let { routePrefix } = this.context
    let { data } = event
    const routeEvent = tryParseJSON(data)
    if (routeEvent.route) {
      history.push(`${routePrefix}/${routeEvent.route}`)
    } else if (routeEvent.theme) {
      const currentTheme = window.localStorage.getItem('theme')
      if (currentTheme !== routeEvent.theme) this.props.toggleTheme()
    } else if (routeEvent.newLang) {
      window.localStorage.setItem('defaultLanguage', routeEvent.newLang)
      i18n.changeLanguage(routeEvent.newLang)
      history.push({ search: '' })
      window.location.reload()
    } else if (routeEvent.help) {
      this.setState({ helpOpen: true })
    }
  }

  search = (e) => {
    e.preventDefault()
    const { game, routePrefix } = this.context
    const { history } = this.props
    const { searchType, search } = this.state

    if (game.name === 'Traderie') {
      this.setState({ searching: true })
      return history.push({ pathname: '/', search: `?search=${search}` })
    }

    const user = getUser()
    if (search.length < 2) return
    switch (searchType.value) {
      case 'username':
        history.push(`${routePrefix}/users?username=${search}`)
        window.dataLayer.push({
          event: 'navbarSearch',
          eventProps: {
            category: 'Navbar Search',
            action: 'Username Search',
          },
          userId: user ? user.id : undefined,
          email: user ? user.email : undefined,
        })
        break
      case 'discord':
        history.push(`${routePrefix}/users?discord=${search}`)
        window.dataLayer.push({
          event: 'navbarSearch',
          eventProps: {
            category: 'Navbar Search',
            action: 'Discord Search',
          },
          userId: user ? user.id : undefined,
          email: user ? user.email : undefined,
        })
        break
      default:
        history.push(`${routePrefix}/products?search=${search}`)
        window.dataLayer.push({
          event: 'navbarSearch',
          eventProps: {
            category: 'Navbar Search',
            action: 'Item Search',
          },
          userId: user ? user.id : undefined,
          email: user ? user.email : undefined,
        })
    }
  }

  getDropDownItems = (user) => {
    const { history } = this.props
    const { routePrefix } = this.context

    let items = [
      {
        icon: <HiSpeakerphone size={'1.5em'} />,
        content: <Trans i18nKey='updates' />,
        onClick: () => {
          history.push(`${routePrefix}/updates`)
        },
      },
      {
        icon: <HiShoppingCart size={'1.5em'} />,
        content: <Trans i18nKey='pointsShop' />,
        onClick: () => {
          history.push(`${routePrefix}/shop`)
        },
      },
      {
        icon: <HiBadgeCheck size={'1.5em'} />,
        content: <Trans i18nKey='akrewPro' />,
        onClick: () => {
          if (isApp && isIOS !== 'true') {
            webAppPushToApp('akrewpro')
          } else {
            history.push('/akrewpro')
          }
        },
      },
    ]

    if (user) {
      if (can('ADMIN:PANEL'))
        items.push({
          icon: <HiIdentification size={'1.5em'} />,
          content: 'Admin',
          onClick: () => {
            history.push(`${routePrefix}/admin`)
          },
        })
    }

    return items
  }

  logoClick = () => {
    this.setState({
      searchType: searchOptions[0],
      search: '',
    })
  }

  setSearchFocused = (value) => {
    this.setState({ searchFocused: value })
  }

  render = () => {
    const { game, routePrefix } = this.context
    const { Logo, ChatIcon } = game.icons

    const { location, mobileChat, history } = this.props
    const query = qs.parse(location.search.substring(1))
    if (
      query.hasOwnProperty('ref') &&
      typeof query['ref'] === 'string' &&
      query['ref'].length
    ) {
      localStorage.setItem('fromRef', query.ref)
    }
    const isAppRoute =
      isApp || mobileChat
        ? matchPath(location.pathname, {
            path: [
              `${routePrefix}`,
              `${routePrefix}/latest`,
              `${routePrefix}/hot`,
              `${routePrefix}/products/:type?/:category?`,
              `${routePrefix}/listings/:selling?`,
              `${routePrefix}/product/:id`,
              `${routePrefix}/listing/:id`,
            ],
            exact: true,
          })
        : true

    const {
      search,
      searching,
      searchType,
      helpOpen,
      searchFocused,
      isTourRunning,
    } = this.state
    const user = getUser()
    const currPage = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    )
    const homePage = currPage === game.schema.replace('_', '')
    const inGame = game.name !== 'Traderie'
    const hideTabs = location.pathname.split('/').includes('shop')
    const inLoginSignup = currPage === 'signup' || currPage === 'login'
    const bannerSlider = localStorage.getItem('bannerSlider')
    const showBannerSlider =
      !location.pathname.includes('chat') &&
      !location.pathname.includes('notification') &&
      (homePage ||
        !bannerSlider ||
        DateTime.now().diff(DateTime.fromISO(bannerSlider)).as('hours') > 24)
    const isOverwolf = localStorage.getItem('overwolf')
    const tutorialSteps =
      localStorage.getItem('currentTutorial') === 'Offer'
        ? offerTutorialSteps
        : listingTutorialSteps

    return (
      <div id='nav-container'>
        <StyledNavBar>
          <div className='container'>
            {!isApp && (
              <StyledNavTop id='navbar'>
                {isOverwolf && <GoBack history={history} />}
                {game.site === 'Traderie' && (
                  <TraderieLogo
                    onClick={this.logoClick}
                    className='nav-desktop'
                  />
                )}
                <StyledNavIcons inGame={inGame}>
                  {inGame && Logo && (
                    <StyledLink
                      to={`${routePrefix}`}
                      style={{
                        marginRight: game.site === 'Nookazon' && 10,
                      }}
                      onClick={this.logoClick}
                      id='game_home'
                      aria-label='Home'
                    >
                      {/* TODO: Use logoWidth from theme */}
                      {game.site === 'Nookazon' ? (
                        <Logo />
                      ) : (
                        <Logo
                          style={{
                            width: 80,
                            maxHeight: isMobile ? 50 : undefined,
                            verticalAlign: 'middle',
                          }}
                        />
                      )}
                      {game.ui?.verified && (
                        <span className='game-verified'>
                          <MdVerified />
                        </span>
                      )}
                    </StyledLink>
                  )}
                  {inGame && game.site !== 'Nookazon' && !isOverwolf && (
                    <div className='nav-dropdown'>
                      <GameDropdown currentGame={game} user={user} />
                    </div>
                  )}
                </StyledNavIcons>
                {game.site === 'Traderie' && (
                  <TraderieLogo
                    onClick={this.logoClick}
                    className='nav-mobile'
                  />
                )}
                {inGame && !searchFocused && (
                  <NavDropdown
                    user={user}
                    toggleHelp={() => this.setState({ helpOpen: true })}
                  />
                )}
                {(inLoginSignup && game.site !== 'Nookazon') ||
                (inGame && isMobile) ? (
                  <></>
                ) : (
                  <div className='desktop-search'>
                    <SearchBar
                      game={game}
                      history={history}
                      onChange={(e) =>
                        this.setState({ search: e.target.value })
                      }
                      onChangeType={(value) =>
                        this.setState({ searchType: value })
                      }
                      onClear={() =>
                        this.setState({ search: '', searching: false })
                      }
                      search={this.search}
                      searching={searching}
                      searchFocused={searchFocused}
                      setSearchFocused={this.setSearchFocused}
                      type={searchType}
                      value={search}
                      onFocusChange={() => {
                        if (!searchFocused)
                          this.setState({ searchFocused: !searchFocused })
                      }}
                    />
                  </div>
                )}
                {
                  <div className='nav-right'>
                    {inGame && !game.has('TRADING:DISABLED') && (
                      <StyledNavLink
                        to={`${routePrefix}/listings/create`}
                        aria-label='Create Listing'
                        create
                      >
                        <HiPencilAlt size='1.5em' />
                        {game.schema === 'bloxfruits' ? (
                          <Trans i18nKey='createTrade' />
                        ) : (
                          <Trans i18nKey='addListing' />
                        )}
                      </StyledNavLink>
                    )}
                    {!user && (
                      <div className='nav-login-btns nav-desktop'>
                        <StyledLoginLink
                          to={'/login'}
                          onClick={() => {
                            if (routePrefix)
                              localStorage.setItem('loginReroute', routePrefix)
                          }}
                          style={{ marginRight: 10 }}
                          aria-label='Log In'
                        >
                          <Trans i18nKey='logIn' />
                        </StyledLoginLink>
                        <StyledNavLink
                          to={'/signup'}
                          onClick={() => {
                            if (routePrefix)
                              localStorage.setItem('loginReroute', routePrefix)
                          }}
                          aria-label='Sign Up'
                        >
                          <Trans i18nKey='signUp' />
                        </StyledNavLink>
                      </div>
                    )}
                    {inGame && user && (
                      <div className='nav-icons nav-desktop'>
                        <StyledNavProfileIcon id='navbar-profile-link'>
                          <UserContext.Consumer>
                            {({ user: loggedUser }) => (
                              <Pfp user={loggedUser} size={30} noBadge />
                            )}
                          </UserContext.Consumer>
                        </StyledNavProfileIcon>
                        <div style={{ marginRight: 5 }}>
                          <NotificationView />
                        </div>
                        <StyledLink
                          to={`${routePrefix}/chat`}
                          className='profile-link'
                          aria-label='Chats'
                        >
                          <ChatIcon />
                        </StyledLink>
                      </div>
                    )}
                    {!inLoginSignup &&
                      inGame &&
                      !game.has('TRADING:DISABLED') && (
                        <StyledLink
                          to={`${routePrefix}/listings/create`}
                          className='nav-mobile'
                          aria-label='Create Listing'
                        >
                          <HiPencilAlt size='1.5em' />
                        </StyledLink>
                      )}
                    {!inLoginSignup &&
                      inGame &&
                      game.has('COMPS:ENABLED') &&
                      game.has('TRADING:DISABLED') &&
                      user && (
                        <StyledLink
                          to={`${routePrefix}/${game.getText('comp')}s/create`}
                          className='nav-mobile'
                          aria-label={`Create ${game.getText('comp')}`}
                        >
                          <HiPencilAlt size='1.5em' />
                        </StyledLink>
                      )}
                    {!inLoginSignup && inGame && (
                      <div className='nav-dropdown'>
                        <Dropdown
                          items={this.getDropDownItems(user)}
                          isMultiLevel={true}
                          hasLang={game.has('USERS:LANGUAGES')}
                          toggleTheme={this.props.toggleTheme}
                          isMobile={isMobile}
                          isTablet={isTablet}
                        />
                      </div>
                    )}
                    {!inLoginSignup && !inGame && (
                      <span className='profile-link-text'>
                        {user && <div>{user.username}</div>}
                      </span>
                    )}
                  </div>
                }
              </StyledNavTop>
            )}
            {isApp && game.name === 'Traderie' && (
              <StyledNavTop
                style={{ marginTop: '6px', borderRadius: '30px' }}
                isApp={isApp}
              >
                <div className='app-search'>
                  <SearchBar
                    game={game}
                    history={history}
                    onChange={(e) => this.setState({ search: e.target.value })}
                    onChangeType={(value) =>
                      this.setState({ searchType: value })
                    }
                    onClear={() =>
                      this.setState({ search: '', searching: false })
                    }
                    search={this.search}
                    searching={searching}
                    searchFocused={searchFocused}
                    setSearchFocused={this.setSearchFocused}
                    type={searchType}
                    value={search}
                    onFocusChange={() => {
                      if (!searchFocused)
                        this.setState({ searchFocused: !searchFocused })
                    }}
                  />
                </div>
              </StyledNavTop>
            )}
            <div className='nav-container'>
              {inGame && ((isApp && isAppRoute) || (!isApp && !mobileChat)) && (
                <div
                  className='nav-mobile'
                  style={{
                    flex: 1,
                    marginTop: 15,
                    marginBottom: 15,
                    display: isApp && isAppRoute ? 'flex' : '',
                  }}
                  id='mobilenav'
                >
                  <SearchBar
                    game={game}
                    onChange={(e) => this.setState({ search: e.target.value })}
                    onChangeType={(value) =>
                      this.setState({ searchType: value })
                    }
                    search={this.search}
                    searchFocused
                    type={searchType}
                    value={search}
                  />
                  {user && isApp && !game.has('TRADING:DISABLED') && (
                    <div style={{ marginLeft: 10 }}>
                      <StyledButtonLink
                        to={`${routePrefix}/listings/create`}
                        aria-label='Create Listing'
                      >
                        <HiPencilAlt size='1.5em' />
                      </StyledButtonLink>
                    </div>
                  )}
                  {user &&
                    isApp &&
                    game.has('COMPS:ENABLED') &&
                    game.has('TRADING:DISABLED') && (
                      <div style={{ marginLeft: 10 }}>
                        <StyledButtonLink
                          to={`${routePrefix}/${game.getText('comp')}s/create`}
                          aria-label={`Create ${game.getText('comp')}`}
                        >
                          <HiPencilAlt size='1.5em' />
                        </StyledButtonLink>
                      </div>
                    )}
                </div>
              )}
              {inGame && isAppRoute && !hideTabs && (
                <StyledTypeBar items={game.types.length}>
                  <div className='nav-bottom'>
                    {/* Home */}
                    {game.types.find((t) => t.link && t.link === '/') && (
                      <StyledThemeNav
                        key={'home-nav'}
                        to={`${routePrefix}/${
                          game.types.find((t) => t.link && t.link === '/').link
                        }`}
                        className='has-tooltip'
                      >
                        <Trans
                          i18nKey={
                            game.types.find((t) => t.link && t.link === '/')
                              .label
                          }
                        />
                      </StyledThemeNav>
                    )}
                    {/* All */}
                    {!game.types.find(
                      (t) => t.link && t.link === 'products'
                    ) && (
                      <StyledThemeNav
                        to={`${routePrefix}/products`}
                        className='has-tooltip'
                        exact
                      >
                        <Trans i18nKey='all' />
                      </StyledThemeNav>
                    )}
                    {/* Categories */}
                    {[...game.types]
                      .filter((t) => t?.link !== '/')
                      .map((type, i) => {
                        let label = type.label
                        if (type.label === 'diy') label = 'diyRecipes'
                        return type.href ? (
                          <StyledThemeNavExternal
                            key={i}
                            href={type.href}
                            className='has-tooltip'
                          >
                            <Trans i18nKey={label} />
                          </StyledThemeNavExternal>
                        ) : (
                          <StyledThemeNav
                            key={i}
                            to={
                              type.link
                                ? `${routePrefix}/${type.link}`
                                : `${routePrefix}/products/${type.label}`
                            }
                            className='has-tooltip'
                            onClick={() => {
                              if (user && !type.link)
                                trackQuest('categoriesVisited')
                            }}
                          >
                            <Trans i18nKey={label} />
                          </StyledThemeNav>
                        )
                      })}
                  </div>
                </StyledTypeBar>
              )}
              {helpOpen && (
                <Help
                  open={true}
                  onClose={() => this.setState({ helpOpen: false })}
                />
              )}
            </div>
          </div>
        </StyledNavBar>
        <StyledBannerSlider>
          <div className='container'>
            {inGame && showBannerSlider && <BannerSlider homePage={homePage} />}
          </div>
        </StyledBannerSlider>
        {inGame && homePage && (
          <Tutorial steps={tutorialSteps} run={isTourRunning} />
        )}
      </div>
    )
  }
}

export default withRouter(Navbar)
