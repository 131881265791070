import React from 'react'
import styled from 'styled-components'

import { getValueColor } from './ListingProperties'

const ListingPropertySpan = styled.span<any>`
  white-space: ${({ type }) => type === 'array' ? '' : 'nowrap'};
  color: ${({ highlight, theme }) => highlight ? theme.btnBackground : ''};
`

const ListingProperty = ({ property, value, highlight }) => {
  return (
    <ListingPropertySpan type={property.type} highlight={highlight}>
      &nbsp;
      {property.format?.values && property.format?.values[value]?.img ? (
        <img
          src={property.format.values[value].img}
          alt={property.property}
          className='bells-icon'
          style={{ verticalAlign: 'middle' }}
        />
      ) : null}
      {property.format && property.format.show_label ? (
        <span
          style={{
            color: property.format ? property.format.label_color || '' : null,
          }}
        >
          {`${property.property}: `}
        </span>
      ) : (
        ''
      )}
      <span style={{ color: getValueColor(property, value) }}>{value}</span>
    </ListingPropertySpan>
  )
}

export default ListingProperty
