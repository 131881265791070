// This component is for display properties below the square Item component

import React, { useContext } from 'react'
import GameContext from '../../GameContext'

const ItemProps = ({ item }) => {
  const { properties } = item
  const { game } = useContext(GameContext)
  if (!properties) return null

  const getValue = (prop) => {
    if (prop.format?.imgs) {
      return (
        <img
          src={'https://cdn.nookazon.com/' + game.schema + '/properties/' + prop.string.replace(' ', '_') + '.png'}
          className='item-prop-img'
        />
      )
    } else if (prop.format?.input === 'color') {
      return (
        <>
          <span
            className='item-prop-color'
            style={{ backgroundColor: prop.string }}
          ></span>
          {prop.string}
        </>
      )
    }
    return null
  }

  return (
    <div>
      {properties.map((prop) => {
        return (
          <span className='item-prop'>
            {prop.name}: {getValue(prop)}
          </span>
        )
      })}
    </div>
  )
}

export default ItemProps
