import React, { useState, useContext, useEffect } from 'react'
import { FaDiscord, FaApple, FaGoogle, FaFacebook } from 'react-icons/fa'
import { Formik, Form, Field } from 'formik'
import { GoogleLogin } from 'react-google-login'
import { Trans } from 'react-i18next'
import isEmail from 'validator/lib/isEmail'
import scriptjs from 'scriptjs'
import { DateTime } from 'luxon'
import { Turnstile } from '@marsidev/react-turnstile'

import GameContext from '../../GameContext'
import http from '../../services/http'
import {
  TURNSTILE_MANAGED_KEY,
  getRedirect,
  setRedirect,
} from '../../services/login'
import { setDetails } from '../../services/details'
import { setJWT, validateUsername, getUser } from '../../services/users'
import { getSignUpGame } from '../../games/controller'

import Alert from '../../components/Alert'
import Helmet from '../../components/Helmet'
import BattleNetLogin from '../../components/Login/BattleNetLogin'
import RobloxLogin from '../../components/Login/RobloxLogin'
import Modal from '../../components/Modal'
import { Checkbox } from '../../components/inputs'
import { StyledLink, StyledErrorMessage } from '../../components/Styled'
import { getFrontendQuests } from '../../components/GetDetails'

import { getDiscordUrl, facebookUrl } from './index'

export const verifyPassword = (pw) => {
  return /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}))/.test(pw)
}

export const verifyFriendCode = (friendCode) => {
  return /^\d{4}-\d{4}-\d{4}$/.test(friendCode)
}

export const verifyRobloxUsername = (username) => {
  return username.length > 2 && username.length < 21
}

export const getRobloxId = (robloxLink) => {
  if (!robloxLink) return
  const found = robloxLink.match(
    /^https:\/\/(www|web)\.roblox.com\/users\/(\d+)\/profile\/?$/
  )
  if (!found || (found && found.length > 1 && !found[2])) return
  return found[2]
}

scriptjs.get(
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  () => {
    const params = {
      clientId:
        process.env.REACT_APP_DEPLOY === 'nookazon'
          ? 'org.nookazon.client'
          : 'org.traderie.client',
      redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
      scope: 'email',
    }
    if (window.AppleID) window.AppleID.auth.init(params)
  }
)

const Signup = ({ location, history }) => {
  const { routePrefix, game } = useContext(GameContext)
  const [isRegistered, setRegistered] = useState(false)
  const loginReroute = localStorage.getItem('loginReroute')
  const [signUpGame, setSignUpGame] = useState(game)
  const [currAlert, setAlert] = useState('')

  useEffect(() => {
    if (loginReroute && loginReroute !== '')
      getSignUpGame(loginReroute).then((exGame) => {
        if (exGame) setSignUpGame(exGame)
      })
  }, [loginReroute])

  useEffect(() => {
    if (location.state?.from) setRedirect(location.state.from)
  }, [])

  let captchaRef
  const isApp = localStorage.getItem('app')

  const handleGoogleLogin = async (googleData) => {
    if (googleData.error) {
      if (
        googleData.error !== 'popup_closed_by_user' &&
        googleData.error !== 'idpiframe_initialization_failed'
      )
        setAlert(`Failed to sign up: ${googleData.error}`)
    } else {
      history.push(`/login?google_token=${googleData.tokenId}`)
    }
  }

  const removeAlert = () => setAlert('')
  const isEmailOptional = signUpGame && signUpGame.has('USERS:EMAIL_OPTIONAL')

  return (
    <div className='login'>
      <Helmet />
      <div className='container'>
        <h1>
          <Trans i18nKey='createAccount' />
        </h1>
        {isApp !== 'true' && (
          <>
            <a
              href={getDiscordUrl()}
              className='discord-login oauth-link'
              onClick={() => {
                window.dataLayer.push({
                  event: 'conversionSignUp',
                  eventProps: {
                    category: 'Sign Up',
                    action: `Conversion Sign Up - ${game.getSite()}`,
                  },
                })
              }}
              aria-label='Sign up with Discord'
            >
              <FaDiscord style={{ marginRight: 5 }} />
              <Trans i18nKey='logInDiscord' />
            </a>
            <button
              onClick={() => {
                window.dataLayer.push({
                  event: 'conversionSignUp',
                  eventProps: {
                    category: 'Sign Up',
                    action: `Conversion Sign Up - ${game.getSite()}`,
                  },
                })
                window.AppleID.auth.signIn()
              }}
              className='apple-login'
              aria-label='Sign up with Apple'
            >
              <FaApple style={{ marginRight: 5 }} />
              <Trans i18nKey='logInApple' />
            </button>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  className='google-login'
                  aria-label='Sign up with Google'
                >
                  <FaGoogle style={{ marginRight: 5 }} />{' '}
                  <Trans i18nKey='logInGoogle' />
                </button>
              )}
              onSuccess={handleGoogleLogin}
              onFailure={handleGoogleLogin}
              cookiePolicy={'single_host_origin'}
            />
            <RobloxLogin
              onClick={() => {
                window.dataLayer.push({
                  event: 'conversionSignUp',
                  eventProps: {
                    category: 'Sign Up',
                    action: `Conversion Sign Up - ${game.getSite()}`,
                  },
                })
              }}
            />
            {process.env.REACT_APP_DEPLOY === 'traderie' && (
              <BattleNetLogin
                onClick={() => {
                  window.dataLayer.push({
                    event: 'conversionSignUp',
                    eventProps: {
                      category: 'Sign Up',
                      action: `Conversion Sign Up - ${game.getSite()}`,
                    },
                  })
                }}
              />
            )}
            <a
              href={facebookUrl}
              onClick={() => {
                window.dataLayer.push({
                  event: 'conversionSignUp',
                  eventProps: {
                    category: 'Sign Up',
                    action: `Conversion Sign Up - ${game.getSite()}`,
                  },
                })
              }}
              className='facebook-login oauth-link'
              aria-label='Continue with Facebook'
            >
              <FaFacebook style={{ marginRight: 5 }} />
              <Trans i18nKey='logInFacebook' />
            </a>
            <div className='or'>
              <div className='or-line'></div>
              <span>or</span>
              <div className='or-line'></div>
            </div>
            {game.site !== 'Nookazon' && (
              <div style={{ marginBottom: 15, textAlign: 'center' }}>
                <Trans i18nKey='nookazonAccountLogin' /> Just{' '}
                <StyledLink
                  to={`/login`}
                  style={{ textDecoration: 'underline' }}
                  aria-label='Log In'
                >
                  <Trans i18nKey='logIn' />
                </StyledLink>
                .
              </div>
            )}
          </>
        )}
        <Formik
          initialValues={{
            username: '',
            password: '',
            password2: '',
            villager_name: '',
            island_name: '',
            roblox_username: '',
            preferred_platform: 'epic',
            friend_code: '',
            birthday: '',
            email: '',
            email_marketing: true,
            captcha: '',
            source: localStorage.getItem('fromRef') || null,
          }}
          validate={(values) => {
            const errors = {}
            if (!values.username) errors.username = <Trans i18nKey='required' />
            if (!values.password) errors.password = <Trans i18nKey='required' />
            if (!values.password2)
              errors.password2 = <Trans i18nKey='required' />
            if (values.password2 !== values.password)
              errors.password2 = <Trans i18nKey='reenterPwError' />
            if (!values.birthday) errors.birthday = <Trans i18nKey='required' />
            if (!isEmailOptional && !values.email)
              errors.email = <Trans i18nKey='required' />
            if (values.username && !validateUsername(values.username)) {
              errors.username = <Trans i18nKey='usernameError' />
            }
            if (values.friend_code && !verifyFriendCode(values.friend_code)) {
              errors.friend_code = <Trans i18nKey='friendCodeError' />
            }
            if (signUpGame.hasContact('roblox_username')) {
              if (!values.roblox_username)
                errors.roblox_username = <Trans i18nKey='required' />
              if (
                values.roblox_username &&
                !verifyRobloxUsername(values.roblox_username)
              ) {
                errors.roblox_username = <Trans i18nKey='robloxUsernameError' />
              }
            }
            if (values.password && !verifyPassword(values.password)) {
              errors.password = <Trans i18nKey='badPasswordError' />
            }
            if (
              values.birthday &&
              DateTime.now()
                .diff(DateTime.fromISO(values.birthday))
                .as('years') < 13
            ) {
              errors.birthday = <Trans i18nKey='ageError' />
            }
            if (!isEmailOptional && values.email && !isEmail(values.email)) {
              errors.email = <Trans i18nKey='emailError' />
            }
            if (
              values.email &&
              (values.email.includes('@gmail.com') ||
                values.email.includes('@googlemail.com') ||
                values.email.includes('@outlook.com'))
            ) {
              if (values.email.includes('+')) {
                errors.email = <Trans i18nKey='emailGmailError' />
              }
            }

            if (game.has('USERS:ISLAND_VILLAGER_NAME')) {
              if (!values.villager_name)
                errors.villager_name = <Trans i18nKey='required' />
              if (!values.island_name)
                errors.island_name = <Trans i18nKey='required' />
            }

            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            const currGame = localStorage.getItem('loginReroute')
            values.captchaManaged = true
            values.currGame = currGame
            values.game_schema = signUpGame
              ? signUpGame.schema === 'public' &&
                signUpGame?.site === 'Traderie'
                ? 'traderie'
                : signUpGame.schema
              : null
            http.post(`/register`, values).then(async (res) => {
              setSubmitting(false)
              if (captchaRef?.current) captchaRef.current.reset()
              if (signUpGame.has('USERS:PREFERRED_PLATFORM'))
                localStorage.setItem(
                  `${currGame.slice(1)}_preferred`,
                  values.preferred_platform
                )
              if (res.error) {
                return setAlert(res.error)
              }
              window.dataLayer.push({
                event: 'conversionSignUp',
                eventProps: {
                  category: 'Sign Up',
                  action: `Conversion Sign Up - ${game.getSite()}`,
                },
              })
              if (
                !signUpGame.has('USERS:REQUIRE_ACTIVATION') ||
                process.env.REACT_APP_REQUIRE_ACTIVATION !== 'true'
              ) {
                setJWT(res.jwt)
                setDetails(res.details)
                localStorage.setItem('currentTutorial', 'Offer')
                await getFrontendQuests()
                localStorage.removeItem('fromRef')
                let redirect = getRedirect()
                const signUpUser = getUser()
                if (signUpUser && currGame === '/monopolygo') {
                  redirect = `/monopolygo/profile/${signUpUser.id}/catalog`
                  localStorage.setItem('newsignup', 'monopolygo')
                }
                history.push(
                  `${
                    redirect
                      ? redirect
                      : currGame
                      ? currGame
                      : `${loginReroute || routePrefix}`
                  }`
                )
              } else {
                setRegistered(true)
              }
            })
          }}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form>
              <div className='input-row login-input'>
                <div className='input-label'>
                  <Trans i18nKey='username' />
                </div>
                <Field type='text' name='username' id='username_input' />
                <StyledErrorMessage name='username' component='div' />
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>
                  <Trans i18nKey='password' />
                </div>
                <Field type='password' name='password' id='password_input' />
                <StyledErrorMessage name='password' component='div' />
                {signUpGame.has('USERS:ROBLOX') && (
                  <div className='signup-sublabel'>
                    This is NOT your Roblox password it's a new password just
                    for Traderie
                  </div>
                )}
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>
                  <Trans i18nKey='password2' />
                </div>
                <Field type='password' name='password2' id='password2_input' />
                <StyledErrorMessage name='password2' component='div' />
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>
                  <Trans i18nKey='birthday' />
                </div>
                <Field name='birthday' type='date' id='birthday_input' />
                <StyledErrorMessage name='birthday' component='div' />
                <div className='signup-sublabel'>
                  <Trans i18nKey='birthdayMessage' />
                </div>
              </div>
              {game.has('USERS:ISLAND_VILLAGER_NAME') && (
                <div className='input-row login-input'>
                  <div className='input-label'>
                    <Trans i18nKey='villagerName' />
                  </div>
                  <Field
                    type='input-label'
                    name='villager_name'
                    id='villager_input'
                  />
                  <StyledErrorMessage name='villager_name' component='div' />
                </div>
              )}
              {game.has('USERS:ISLAND_VILLAGER_NAME') && (
                <div className='input-row login-input'>
                  <div className='input-label'>
                    <Trans i18nKey='islandName' />
                  </div>
                  <Field
                    type='input-label'
                    name='island_name'
                    id='island_input'
                  />
                  <StyledErrorMessage name='island_name' component='div' />
                </div>
              )}
              {signUpGame.hasContact('roblox_username') && (
                <div className='input-row login-input'>
                  <div className='input-label'>
                    <Trans i18nKey='robloxUsername' />
                  </div>
                  <Field
                    type='input-label'
                    name='roblox_username'
                    id='roblox_input'
                  />
                  <StyledErrorMessage name='roblox_username' component='div' />
                </div>
              )}
              <div className='input-row login-input'>
                <div className='input-label'>
                  <Trans i18nKey='email' />
                </div>
                <Field type='email' name='email' id='email_input' />
                <StyledErrorMessage name='email' component='div' />
                <div className='signup-sublabel'>
                  <Trans i18nKey='emailMessage' />
                </div>
              </div>
              {game.hasContact('friend_code') && (
                <div className='input-row login-input'>
                  <div className='input-label'>
                    <Trans i18nKey='friendCode' />
                  </div>
                  <Field name='friend_code' placeholder='ex. XXXX-XXXX-XXXX' />
                  <StyledErrorMessage name='friend_code' component='div' />
                </div>
              )}
              {!isEmailOptional && (
                <div className='input-row login-input'>
                  <Checkbox
                    label={<Trans i18nKey='emailOptIn' />}
                    checked={values.email_marketing}
                    onChange={(e) =>
                      setFieldValue('email_marketing', e.target.checked)
                    }
                  />
                  <StyledErrorMessage name='email_marketing' component='div' />
                </div>
              )}
              <Turnstile
                siteKey={TURNSTILE_MANAGED_KEY}
                ref={captchaRef}
                onSuccess={(token) => setFieldValue('captcha', token)}
                onError={() => {
                  if (captchaRef?.current) captchaRef.current.reset()
                }}
                onExpire={() => {
                  if (captchaRef?.current) captchaRef.current.reset()
                }}
              />
              <div style={{ color: 'gray', marginBottom: 10 }}>
                <Trans i18nKey='pressingRegister' />{' '}
                <StyledLink
                  style={{ textDecoration: 'underline' }}
                  to={`${loginReroute || routePrefix}/termsofservice`}
                  aria-label='Terms of Service'
                >
                  <Trans i18nKey='termsofService' />
                </StyledLink>
                {', '}
                <StyledLink
                  style={{ textDecoration: 'underline' }}
                  to={`${loginReroute || routePrefix}/community-guidelines`}
                  aria-label='Community Guidelines'
                >
                  <Trans i18nKey='communityGuidelines' />
                </StyledLink>{' '}
                <Trans i18nKey='aswell' />{' '}
                <StyledLink
                  style={{ textDecoration: 'underline' }}
                  to={`${loginReroute || routePrefix}/privacypolicy`}
                  aria-label='Privacy Policy'
                >
                  <Trans i18nKey='privacyPolicy' />
                </StyledLink>
              </div>
              <div className='login-btn-bar'>
                <button
                  type='submit'
                  disabled={isSubmitting}
                  id='signup_submit_button'
                  aria-label='Register'
                >
                  <Trans i18nKey='register' />
                </button>
              </div>
              <span className='sign-up-link'>
                <p style={{ marginBottom: '10px' }}>
                  <Trans i18nKey='alreadyHaveAccount' />
                  &nbsp;
                  <StyledLink
                    to={`/login`}
                    onClick={() => {
                      window.scrollTo(0, 0)
                    }}
                    style={{ textDecoration: 'underline' }}
                    aria-label='Log In'
                  >
                    <Trans i18nKey='logIn' />!
                  </StyledLink>
                </p>
              </span>
            </Form>
          )}
        </Formik>
        <Modal
          id='signup_verify_modal'
          title={'Verify Email'}
          open={isRegistered}
          btnClass='btn-alt'
          label='Close'
          body={
            <div>
              <Trans i18nKey='verifyEmail' /> {game.site}
              <br />
              <Trans i18nKey='verifyEmailResend' />
            </div>
          }
          onConfirm={() => {
            history.push(loginReroute || routePrefix)
          }}
        ></Modal>
      </div>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default Signup
