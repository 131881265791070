import React from 'react'
import qs from 'qs'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'

import GameContext from '../../GameContext'
import { getUser } from '../../services/users'
import { upperCaseEachWord } from '../../services/validate'
import AdSlot from '../../components/AdSlot'
import Listings from '../../components/Listings/Listings'

import './style.css'

const ListingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

class SeeAll extends React.Component {
  static contextType = GameContext

  render = () => {
    const { game, routePrefix } = this.context
    const { match, history, location } = this.props
    const { url, path } = match
    const { selling } = match.params
    const query = qs.parse(location.search.substring(1))
    const { orderBy } = query
    const splitPath = location.pathname.split('/')
    let header = 'Selling'
    if (game.has('LISTINGS:COMPACT')) {
      header = `${game.getSite()} ${game.getTitle()} Trading`
    } else if (selling === 'buying') {
      header = 'Looking For'
    } else if (selling === 'auctions') {
      header = 'Auctions'
    } else if (selling === 'foryou') {
      if (getUser() === undefined) history.push(`${routePrefix}`)
      header = 'For You'
    }
    const gridCount = 6

    return (
      <div className='see-all'>
        <Helmet>
          <title>
            Browse {upperCaseEachWord(splitPath[4])}{' '}
            {upperCaseEachWord(splitPath[3])}{' '}
            {orderBy === 'endtime-asc' ? 'Ending Soon' : ''}{' '}
            {header === 'Selling' ? 'Latest' : header} Listings |{' '}
            {game.getTitle()} | {game.getSite()}
          </title>
          <meta
            name='description'
            content={`Trade ${upperCaseEachWord(
              splitPath[4]
            )} ${upperCaseEachWord(splitPath[3])} from ${
              game.name
            } on ${game.getSite()}, a peer to peer trading marketplace for ${
              game.name
            } players.`}
          />
        </Helmet>
        <AdSlot name='pb-slot-incontent-1' divId='seeall-ad-1' />
        <div className='container'>
          <ListingsWrapper>
            <Switch>
              <Route
                path={`${path}`}
                render={(routeProps) => (
                  <Listings
                    url={url}
                    header={header}
                    auction={selling === 'auctions'}
                    buying={selling === 'buying'}
                    matching={selling === 'foryou'}
                    filters={[
                      'diy',
                      'free',
                      'makeOffer',
                      'materialsRequired',
                      'properties',
                      'rating',
                      'sort',
                      'touchTrade',
                      'types',
                    ]}
                    grid={gridCount}
                    {...routeProps}
                  />
                )}
              />
            </Switch>
          </ListingsWrapper>
        </div>
        <AdSlot name='pb-slot-incontent-2' divId='seeall-ad-2' />
      </div>
    )
  }
}

export default SeeAll
