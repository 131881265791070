import http from './http'

// PROD
export const TURNSTILE_CAPTCHA_KEY =
  process.env.REACT_APP_STRIPE_ENV === 'production'
    ? process.env.REACT_APP_DEPLOY === 'traderie'
      ? '0x4AAAAAAARhjCYmVCfiEG5g'
      : '0x4AAAAAAARhi7d__6a7t3VS'
    : '1x00000000000000000000BB'

// key used when not using invisible turnstile captcha mode
export const TURNSTILE_MANAGED_KEY =
  process.env.REACT_APP_STRIPE_ENV === 'production'
    ? process.env.REACT_APP_DEPLOY === 'traderie'
      ? '0x4AAAAAAA0BwNWF7LPDo6FN'
      : '0x4AAAAAAA0BvrazfOHTUDNI'
    : '1x00000000000000000000AA'

export const forgotUsername = (email, captcha, site, captchaManaged) => {
  return http.post('/forgotUsername', { email, captcha, site, captchaManaged })
}

export const forgotPassword = (
  email,
  captcha,
  site,
  game,
  admin,
  captchaManaged
) => {
  let type = 'forgot'
  return http.post('/sendHashEmail', {
    email,
    type,
    captcha,
    site,
    game,
    admin,
    captchaManaged,
  })
}

export const signupRedirect = (history) => {
  history.push({
    pathname: '/signup',
    state: { from: history.location.pathname },
  })
}

export const getRedirect = () => {
  const redirect = localStorage.getItem('redirect')
  // As soon as it's used get rid of it
  localStorage.removeItem('redirect')
  return redirect
}

export const setRedirect = (pathname) => {
  localStorage.setItem('redirect', pathname)
}
