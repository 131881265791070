import React, { useContext, useEffect, useRef, useState } from 'react'
import qs from 'qs'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DateTime } from 'luxon'

import GameContext from '../../GameContext'
import UserContext from '../../contexts/UserContext'

import http from '../../services/http'
import { upperCaseEachWord } from '../../services/validate'
import { getWishlists } from '../../components/Items/AddToWishlist'
import { Traderie } from '../../games/icons'

import { DownloadImgBtn } from '../../components/common'
import { StyledSelect } from '../../components/Styled'
import AdSlot from '../../components/AdSlot'
import Checkbox from '../../components/inputs/Checkbox'
import Helmet from '../../components/Helmet'
import Loading from '../../components/Loading'

import SearchBar from './SearchBar'
import ValueList from './ValueList'
import ValueRank from './ValueRank'
import ValueTable from './ValueTable'

import './style.css'

const dateStr = DateTime.now().toFormat('MMMM yyyy')

const StyledValuesFilterBar = styled.div`
  margin-left: ${(props) => props.floatLeft && 'auto'};
`

const Values = ({ history, location, match }) => {
  const { game, routePrefix } = useContext(GameContext)
  const { ui } = game
  const { url } = match
  const { user } = useContext(UserContext)
  const { t } = useTranslation()

  const [items, setItems] = useState([]) // items list structure varies: rank vs list
  // Item bank: used for filtering/searching
  const [itemsStatic, setItemsStatic] = useState([])
  const [loading, setLoading] = useState(true)
  const [wishlists, setWishlists] = useState([])
  const [downloading, setDownloading] = useState(false)

  let currType =
    url.substring(url.lastIndexOf('/') + 1) !== 'values'
      ? url.substring(url.lastIndexOf('/') + 1)
      : ''
  const itemType = ui?.values?.defaultType
  const tiers = ui?.values?.tiers
  const query = qs.parse(location.search.substring(1))
  if (itemType && currType.length === 0) currType = itemType

  const ref = useRef(null)

  useEffect(() => {
    const format = game.ui?.values?.format
    const params = {}
    if (currType !== 'all') params.type = currType
    if (format) params.format = format
    setLoading(true)
    setItems([])
    http.get('/items/values', params).then((res) => {
      const itemsWithRank = [...res.prices].map((item, i) => {
        if (!Array.isArray(item)) {
          if (format === 'rank') {
            item.rank = res.prices.length - i
          } else if (format === 'tiers') {
            let currRank = tiers[0]
            let nextRank = tiers[1]
            let minNextRank = 100
            for (let i = 1; i < tiers.length - 1; i++) {
              if (
                item.user_value >= tiers[i - 1].value &&
                item.user_value < tiers[i].value
              ) {
                minNextRank = tiers[i].value
                currRank = tiers[i - 1]
                nextRank = tiers[i]
              }
            }
            item.rank = currRank.tier
          } else {
            item.rank = i + 1
          }
        }
        return item
      })

      setItemsStatic(itemsWithRank)
      if (Object.keys(query).length > 0) {
        filterItems()
      } else {
        setItems(itemsWithRank)
      }
      setLoading(false)
    })
  }, [currType])

  useEffect(() => {
    if (user) {
      getWishlists(null).then((wishlists) => {
        setWishlists(wishlists)
      })
    }
  }, [])

  useEffect(() => {
    if (itemsStatic.length !== 0) {
      setLoading(true)
      filterItems()
    }
  }, [location.search, itemsStatic.length])

  const onSearch = (search) => {
    let query = qs.parse(location.search.substring(1))
    history.push({
      search: qs.stringify({ ...query, search: search.search.toLowerCase() }),
    })
  }

  const filterItems = async () => {
    let newItems = [...itemsStatic]
    if (Object.keys(query).length > 0) {
      if (query.tags) {
        const format = game.ui?.values?.format
        let params = { selectedTags: query.tags }
        let itemValues = await http.get('/items/values', params)
        itemValues.prices.map((item, i) => {
          if (!Array.isArray(item)) {
            if (format === 'rank') {
              item.rank = itemValues.prices.length - i
            } else {
              item.rank = i + 1
            }
          }
          return item
        })
        newItems = itemValues.prices
      }
      if (query.orderBy) {
        const [sortBy, dir] = query.orderBy.split('-')
        switch (sortBy) {
          case 'value':
            if (dir === 'asc') {
              newItems.sort((a, b) => {
                if (Array.isArray(a) && a.length > 0) {
                  return a[0].user_value - b[0].user_value
                }
                return a.user_value - b.user_value
              })
            } else if (dir === 'desc') {
              newItems.sort((a, b) => {
                if (Array.isArray(a) && a.length > 0) {
                  return b[0].user_value - a[0].user_value
                }
                return b.user_value - a.user_value
              })
            }
            break
          default:
            break
        }
      }
      if (query.search) {
        if (Array.isArray(newItems[0]) && newItems[0].length > 0) {
          const newItemsTemp = []
          newItems.forEach((itemArr) => {
            const itemArrTemp = itemArr.filter((item) =>
              item.name.toLowerCase().includes(query.search)
            )
            if (itemArrTemp.length > 0) newItemsTemp.push(itemArrTemp)
          })
          newItems = newItemsTemp
        } else {
          newItems = newItems.filter((item) =>
            item.name.toLowerCase().includes(query.search)
          )
        }
      }
      if (query.variant) {
        if (Array.isArray(newItems[0]) && newItems[0].length > 0) {
          const newItemsTemp = []
          newItems.forEach((itemArr) => {
            const itemArrTemp = itemArr.filter(
              (item) => item.variant_name === query.variant
            )
            if (itemArrTemp.length > 0) newItemsTemp.push(itemArrTemp)
          })
          newItems = newItemsTemp
        } else {
          newItems = newItems.filter(
            (item) => item.variant_name === query.variant
          )
          newItems.map((item, i) => (item.rank = i + 1))
        }
      }
      if (query.type) {
        if (Array.isArray(newItems[0]) && newItems[0].length > 0) {
          const newItemsTemp = []
          newItems.forEach((itemArr) => {
            const itemArrTemp = itemArr.filter(
              (item) => item[query.type] === true
            )
            if (itemArrTemp.length > 0) newItemsTemp.push(itemArrTemp)
          })
          newItems = newItemsTemp
        } else {
          newItems = newItems.filter((item) => item[query.type] === true)
          newItems.map((item, i) => (item.rank = i + 1))
        }
      }
    }
    setItems(newItems)
    setLoading(false)
  }

  const getFormat = () => {
    if (typeof items === 'undefined') return <Trans i18nKey='noValues' />

    const format = ui.values?.format || ''
    switch (format) {
      case 'table':
        return <ValueTable items={items} config={ui.values} />
      case 'rank':
      case 'tiers':
        return (
          <ValueRank
            items={items}
            location={location}
            match={match}
            tiers={ui?.values?.tiers}
          />
        )
      default:
        return (
          <div className='value-list-container'>
            <ValueList
              history={history}
              items={items}
              location={location}
              match={match}
              query={query}
              wishlists={wishlists}
              loading={loading}
            />
          </div>
        )
    }
  }

  const typeOptions = game.types
    .filter((t) => !t.link && !t.href && t.label !== 'services')
    .map((type) => {
      return { label: t(type.label), value: type.value || type.label }
    })
  typeOptions.push({ label: t('all'), value: 'all' })

  return (
    <div className='values'>
      <Helmet data={{ date: `${dateStr}`, type: currType }} />
      {!downloading && (
        <AdSlot name='pb-slot-incontent-1' divId='nookazon-values-ad-1' />
      )}
      <div className='values-container' ref={ref}>
        <div className='container'>
          <div className='values-header'>
            <h1
              className='values-title'
              style={{
                justifyContent: downloading ? 'center' : '',
                fontSize: downloading ? 40 : '1.5em',
              }}
            >
              <Traderie
                color
                style={{ fontSize: downloading ? 50 : 30, marginRight: 4 }}
              />{' '}
              Traderie {game.name} Value List
            </h1>

            {!downloading && (
              <SearchBar
                onSearch={onSearch}
                history={history}
                location={location}
                loading={loading}
              />
            )}
          </div>
          <div className='values-type-filters'>
            {ui.values?.typeFilter && game.schema === 'adopt_me' && (
              <div className='values-filter'>
                <div className='values-type-select'>
                  <StyledSelect
                    value={typeOptions.find((type) => type.value === currType)}
                    options={typeOptions}
                    onChange={(option) => {
                      delete query['variant']
                      history.push({
                        pathname: `${routePrefix}/values/${option.value}`,
                        search: qs.stringify({ ...query }),
                      })
                    }}
                  />
                </div>
              </div>
            )}
            {ui.values &&
              ((currType && ui.values[`${currType}Variants`]) ||
                ui.values.variants) && (
                <StyledValuesFilterBar
                  floatLeft={ui.values.floatLeft}
                  className='values-variant-filters'
                >
                  {(currType
                    ? ui.values[`${currType}Variants`]
                    : ui.values.variants
                  ).map((variant) => {
                    return (
                      <div className='values-variant-filter'>
                        <Checkbox
                          label={upperCaseEachWord(variant)}
                          style={{ display: 'flex' }}
                          checked={query.variant === variant}
                          onChange={(e) => {
                            const searchQuery = { ...query }
                            if (e.target.checked) {
                              searchQuery['variant'] = variant
                            } else {
                              delete searchQuery['variant']
                            }
                            history.push({
                              search: qs.stringify(searchQuery),
                            })
                          }}
                        />
                      </div>
                    )
                  })}
                </StyledValuesFilterBar>
              )}
            {!downloading && (
              <a
                href='https://discord.com/api/oauth2/authorize?client_id=1120837645166202882&permissions=2048&scope=bot'
                className='btn discord-bot'
                style={{
                  display: 'inline-block',
                  marginLeft: 15,
                  textDecoration: 'none',
                }}
                target='blank'
              >
                Add our Values Bot to your Discord
              </a>
            )}
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div className='value-list'>{getFormat()}</div>
        )}
      </div>
      {ui?.valuesOrder !== 'ascending' && (
        <DownloadImgBtn
          html={ref}
          fileName='Value_List'
          classNameList={['download-img-btn', 'cool-slot', 'values-search-bar']}
          onDownload={() => {
            setDownloading(true)
          }}
          onDownloaded={() => {
            setDownloading(false)
          }}
          style={{ marginBottom: '15px' }}
        />
      )}
      <AdSlot name='pb-slot-incontent-2' divId='nookazon-values-ad-2' />
    </div>
  )
}

export default Values
