import React, { useState } from 'react'
import { StyledSelect } from '../../components/Styled'
import { Checkbox } from '../../components/inputs'
import { WflItem } from '../../types/items'

type Props = {
  item: any
  variant: any
  wflItems: WflItem[]
  setWflItems: Function
  setActive: Function
}

const WFLProperties = ({ item, variant, wflItems, setWflItems, setActive }: Props) => {
  if (!item.properties) return null
  const props = item.properties.filter((p) => p.format?.values)
  const currWflItem = wflItems.find((wflItem) => wflItem.id === item.id) || {}
  const [checked, setChecked] = useState<any>([])
 
  return (
    <div className='value-props'>
      <span>
        {props.map((prop) => {
          let wflItem = {...item}
          const lowerCaseProp = prop.property.toLowerCase()
          switch (prop.type) {
            case 'bool':
              return (
                <Checkbox
                  // @ts-ignore
                  checked={checked.includes(prop.id) || currWflItem[lowerCaseProp]}
                  onChange={(e) => {
                    if (currWflItem === undefined || Object.keys(currWflItem).length === 0) {
                      wflItem[lowerCaseProp] = true 
                      wflItem.prices = [item.prices.find((itemPrice) => itemPrice[lowerCaseProp])]
                      setWflItems(wflItem, variant)
                      setActive(true)
                    } else { 
                      wflItem[lowerCaseProp] = e.target.checked
                      wflItem.amount = 1
                      if (e.target.checked) {
                        wflItem.prices = [item.prices.find((itemPrice) => itemPrice[lowerCaseProp])]
                      } else { 
                        wflItem.prices = [item.prices.find((itemPrice) => !itemPrice[lowerCaseProp])]
                      }
                      setWflItems(wflItem, variant, true)
                    }

                    const newChecked = [...checked]
                    if (newChecked.includes(prop.id)) {
                      const propIdx = newChecked.findIndex((check) => check === prop.id)
                      newChecked.splice(propIdx, 1)
                    } else { 
                      newChecked.push(prop.id)
                    }
                    setChecked(newChecked)
                  }}
                  label={prop.property}
                />
              )
            default:
              return <></>
          }
        })}
      </span>
    </div>
  )
}

export default WFLProperties
