import React, { useContext, useState, useEffect } from 'react'
import { FaTimes } from 'react-icons/fa'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { getUser } from '../../services/users'
import { parseNumber } from '../../services/validate'

import CreateListingActiveProperties from './CreateListingActiveProperties'
import { Checkbox, FileUpload } from '../inputs'
import { StyledInput, StyledSelect } from '../Styled'
import ItemOption from '../Offers/OfferTable/ItemOption'
import GameContext from '../../GameContext'
import Alert from '../Alert'

const ListingOptionsInputs = styled.div`
  display: ${({ theme }) => theme.listingOptionsDisplay || 'flex'};
  flex-wrap: wrap;
  margin-top: 8px;
`

const ListingOptionField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
  border-bottom: ${({ theme }) => theme.listingOptionFieldBorderBottom};
`

const ListingOptionInput = styled(StyledInput)`
  padding: ${({ theme }) => theme.listingOptionInputPadding};
  margin-bottom: 5px;
`

const RemovePropertyBtn = styled.button`
  color: red;
  background: transparent;
  padding: 0;
  min-width: auto;
  &:hover {
    background: transparent;
  }
`

const GreaterAffixImg = styled.img`
  max-width: 20px;
  filter: ${({ active }) => (active ? 'grayscale(0)' : 'grayscale(1)')};
`

const CreateListingProperties = ({
  handleChange,
  isOffer,
  listingProperties,
  noLabel,
  properties,
  propStyle,
  setError,
  style,
}) => {
  const isSearchProps =
    properties.filter((prop) => !prop.format?.group).length > 10
  let defaultProperties = properties.filter((p) => p.format && p.format.default)
  const [activeProperties, setActiveProperties] = useState(
    properties.length <= 5
      ? properties
      : (isSearchProps ? defaultProperties : properties) || []
  )
  const [currAlert, setAlert] = useState('')
  // const [maxProperties, setMaxProperties] = useState(properties.length)
  const user = getUser()
  const { game } = useContext(GameContext)
  const { t } = useTranslation()

  let checkboxProperties = []
  let dropdownProperties = []
  let inputProperties = []
  let imageProperties = []
  let multiSelectProperties = []

  useEffect(() => {
    // Update active properties on item change
    setActiveProperties(
      properties.length <= 5
        ? properties
        : (isSearchProps ? defaultProperties : properties) || []
    )
  }, [JSON.stringify(properties)])

  useEffect(() => {
    // setMaxProperties(properties.length)

    // Fill in default properties
    properties.forEach((property) => {
      let newValue
      if (
        property.default_value !== undefined &&
        property.default_value !== null
      )
        newValue = property.default_value

      if (
        property.item_format &&
        property.item_format.locked !== undefined &&
        property.item_format.locked !== null
      )
        newValue = property.item_format.locked

      if (newValue !== undefined)
        listingProperties[property.id] = {
          id: property.id,
          property: property.property,
          option: newValue,
          type: property.type,
        }
    })
    handleChange({ ...listingProperties })
  }, [])

  const numListingProps = Object.keys(listingProperties).length
  useEffect(() => {
    const newProps = []
    Object.keys(listingProperties).forEach((propId) => {
      const prop = properties.find((prop) => prop.id + '' === propId)
      if (prop && !activeProperties.find((prop) => prop.id + '' === propId))
        newProps.push(prop)
    })
    setActiveProperties([...activeProperties, ...newProps])
  }, [numListingProps])

  const updateProperty = (property, update) => {
    if (update.option !== undefined || update.greaterAffix !== undefined) {
      if (setError) setError('')
      if (property.type === 'array' && property.max) {
        if (update.option.split(', ').length > property.max) {
          const err = t('cannotHaveMaxProps', {
            max: property.max,
            property: property.property,
          })
          return setError ? setError(err) : setAlert(err)
        }
      }

      if (update.multiselect !== undefined) {
        const currIdx = activeProperties.findIndex(
          (prop) => prop.property_id === update.id
        )

        // check if reached max # of properties allowed
        if (update.multiselect.format?.limit) {
          const currActiveProps = activeProperties.filter(
            (prop) => prop.format?.group?.old === update.multiselect.property
          )
          if (currActiveProps.length + 1 > update.multiselect.format.limit) {
            const multiErr = t('cannotHaveMaxProps', {
              max: update.multiselect.format.limit,
              property: update.multiselect.property,
            })
            return setError ? setError(multiErr) : setAlert(multiErr)
          }
        }
        activeProperties[currIdx].format.group.old =
          activeProperties[currIdx].format.group.name
        delete activeProperties[currIdx].format.group.name
        setActiveProperties(activeProperties)
      }

      if (
        property.type === 'number' &&
        property.number &&
        Math.abs(property.number % 1) !== 0 &&
        property.format &&
        !property.format.decimal
      ) {
        const decError = t('propMustBeFullNum', { property: property.property })
        return setError ? setError(decError) : setAlert(decError)
      }

      if (property.type === 'items' || update.type === 'items')
        update.type = 'string'

      listingProperties[property.id] = {
        ...update,
        preferred: property.format && property.format.preferred,
      }
      if (update.multiselect) {
        delete listingProperties[property.id].multiselect
        if (update.multiselect.format?.limit) {
          listingProperties[property.id].group = update.multiselect.property
          listingProperties[property.id].limit =
            update.multiselect.format?.limit
        }
      }

      if (
        !update.option &&
        property.type === 'bool' &&
        property.format?.group?.old
      ) {
        removeProperty(property.id, true)
      }
    } else {
      delete listingProperties[property.id]
    }

    handleAffectedProperties()
    handleChange({ ...listingProperties })
  }

  const loadOptions = (inputValue) => {
    setTimeout(() => {
      return inputValue.map((option) => ({ value: option, label: option }))
    }, 1000)
  }

  const handleAffectedProperties = () => {
    properties.forEach((prop) => {
      if (prop.affected_properties) {
        Object.keys(prop.affected_properties).forEach((aProp) => {
          const currListingProp = Object.values(listingProperties).find(
            (lProp) => lProp.property === aProp
          )
          if (currListingProp) {
            const aPropConfig =
              prop.affected_properties[aProp][currListingProp.option]
            if (aPropConfig && !aPropConfig.active) {
              prop.isDisabled = true
            } else {
              delete prop.isDisabled
            }
          } else {
            delete prop.isDisabled
          }
        })
      }
    })
  }

  const removeProperty = (propId, multiselect) => {
    if (multiselect) {
      const currIdx = activeProperties.findIndex((prop) => prop.id === propId)
      activeProperties[currIdx].format.group.name =
        activeProperties[currIdx].format.group.old
      delete activeProperties[currIdx].format.group.old
      setActiveProperties(activeProperties)
    } else {
      setActiveProperties(
        activeProperties.filter((aProp) => aProp.id !== propId)
      )
    }
    delete listingProperties[propId]
    handleChange({ ...listingProperties })
  }

  if (activeProperties.length > 0) {
    activeProperties
      .filter((prop) => !prop.format?.group?.name)
      .forEach((property) => {
        if (
          !isOffer ||
          (isOffer && (property.format ? !property.format.skipOffer : true))
        ) {
          if (property.type === 'multiselect') {
            const propOptions = activeProperties.filter(
              (prop) => prop.format?.group?.name === property.property
            )
            property.options = propOptions.map((prop) => prop.property)
            dropdownProperties.push(property)
          } else if (
            property.type === 'string' ||
            property.type === 'number' ||
            property.type === 'items'
          ) {
            if (property.options) {
              dropdownProperties.push(property)
            } else {
              inputProperties.push(property)
            }
          } else if (property.type === 'array') {
            multiSelectProperties.push(property)
          } else if (property.type === 'image') {
            imageProperties.push(property)
          } else {
            checkboxProperties.push(property)
          }
        }
      })
  }

  const removeAlert = () => setAlert('')

  return (
    <div
      className='create-listing-section create-listing-properties'
      style={style}
    >
      {!noLabel && !isOffer && (
        <div className='create-listing-title'>
          <Trans i18nKey='listingOptions' />
        </div>
      )}
      <div style={propStyle}>
        {imageProperties?.length > 0 && (
          <ListingOptionsInputs>
            {imageProperties.map((imageProperty) => {
              let currProp = listingProperties[imageProperty.id]
              return (
                <div style={{ marginRight: 5 }}>
                  {currProp && currProp.option ? (
                    <img
                      src={URL.createObjectURL(currProp.option)}
                      alt='uploaded'
                      className='create-listing-image'
                    />
                  ) : (
                    <FileUpload
                      handleUpload={(image, blob) => {
                        if (image) {
                          updateProperty(imageProperty, {
                            id: imageProperty.id,
                            type: 'image',
                            option: blob,
                          })
                          window.dataLayer.push({
                            event: 'createListing',
                            eventProps: {
                              category: 'Create Listing',
                              action: 'Listing Options: Upload Image',
                            },
                            userId: user ? user.id : undefined,
                            email: user ? user.email : undefined,
                          })
                        }
                      }}
                      message={'Click/drag to add a photo to your listing'}
                    />
                  )}
                </div>
              )
            })}
          </ListingOptionsInputs>
        )}
        {checkboxProperties?.length > 0 && (
          <ListingOptionsInputs>
            {checkboxProperties.map((checkboxProperty, i) => {
              const { property, options, item_format } = checkboxProperty

              const dropOptions =
                options && options.length > 0
                  ? [
                      { value: true, label: options[0] },
                      { value: false, label: options[1] },
                    ]
                  : []

              const lockedValue =
                item_format && item_format.locked !== undefined
                  ? item_format.locked
                  : ''
              const isLocked = lockedValue !== ''

              const checkValue = isLocked
                ? lockedValue
                : listingProperties[checkboxProperty.id]
                ? listingProperties[checkboxProperty.id].option
                : ''

              return checkboxProperty.options ? (
                <div className='listing-option-select' key={i}>
                  {property}
                  <StyledSelect
                    value={dropOptions.find((dO) => dO.value === checkValue)}
                    options={dropOptions}
                    isSearchable={false}
                    onChange={(t) => {
                      updateProperty(checkboxProperty, {
                        id: checkboxProperty.id,
                        property: checkboxProperty.property,
                        option: t.value,
                        type: checkboxProperty.type,
                      })
                      window.dataLayer.push({
                        event: 'createListing',
                        eventProps: {
                          category: 'Create Listing',
                          action: 'Listing Options: Checkbox Property',
                        },
                        userId: user ? user.id : undefined,
                        email: user ? user.email : undefined,
                      })
                    }}
                    isDisabled={isLocked}
                  />
                </div>
              ) : (
                <div key={i} className='create-listing-checks'>
                  <Checkbox
                    checked={checkValue}
                    onChange={(e) => {
                      updateProperty(checkboxProperty, {
                        id: checkboxProperty.id,
                        property: checkboxProperty.property,
                        option: e.target.checked,
                        type: checkboxProperty.type,
                      })
                      window.dataLayer.push({
                        event: 'createListing',
                        eventProps: {
                          category: 'Create Listing',
                          action: 'Listing Options: Checkbox Property',
                        },
                        userId: user ? user.id : undefined,
                        email: user ? user.email : undefined,
                      })
                    }}
                    disabled={isLocked}
                    labelClassName='product-pricing-option'
                    label={
                      <>
                        {checkboxProperty.img && (
                          <img
                            src={checkboxProperty.img}
                            alt={checkboxProperty.property}
                            className='bells-icon'
                            style={{
                              verticalAlign: 'middle',
                            }}
                          />
                        )}
                        {property}
                      </>
                    }
                  />
                </div>
              )
            })}
          </ListingOptionsInputs>
        )}
        {(dropdownProperties || multiSelectProperties) && (
          <ListingOptionsInputs>
            {dropdownProperties.map((dropdownProperty, i) => {
              const dropdownValue =
                (listingProperties[dropdownProperty.id]
                  ? listingProperties[dropdownProperty.id].option
                  : dropdownProperty.default_value) || ''
              return (
                <div className='listing-option-select' key={i}>
                  {dropdownProperty.property}
                  <StyledSelect
                    value={
                      dropdownValue
                        ? { value: dropdownValue, label: dropdownValue }
                        : dropdownProperty.options[0]
                    }
                    options={dropdownProperty.options.map((option) => {
                      const payload = {
                        value: option,
                        label: option,
                      }
                      if (dropdownProperty.format?.imgs)
                        payload.img_url = `https://cdn.nookazon.com/${game.schema}/properties/${option}.png`
                      return payload
                    })}
                    components={{ Option: ItemOption }}
                    onChange={(t) => {
                      if (dropdownProperty.type === 'multiselect') {
                        const currProp = activeProperties.find(
                          (prop) => prop.property === t.value
                        )
                        updateProperty(currProp, {
                          id: currProp.property_id,
                          property: currProp.property,
                          option: currProp.type === 'bool' ? true : null,
                          type: currProp.type,
                          multiselect: dropdownProperty,
                        })
                      } else {
                        updateProperty(dropdownProperty, {
                          id: dropdownProperty.id,
                          property: dropdownProperty.property,
                          option: t.value,
                          type: dropdownProperty.type,
                        })
                      }
                      window.dataLayer.push({
                        event: 'createListing',
                        eventProps: {
                          category: 'Create Listing',
                          action: 'Listing Options: Dropdown Property',
                        },
                        userId: user ? user.id : undefined,
                        email: user ? user.email : undefined,
                      })
                    }}
                  />
                </div>
              )
            })}
            {multiSelectProperties.map((multiSelectProperty, i) => {
              const multiValue = listingProperties[multiSelectProperty.id]
                ? listingProperties[multiSelectProperty.id].option
                    .split(', ')
                    .map((op) => ({ value: op, label: op }))
                : multiSelectProperty.default_value || ''
              return (
                <div className='listing-option-multiselect' key={i}>
                  {multiSelectProperty.property}
                  <StyledSelect
                    isMulti
                    isClearable
                    hideSelectedOptions={
                      multiSelectProperty.format
                        ? !multiSelectProperty.format.allowMultiple
                        : true
                    }
                    value={multiValue}
                    options={multiSelectProperty.options
                      .sort()
                      .map((option) => ({
                        value: option,
                        label: option,
                      }))}
                    loadOptions={loadOptions}
                    onChange={(t, action) => {
                      let moves = [...multiValue]
                      if (
                        multiSelectProperty.format &&
                        multiSelectProperty.format.allowMultiple
                      ) {
                        if (
                          action.action === 'select-option' ||
                          action.action === 'deselect-option'
                        ) {
                          moves.push(action.option)
                        }
                        if (action.action === 'remove-value') {
                          const idx = moves.findIndex(
                            (mV) => mV.value === action.removedValue.value
                          )
                          if (idx !== -1) moves.splice(idx, 1)
                        }
                      } else {
                        moves = t || []
                      }
                      updateProperty(multiSelectProperty, {
                        id: multiSelectProperty.id,
                        property: multiSelectProperty.property,
                        option:
                          moves.map((move) => move.label).join(', ') ||
                          undefined,
                        type: 'string',
                      })
                    }}
                    menuPlacement='auto'
                  />
                </div>
              )
            })}
          </ListingOptionsInputs>
        )}
        {inputProperties?.length > 0 && (
          <ListingOptionsInputs>
            {inputProperties.map((inputProperty, i) => {
              let inputPosition = inputProperty?.format?.input_position || 'top'

              const isColor = inputProperty.format?.input === 'color'

              let inputLabel = inputProperty.property
              let inputLabelRight = ''

              const split = inputProperty.property.split('{{value}}')
              if (split.length > 1) {
                inputLabel = split[0]
                inputLabelRight = split[1]
              } else if (inputPosition === 'right') {
                inputLabel = ''
                inputLabelRight = inputProperty.property
              }

              // group comes from CreateListingActiveProperties when added
              const propGroup = inputProperty.group
              let range
              if (propGroup?.value?.includes('-'))
                range = inputProperty.group.value

              const inputValue = listingProperties[inputProperty.id]
                ? listingProperties[inputProperty.id].option
                : inputProperty.default_value ||
                  propGroup?.max ||
                  propGroup?.default ||
                  ''

              const currInput = (
                <ListingOptionInput
                  className='product-input'
                  style={{
                    maxWidth: inputProperty.type === 'number' ? 100 : undefined,
                    width: isColor ? 40 : undefined,
                    height: isColor ? 40 : undefined,
                    padding: isColor ? 0 : undefined,
                    border: isColor ? 'none' : undefined,
                  }}
                  type={
                    inputProperty.format?.input
                      ? inputProperty.format?.input
                      : inputProperty.type === 'number'
                      ? 'number'
                      : ''
                  }
                  // defaultValue={inputProperty.default_value || ''}
                  value={inputValue}
                  onChange={(e) => {
                    const decimal =
                      inputProperty.format && inputProperty.format.decimal
                    if (inputProperty.type === 'number') {
                      let val = parseNumber(e.target.value, decimal)
                      if (
                        (inputProperty.min && val < inputProperty.min) ||
                        (inputProperty.max && val > inputProperty.max)
                      ) {
                        return setAlert(
                          `Input a number between ${
                            inputProperty.min || '0'
                          } and ${inputProperty.max}`
                        )
                      }
                    }
                    let opt =
                      inputProperty.type === 'number'
                        ? parseNumber(e.target.value, decimal)
                        : e.target.value
                    let propertyPayload = {
                      id: inputProperty.id,
                      property: inputProperty.property,
                      option: opt,
                      type: inputProperty.type,
                    }
                    if (inputProperty.greaterAffix)
                      propertyPayload.greaterAffix = inputProperty.greaterAffix
                    updateProperty(inputProperty, propertyPayload)
                    window.dataLayer.push({
                      event: 'createListing',
                      eventProps: {
                        category: 'Create Listing',
                        action: 'Listing Options: Input Property',
                      },
                      userId: user ? user.id : undefined,
                      email: user ? user.email : undefined,
                    })
                  }}
                />
              )

              const hasGreaterAffix =
                game.has('ITEMS:GREATER_AFFIXES') &&
                (inputProperty.item_format?.groups?.Stats ||
                  propGroup?.group.toLowerCase() === 'stats')

              return (
                <ListingOptionField key={i}>
                  <div style={inputProperty.format?.stylesLabel}>
                    {inputLabel &&
                      `${inputLabel + (inputLabelRight ? '' : ' ')}`}
                    {currInput}
                    {inputLabelRight &&
                      `${(inputLabel ? '' : ' ') + inputLabelRight}`}
                    {range && (
                      <span style={{ color: 'gray' }}>&nbsp;{range}</span>
                    )}
                    {hasGreaterAffix && (
                      <span className='greater-affix-opt'>
                        <button
                          className='greater-affix-opt-btn'
                          onClick={() => {
                            if (!listingProperties[inputProperty.id])
                              listingProperties[inputProperty.id] = {}
                            updateProperty(inputProperty, {
                              id: inputProperty.id,
                              property: inputProperty.property,
                              option:
                                listingProperties[inputProperty.id].option,
                              type: inputProperty.type,
                              greaterAffix:
                                !listingProperties[inputProperty.id]
                                  .greaterAffix,
                            })
                            const currProp = activeProperties.findIndex(
                              (p) => p.id === inputProperty.id
                            )
                            if (currProp !== undefined)
                              activeProperties[currProp].greaterAffix =
                                !activeProperties[currProp].greaterAffix
                            setActiveProperties(activeProperties)
                          }}
                        >
                          <GreaterAffixImg
                            src='https://cdn.nookazon.com/diablo4/icons/greateraffix.png'
                            active={inputProperty.greaterAffix}
                          />
                        </button>
                      </span>
                    )}
                  </div>
                  {(inputProperty.format?.group ||
                    (isSearchProps &&
                      (inputProperty.format
                        ? !inputProperty.format.default
                        : true))) && (
                    <div style={{ height: '100%' }}>
                      <RemovePropertyBtn
                        onClick={() => {
                          removeProperty(
                            inputProperty.id,
                            inputProperty.format?.group
                          )
                        }}
                        aria-label='Remove property'
                      >
                        <FaTimes />
                      </RemovePropertyBtn>
                    </div>
                  )}
                </ListingOptionField>
              )
            })}
          </ListingOptionsInputs>
        )}
      </div>
      {properties.length > 8 && (
        <CreateListingActiveProperties
          activeProperties={activeProperties}
          listingProperties={listingProperties}
          loadOptions={loadOptions}
          properties={properties}
          setActiveProperties={setActiveProperties}
          setListingProperties={handleChange}
        />
      )}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default CreateListingProperties
