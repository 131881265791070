import React, {
  forwardRef,
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react'
import Joyride from 'react-joyride'
import styled from 'styled-components'
import { MdOutlineInfo } from 'react-icons/md'
import { Trans, useTranslation } from 'react-i18next'
import { Turnstile } from '@marsidev/react-turnstile'
import { itemHas, getModeLabel } from 'item-mode'

import GameContext from '../../../GameContext'
import UserContext from '../../../contexts/UserContext'
import SocketContext from '../../../contexts/SocketContext'
import { ModalContext } from '../../../contexts'

import http from '../../../services/http'
import { createListing, editListing } from '../../../services/listings'
import { getCreatePreferredProps } from '../../../services/properties'
import { isNumber } from '../../../services/validate'
import {
  TURNSTILE_CAPTCHA_KEY,
  TURNSTILE_MANAGED_KEY,
} from '../../../services/login'
import { webhookErr } from '../../../services/error'
import { hasFinishedTutorial } from '../../../services/tutorial'

import Listings from '../Listings'
import Loading from '../../Loading'
import Tutorial from '../../Tutorial'
import Tooltip from '../../Tooltip'
import OfferTable from '../../Offers/OfferTable'
import ItemSearch from '../../Items/ItemSearch'
import ItemRow from '../../Items/ItemRow'
import PatreonModal from '../../PatreonModal'
import { Checkbox } from '../../inputs'
import {
  StyledInput,
  StyledSelect,
  CancelLink,
  StyledErrorMessage,
  Beacon,
} from '../../Styled'

import Confirm from './Confirm'
import CreateListingProperties from '../CreateListingProperties'
import ListingProperties from '../ListingItem/ListingProperties'
import NotLoggedIn from './NotLoggedIn'
import Suggestions from './Suggestions'
import Title from './Title'
import Img2Listing from '../Img2Listing'

const auctionOptions = (() => {
  let times = []
  times.push({ label: '0.5 (30 minutes)', value: 0.5 })
  for (let i = 1; i < 25; i++) {
    times.push({ label: i, value: i })
  }
  return times
})()

const StyledListingToggle = styled.div`
  flex: 1;
  padding: 10px;
  text-align: center;
  border-top: 3px solid;
  border-bottom: 3px solid;
  border-right: 3px solid;
  border-left: ${({ type }) => (type === 'selling' ? '3px solid' : 0)};
  border-color: ${({ theme }) =>
    theme.listingToggleColor || theme.btnBackground};
  background-color: ${({ theme, filled }) =>
    filled ? theme.listingToggleColor || theme.btnBackground : ''};
  cursor: pointer;
  font-weight: bold;
`

const StyledLiveListing = styled.div`
  font-size: 20px;
  font-weight: bold;
  border-bottom: 3px solid;
  color: ${({ theme }) => theme.listingToggleColor};
  margin-bottom: 10px;
`

const StyledSectionDiv = styled.div`
  background-color: ${({ theme, isCompactView }) =>
    isCompactView ? theme.bodyAlt : 'none'};
`

const BeaconComponent = forwardRef((props, ref) => {
  return (
    <Beacon ref={ref} {...props}>
      <MdOutlineInfo
        style={{ zIndex: 1000, color: '#60bcb5', fontSize: '20px' }}
      />
    </Beacon>
  )
})

const CreateListing = ({
  autoLookingFor,
  close,
  editing,
  header,
  history,
  item: propItem,
  listing: propListing,
  noOr,
  product,
  search,
  variant: propVariant,
}) => {
  const { t } = useTranslation()
  const captchaRefInvisible = useRef(null)
  const captchaRefManaged = useRef(null)
  const topRef = useRef(null)
  const { game, routePrefix } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const socket = useContext(SocketContext)
  const { openModal } = useContext(ModalContext)
  const [items, setItems] = useState(
    (propListing &&
      propListing.prices &&
      propListing.prices.filter((i) => i.item_id)) ||
      []
  )
  const [makeOffer, setMakeOffer] = useState(
    (propListing && propListing.make_offer) || false
  )
  const [acceptListingPrice, setAcceptListingPrice] = useState(
    (propListing && propListing.accept_listing_price) || false
  )
  const [touchTrading, setTouchTrading] = useState(
    (propListing && propListing.touch_trading) || false
  )
  const [needMaterials, setNeedMaterials] = useState(
    (propListing && propListing.need_materials) || false
  )
  const [standingListing, setStandingListing] = useState(
    (propListing && propListing.standing) || false
  )
  const [stockListing, setStockListing] = useState(
    (propListing && propListing.stock) || false
  )
  const [offerBells, setOfferBells] = useState(
    (propListing && propListing.offer_bells) || false
  )
  const [offerNmt, setOfferNmt] = useState(
    (propListing && propListing.offer_nmt) || false
  )
  const offerCurr = {
    offerBells,
    setofferBells: setOfferBells,
    offerNmt,
    setofferNmt: setOfferNmt,
  }
  const [offerWishlist, setOfferWishlist] = useState(
    (propListing && propListing.offer_wishlist) || false
  )
  const [offerWishlistId, setOfferWishlistId] = useState(
    (propListing && propListing.offer_wishlist_id) || ''
  )
  const [acceptWishlist, setAcceptWishlist] = useState(false)
  const [amount, setAmount] = useState((propListing && propListing.amount) || 1)
  const [captcha, setCaptcha] = useState('')
  const [managedWidget, setManagedWidget] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [diy, setDiy] = useState(propVariant && propVariant.name === 'DIY')
  const [endTime, setEndTime] = useState('')
  const [error, setError] = useState('')
  const [free, setFree] = useState(false)
  const [isAuction, setIsAuction] = useState(false)
  const [item, setItem] = useState(propItem || '')
  const [itemList, setItemList] = useState(
    propItem ? [{ ...propItem, quantity: 1 }] : []
  )
  const [isTourRunning, setIsTourRunning] = useState(
    localStorage.getItem('currentTutorial') === 'Listing' &&
      !hasFinishedTutorial('Listing') &&
      !localStorage.getItem('tutorialSkipped')
  )

  // If we want to ever do editing listing properties
  // const [itemProps, setItemProps] = useState('')

  const [variant, setVariant] = useState(propVariant || '')
  const [listingProperties, setListingProperties] = useState(
    getCreatePreferredProps(game.schema) || {}
  )
  const [loading, setLoading] = useState(false)
  const [selling, setSelling] = useState(
    autoLookingFor === false ? autoLookingFor : true
  )
  const [userWishlists, setUserWishlists] = useState([])
  const [wishlist, setWishlist] = useState('')
  const [showProModal, setShowProModal] = useState(false)
  const [run, setRun] = useState(true)

  const isCompactView = game.has('LISTINGS:COMPACT')
  const isMultiListing = game.has('LISTINGS:MULTI')
  // useEffect(() => {
  //   const abortController = new AbortController()
  //   return abortController.abort()
  // }, [])

  if (!user) return <NotLoggedIn header={header} routePrefix={routePrefix} />

  useEffect(() => {
    setVariant(propVariant || '')
  }, [propVariant])

  useEffect(() => {
    setMakeOffer(false)
    if (!autoLookingFor) getWishlists(true)
  }, [autoLookingFor])

  // useEffect(() => {
  //   if (editing)
  //     getItemProperties({ item: item.id, info: true }).then((res) => {
  //       if (res.error) return
  //       setItemProps(res.properties)
  //     })
  // }, [item.id, editing])

  const gameType = game.types.find((t) => t.label === item.type) || {}
  const liveListing = itemHas(item.mode, 'LISTINGS:LIVE')

  const setInitState = () => {
    setItems([])
    setMakeOffer(false)
    setAcceptListingPrice(false)
    setTouchTrading(false)
    setNeedMaterials(false)
    setStandingListing(false)
    setStockListing(false)
    setOfferBells(false)
    setOfferNmt(false)
    setOfferWishlist(false)
    setOfferWishlistId('')
  }

  const setErrorAndScroll = (msg) => {
    topRef.current?.scrollIntoView(true)
    return setError(msg)
  }

  const create = async () => {
    if ((isMultiListing && itemList.length === 0) || (!isMultiListing && !item))
      return setErrorAndScroll(
        'You must choose an item to create a listing for'
      )

    if (
      selling &&
      gameType.variantRequired &&
      (!variant || (variant && !variant.name))
    )
      return setErrorAndScroll('This item requires a variation to be selected')

    if (
      !game.has('LISTINGS:DEFAULT_MAKE_OFFER') &&
      items.length === 0 &&
      !makeOffer &&
      !touchTrading &&
      user &&
      (parseInt(user.reviews) < 5 || parseInt(user.rating) < 3)
    ) {
      return setErrorAndScroll(
        'To list items for free, you must have at least 5 reviews and an overall rating above 3 stars.'
      )
    }

    let newCaptcha = captcha
    if (game.has('LISTINGS:CAPTCHA') && !user.skip_captcha) {
      if (
        captchaRefInvisible?.current &&
        !managedWidget &&
        (captcha === null || captcha === '')
      ) {
        newCaptcha = await captchaRefInvisible.current.getResponsePromise()
        setCaptcha(newCaptcha)
      }
    }

    setLoading(true)
    setError('')

    let payload = {
      acceptListingPrice: itemHas(item.mode, 'LISTINGS:DEFAULT_BUYITNOW')
        ? true
        : acceptListingPrice,
      amount,
      captcha: newCaptcha,
      canBypassCaptcha: user.skip_captcha,
      captchaManaged: managedWidget,
      diy,
      endTime,
      free,
      game,
      item,
      items,
      isAuction,
      listingProperties,
      makeOffer,
      needMaterials,
      offerBells,
      offerNmt,
      offerWishlist: acceptWishlist,
      offerWishlistId,
      selling,
      standingListing: liveListing ? true : standingListing,
      stockListing,
      touchTrading,
      variant,
      wishlist,
    }

    if (editing) {
      if (!propListing.id)
        return setError('Must have an existing listing to edit.')
      payload.listing = propListing.id
      payload.sellerId = propListing.seller_id
      editListing(payload).then((res) => {
        setLoading(false)
        setCaptcha('')
        if (res.error) {
          topRef.current?.scrollIntoView(true)
          if (
            game.has('LISTINGS:CAPTCHA') &&
            (captchaRefInvisible?.current || captchaRefManaged?.current)
          ) {
            captchaRefInvisible?.current.reset()
            captchaRefManaged?.current.reset()
          }
          if (res.error.includes('of this item') && item.type !== 'villagers') {
            return setShowProModal(true)
          } else {
            return setError(res.error)
          }
        }
        setManagedWidget(false)
        setConfirm(true)
      })
    } else {
      if (isMultiListing) payload.listingItems = itemList
      createListing(payload).then((res) => {
        setLoading(false)
        setCaptcha('')
        if (res.error) {
          topRef.current?.scrollIntoView(true)
          if (
            game.has('LISTINGS:CAPTCHA') &&
            (captchaRefInvisible?.current || captchaRefManaged?.current)
          ) {
            captchaRefInvisible?.current.reset()
            captchaRefManaged?.current.reset()
          }
          if (res.error.data?.popup && item.type !== 'villagers') {
            if (res.error.data.popup === 'roblox') {
              openModal({
                title: `Roblox Verification Required`,
                onCancel: () => {},
                onConfirm: () => {
                  history.push(`${routePrefix}/robloxverify`)
                },
                body: (
                  <>
                    <p>
                      You must have your Roblox account verified to list this
                      many items. Click ok for instructions on verifying your
                      account
                    </p>
                  </>
                ),
                label: <Trans i18nKey='yes' />,
              })
            } else {
              return setShowProModal(true)
            }
          } else {
            return setError(res.error)
          }
        }

        if (liveListing) {
          socket.emit('group_create', res.listing)
          history.push(`${routePrefix}/listing/${res.listing}`)
        } else {
          setManagedWidget(false)
          setConfirm(true)
          window.scrollTo(0, 0)
        }

        if (isMultiListing) {
          window.dataLayer.push({
            event: 'createListing',
            eventProps: {
              category: 'Create Multilisting',
              action: 'Complete Add Multilisting',
            },
            userId: user ? user.id : undefined,
            email: user ? user.email : undefined,
          })
        } else {
          window.dataLayer.push({
            event: 'createListing',
            eventProps: {
              category: 'Create Listing',
              action: 'Complete Add Listing',
            },
            userId: user ? user.id : undefined,
            email: user ? user.email : undefined,
          })
        }
      })
    }
  }

  const handleItemsUpdate = (newItems) => {
    setItems(newItems)
  }

  const handleMyItemsUpdate = (newItems) => {
    const limit = game?.limits?.listingItems
    if (limit && newItems.length > limit) {
      const newItemsReplaced = [...newItems]
      const index = newItemsReplaced.length - 2
      if (index > -1) newItemsReplaced.splice(newItemsReplaced.length - 2, 1)
      else newItemsReplaced.splice(newItemsReplaced.length - 1, 1)
      setItemList(newItemsReplaced)
      updateListingProps(newItemsReplaced)
      setError(`You may only have up to ${limit} items.`)
    } else {
      setItemList(newItems)
      updateListingProps(newItems)
    }
  }

  const updateListingProps = (itemList) => {
    const newListingProps = { ...listingProperties }
    for (const it of itemList) {
      if (it.offerProps) {
        for (const prop of it.offerProps) {
          newListingProps[prop.id] = prop
        }
      }
    }
    setListingProperties(newListingProps)
  }

  const getWishlists = (isLookingFor, isOfferWishlist) => {
    let wishlists = [{ value: null, label: 'Any Wishlist' }]
    if (isLookingFor) wishlists = [{ value: '', label: 'Looking For' }]
    http
      .get(
        `/wishlists`,
        { user: user.id, offerWishlist: isOfferWishlist }
        // { signal: this.abortController.signal }
      )
      .then((res) => {
        if (res.error) return setErrorAndScroll(res.error)
        res.wishlists.forEach((wishlist) => {
          wishlists.push({
            value: wishlist.id,
            label: wishlist.name,
          })
        })
        setUserWishlists(wishlists)
      })
  }

  if (confirm)
    return (
      <Confirm
        site={game.getSite()}
        listingProperties={listingProperties}
        close={close}
      />
    )

  if (showProModal)
    return (
      <PatreonModal
        open={showProModal}
        history={history}
        onClose={() => setShowProModal(false)}
        title={t('akrewProNeed')}
        note={t('akrewPro5Listings')}
      />
    )

  const canTouchTrade = item.tags
    ? item.tags.find((tag) => tag.tag === 'catalog')
    : false

  const offerableCurrencies =
    game.currencies.filter((currency) => currency.offerable).length > 0
  const tutorialSteps = [
    {
      target: '.create-listing-sections-container',
      content: 'This is the create listing form!',
      disableBeacon: true,
      spotlightClicks: true,
    },
  ]

  if (game.has('LISTINGS:OCR')) {
    tutorialSteps.push({
      target: '.img-to-listing',
      content:
        'You can upload a screenshot of your in game item for us to read.',
      placement: 'bottom',
      disableBeacon: true,
      spotlightClicks: true,
    })
  }

  if (item.properties) {
    tutorialSteps.push({
      target: '.create-listing-properties',
      content: 'Here you can input specific properties related to the item.',
      placement: 'top',
      disableBeacon: true,
      spotlightClicks: true,
    })
  }

  tutorialSteps.push({
    target: '#create-listing-pricing',
    content: `If you plan on requesting a specific price & don't want to receive offers, click 'Accept Only Listing Price'. If you want users to be able to create their own offers, select 'Ask for Offers'`,
    placement: 'top',
    disableBeacon: true,
    spotlightClicks: true,
  })
  if (makeOffer) {
    tutorialSteps.push({
      target: '#create-listing-accepting-options',
      content: `Here you can select what sort of offers you are willing to accept. ${
        offerableCurrencies
          ? `If you don't select any, all offers will be allowed.`
          : ''
      }`,
      disableBeacon: true,
    })
    if (acceptWishlist) {
      tutorialSteps.push({
        target: '.add-wishlist-select',
        content: `Here you can select which of your wishlists (or any of them!) you want to receive offers from.`,
        disableBeacon: true,
      })
    }
  } else {
    tutorialSteps.push({
      target: '#item-offer-table-select',
      content:
        'If you want to request certain items, search for & add them here.',
      placement: 'top',
      disableBeacon: true,
      spotlightClicks: true,
    })
    if (offerableCurrencies) {
      tutorialSteps.push({
        target: '#currency-offer-table',
        content: 'If you want to request a currrency, input the amount here.',
        disableBeacon: true,
        spotlightClicks: true,
      })
    }
    // tutorialSteps.push({
    //   target: '.and-or-btn',
    //   content: `You can list multiple groups of prices you're open to receiving using this button. Fill out each group the same way as above.`,
    //   disableBeacon: true,
    //   spotlightClicks: true,
    // })
  }

  tutorialSteps.push({
    target: '.create-listing-btn',
    content: `Once you're satisfied with your listing, click 'Add Listing' to set it live. Note your listings will only be visible as long as you are not offline.`,
    disableBeacon: true,
    spotlightClicks: true,
  })

  return (
    <div className='create-listing' ref={topRef}>
      {loading && (
        <div
          style={{
            position: 'absolute',
            padding: '31vh 40vw',
            zIndex: 99,
            cursor: 'not-allowed',
          }}
        >
          <Loading />
        </div>
      )}
      {header && (
        <h1>
          {isCompactView && !header.includes('edit') ? (
            <Trans i18nKey='createATrade' />
          ) : (
            <Trans i18nKey={header} />
          )}
        </h1>
      )}
      <StyledErrorMessage as='div'>{error}</StyledErrorMessage>
      {liveListing ? (
        <StyledLiveListing>
          {' '}
          <Trans i18nKey={getModeLabel(item.mode, 'selling')} />{' '}
        </StyledLiveListing>
      ) : (
        <>
          {!editing && !isMultiListing && (
            <div className='create-listing-section create-listing-selling'>
              <StyledListingToggle
                onClick={() => {
                  window.dataLayer.push({
                    event: 'createListing',
                    eventProps: {
                      category: 'Create Listing',
                      action: 'Toggle Selling',
                    },
                    userId: user ? user.id : undefined,
                    email: user ? user.email : undefined,
                  })
                  setSelling(true)
                  setIsAuction(false)
                }}
                filled={selling && !isAuction}
                style={{
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                  color: selling && !isAuction ? 'white' : '',
                }}
                type='selling'
              >
                <Trans i18nKey={getModeLabel(item.mode, 'selling')} />
              </StyledListingToggle>
              {game.has('LISTINGS:AUCTIONS') && (
                <StyledListingToggle
                  onClick={() => {
                    setSelling(true)
                    setIsAuction(true)
                    setInitState()
                    window.dataLayer.push({
                      event: 'createListing',
                      eventProps: {
                        category: 'Create Listing',
                        action: 'Toggle Auction',
                      },
                      userId: user ? user.id : undefined,
                      email: user ? user.email : undefined,
                    })
                  }}
                  filled={isAuction}
                  style={{ color: isAuction ? 'white' : '' }}
                  id='create-auction-listing'
                >
                  <Trans i18nKey='auction' />
                </StyledListingToggle>
              )}
              <StyledListingToggle
                onClick={() => {
                  getWishlists(true)
                  setSelling(false)
                  setIsAuction(false)
                  setInitState()
                  window.dataLayer.push({
                    event: 'createListing',
                    eventProps: {
                      category: 'Create Listing',
                      action: 'Toggle Looking For',
                    },
                    userId: user ? user.id : undefined,
                    email: user ? user.email : undefined,
                  })
                }}
                filled={!(selling || isAuction)}
                style={{
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  color: selling || isAuction ? '' : 'white',
                }}
                id='create-lookingfor-listing'
              >
                <Trans i18nKey='lookingFor' />
              </StyledListingToggle>
            </div>
          )}
        </>
      )}
      <div
        className={`create-listing-sections-container ${
          isCompactView && 'compact'
        }`}
      >
        {game.has('LISTINGS:OCR') && !editing && (
          <div className='img-to-listing'>
            <Img2Listing
              onParseStart={() => {
                setItem('')
                setListingProperties({})
              }}
              onParse={(item) => {
                setItem(item)
                setListingProperties(item.listingProperties)
              }}
            />
            <div className='or'>
              <div className='or-line'></div>
              <span>or</span>
              <div className='or-line'></div>
            </div>
          </div>
        )}
        {search && (
          <StyledSectionDiv
            className={`create-listing-section ${isCompactView && 'compact'}`}
            id='create-listing-section-item-search'
            isCompactView={isCompactView}
          >
            <Title type='search' selling={selling} />
            {isMultiListing ? (
              <OfferTable
                creating={true}
                handleChange={handleMyItemsUpdate}
                isAuction={isAuction}
                listing={propListing}
                noOr={true}
                myItems
              />
            ) : (
              <>
                <div style={{ marginBottom: '16px', maxWidth: '300px' }}>
                  <ItemSearch
                    secondary={isCompactView}
                    secondaryBgColor={isCompactView}
                    value={item}
                    creating
                    onChange={(item) => {
                      setRun(true)
                      item.diy = false
                      setItem(item)
                      setVariant(item?.variant || {})
                      setListingProperties({})
                    }}
                  />
                </div>
                <div
                  className={`create-listing-selling-container ${
                    isCompactView && 'compact'
                  }`}
                >
                  <span>
                    <ItemRow
                      item={item}
                      onVariantChange={(variant) => {
                        setItem({ ...item, variant })
                        setVariant(variant)
                      }}
                      activeProperties={listingProperties}
                    />
                    {isCompactView && (
                      <>
                        {item.properties && (
                          <CreateListingProperties
                            properties={item.properties}
                            listingProperties={listingProperties}
                            handleChange={setListingProperties}
                            setError={setError}
                            style={{ marginTop: '16px', marginBottom: '0px' }}
                            noLabel
                          />
                        )}
                        <div className='create-listing-section'>
                          {gameType.notice && (
                            <div className='notice'>
                              <b>
                                <Trans i18nKey='notice' />:
                              </b>
                              &nbsp;
                              <Trans
                                i18nKey={
                                  gameType.listingNotice || gameType.notice
                                }
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </span>
                  {isCompactView && (
                    <>
                      {!itemHas(item.mode, 'LISTINGS:NO_AMOUNT') && item && (
                        <div>
                          <StyledInput
                            id='listing-item-amount'
                            className='product-input'
                            style={{ marginBottom: 10 }}
                            value={amount}
                            onChange={(e) => {
                              const { value } = e.target
                              if (isNumber(value)) setAmount(value)
                            }}
                            maxLength={game.has('LISTINGS:BIGINT') ? 19 : 10}
                            minLength={0}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </StyledSectionDiv>
        )}
        {!selling && item && (
          <Listings
            item={item}
            variant={variant}
            history={history}
            location={history.location}
            grid={4}
            hideLoad
            hideView
            size={3}
            suggestion
            vertical={!isCompactView}
          />
        )}
        {editing && (
          <div className='create-listing-section'>
            <Title type='search' selling={selling} />
            <ItemRow
              item={item}
              onVariantChange={(variant) => {
                setItem({ ...item, variant })
                setVariant(variant)
              }}
              activeProperties={listingProperties}
            />
            {propListing.properties && (
              <ListingProperties
                properties={propListing.properties}
                item={item}
              />
            )}
          </div>
        )}
        {!isCompactView && (
          <>
            <div className='create-listing-section'>
              {gameType.notice && (
                <div className='notice'>
                  <b>
                    <Trans i18nKey='notice' />:
                  </b>
                  &nbsp;
                  <Trans i18nKey={gameType.listingNotice || gameType.notice} />
                </div>
              )}
              {!itemHas(item.mode, 'LISTINGS:NO_AMOUNT') && (
                <div>
                  <Title
                    type='amount'
                    mode={getModeLabel(item.mode, 'amount')}
                  />
                  <StyledInput
                    id='listing-item-amount'
                    className='product-input'
                    style={{ marginBottom: 10 }}
                    value={amount}
                    onChange={(e) => {
                      const { value } = e.target
                      if (isNumber(value)) setAmount(value)
                    }}
                    maxLength={game.has('LISTINGS:BIGINT') ? 19 : 10}
                    minLength={0}
                  />
                </div>
              )}
              {game.has('LISTINGS:STANDING') &&
                !gameType.noStanding &&
                !isAuction &&
                selling &&
                !liveListing && (
                  <div>
                    <Tooltip text={t('stockListingDefinition')} width={300}>
                      <Checkbox
                        labelClassName='product-pricing-option'
                        label={<Trans i18nKey='stockListing' />}
                        checked={stockListing}
                        onChange={(e) => {
                          setStockListing(e.target.checked)
                          setStandingListing(false)
                          window.dataLayer.push({
                            event: 'createListing',
                            eventProps: {
                              category: 'Create Listing',
                              action: 'Toggle In Stock Listing',
                            },
                            userId: user ? user.id : undefined,
                            email: user ? user.email : undefined,
                          })
                        }}
                      />
                    </Tooltip>
                    <Tooltip text={t('standingListingDefinition')} width={300}>
                      <Checkbox
                        labelClassName='product-pricing-option'
                        label={<Trans i18nKey='standingListing' />}
                        checked={standingListing}
                        onChange={(e) => {
                          setStandingListing(e.target.checked)
                          setStockListing(false)
                          window.dataLayer.push({
                            event: 'createListing',
                            eventProps: {
                              category: 'Create Listing',
                              action: 'Toggle Standing Listing',
                            },
                            userId: user ? user.id : undefined,
                            email: user ? user.email : undefined,
                          })
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
            </div>
            <div className='create-listing-section'>
              {(item.diy || item.canDiy) && !needMaterials && (
                <Checkbox
                  labelClassName='create-listing-diy'
                  label={
                    <>
                      <Trans i18nKey='diyRecipe' />
                      <img
                        src='https://cdn.nookazon.com/nookazon/icons/recipe.png'
                        alt='category'
                        className='category-icon'
                      />
                    </>
                  }
                  checked={diy}
                  onChange={(e) => {
                    item.diy = e.target.checked
                    item.canDiy = true
                    setDiy(e.target.checked)
                    setItem(item)
                    window.dataLayer.push({
                      event: 'createListing',
                      eventProps: {
                        category: 'Create Listing',
                        action: 'Toggle DIY Recipe',
                      },
                      userId: user ? user.id : undefined,
                      email: user ? user.email : undefined,
                    })
                  }}
                  labelStyle={{ marginTop: '-6px' }}
                />
              )}
            </div>
            {isAuction && (
              <div className='create-listing-section'>
                <Title type='auction' />
                <div className='listing-auction-input'>
                  <div>
                    <Trans i18nKey='durationHours' />
                  </div>
                  <StyledSelect
                    value={auctionOptions.find((aO) => aO.value === endTime)}
                    options={auctionOptions}
                    onChange={(t) => {
                      setEndTime(t.value)
                      window.dataLayer.push({
                        event: 'createListing',
                        eventProps: {
                          category: 'Create Listing',
                          action: 'Auction: Set Auction Duration',
                        },
                        userId: user ? user.id : undefined,
                        email: user ? user.email : undefined,
                      })
                    }}
                    isSearchable={false}
                  />
                </div>
              </div>
            )}
            {item.properties?.length > 0 && (
              <CreateListingProperties
                properties={item.properties}
                listingProperties={listingProperties}
                handleChange={setListingProperties}
                setError={setError}
              />
            )}
          </>
        )}
        {!itemHas(item.mode, 'ITEMS:NO_PRICING') && (
          <StyledSectionDiv
            className={`create-listing-section ${isCompactView && 'compact'} ${
              product && 'product-page'
            }`}
            id='create-listing-pricing'
            isCompactView={isCompactView}
          >
            <Title type='pricing' isAuction={isAuction} selling={selling} />
            <div className='create-listing-checks'>
              {!itemHas(item.mode, 'ITEMS:NO_PRICING_OPTIONS') && (
                <>
                  {!isAuction && canTouchTrade && !diy && (
                    <Checkbox
                      labelClassName='product-pricing-option'
                      label={
                        <>
                          <Trans i18nKey='touchTrading' />?
                        </>
                      }
                      checked={touchTrading}
                      onChange={(e) => {
                        const touchTrading = e.target.checked
                        setTouchTrading(touchTrading)
                        if (touchTrading) {
                          setNeedMaterials(false)
                          setStandingListing(touchTrading)
                        }
                        window.dataLayer.push({
                          event: 'createListing',
                          eventProps: {
                            category: 'Create Listing',
                            action: 'Touch Trading',
                          },
                          userId: user ? user.id : undefined,
                          email: user ? user.email : undefined,
                        })
                      }}
                      labelStyle={{ marginTop: '-6px' }}
                    />
                  )}
                  {user &&
                    game.has('LISTINGS:FREE') &&
                    !isAuction &&
                    user &&
                    parseInt(user.rating) >= 3 &&
                    parseInt(user.reviews) >= 5 && (
                      <Checkbox
                        labelClassName='product-pricing-option'
                        label={<Trans i18nKey='free' />}
                        inputId='listing-free'
                        checked={free}
                        onChange={(e) => {
                          const free = e.target.checked
                          setFree(free)
                          setMakeOffer(false)
                          if (free) {
                            setItems([])
                          } else {
                            setOfferBells(false)
                            setOfferNmt(false)
                            setAcceptWishlist(false)
                            setOfferWishlistId('')
                          }
                        }}
                        labelStyle={{ marginTop: '-3px' }}
                      />
                    )}
                  {!isAuction && !makeOffer && (
                    <Checkbox
                      labelClassName='product-pricing-option'
                      inputId='listing-accept-listing-price'
                      label={<Trans i18nKey='acceptListingPrice' />}
                      checked={acceptListingPrice}
                      onChange={(e) => {
                        setRun(true)
                        setAcceptListingPrice(e.target.checked)
                        window.dataLayer.push({
                          event: 'createListing',
                          eventProps: {
                            category: 'Create Listing',
                            action: 'Accept Only Listing Price',
                          },
                          userId: user ? user.id : undefined,
                          email: user ? user.email : undefined,
                        })
                      }}
                    />
                  )}
                  {!isAuction &&
                    !diy &&
                    (item.diy ||
                      (item.obtained &&
                        item.obtained.includes('Crafting'))) && (
                      <Checkbox
                        labelClassName='product-pricing-option'
                        label={<Trans i18nKey='acceptListingPrice' />}
                        inputId='listing-accept-listing-price'
                        checked={acceptListingPrice}
                        onChange={(e) => {
                          setAcceptListingPrice(e.target.checked)
                          window.dataLayer.push({
                            event: 'createListing',
                            eventProps: {
                              category: 'Create Listing',
                              action: 'Accept Only Listing Price',
                            },
                            userId: user ? user.id : undefined,
                            email: user ? user.email : undefined,
                          })
                        }}
                      />
                    )}
                  {!isAuction &&
                    !diy &&
                    (item.diy ||
                      (item.obtained && item.obtained.includes('Crafting'))) &&
                    !item.tags?.find(
                      (t) => t.tag === 'Nondroppable Materials'
                    ) && (
                      <Checkbox
                        labelClassName='product-pricing-option'
                        label={<Trans i18nKey='materialsRequired' />}
                        checked={needMaterials}
                        onChange={(e) => {
                          setNeedMaterials(e.target.checked)
                          setStandingListing(e.target.checked)
                          window.dataLayer.push({
                            event: 'createListing',
                            eventProps: {
                              category: 'Create Listing',
                              action: 'Materials Needed',
                            },
                            userId: user ? user.id : undefined,
                            email: user ? user.email : undefined,
                          })
                        }}
                      />
                    )}
                </>
              )}
              {!isAuction && !free && !acceptListingPrice && (
                <Checkbox
                  labelClassName='product-pricing-option'
                  label={<Trans i18nKey='askForOffers' />}
                  inputId='listing-ask-for-offers'
                  checked={makeOffer}
                  onChange={(e) => {
                    const makeOffer = e.target.checked
                    setMakeOffer(makeOffer)
                    if (makeOffer) {
                      setItems([])
                    } else {
                      setOfferBells(false)
                      setOfferNmt(false)
                      setAcceptWishlist(false)
                      setOfferWishlistId('')
                    }
                    window.dataLayer.push({
                      event: 'createListing',
                      eventProps: {
                        category: 'Create Listing',
                        action: 'Ask For Offers',
                      },
                      userId: user ? user.id : undefined,
                      email: user ? user.email : undefined,
                    })
                  }}
                />
              )}
            </div>
            {!makeOffer && !free && (
              <div id='items-to-trade'>
                <OfferTable
                  creating={true}
                  handleChange={handleItemsUpdate}
                  isAuction={isAuction}
                  label={
                    selling ? (
                      <Trans i18nKey='itemsYouWant' />
                    ) : (
                      <Trans i18nKey='itemsYoureOffering' />
                    )
                  }
                  listing={propListing}
                  noOr={noOr || false}
                  selling={selling}
                />
              </div>
            )}
            {makeOffer && (
              <div id='create-listing-accepting-options'>
                <div>
                  <b>
                    <Trans i18nKey='accepting' />
                  </b>
                </div>
                {game.site === 'Nookazon' &&
                  game.hasCurrencies() &&
                  game.currencies.map((currency) => {
                    return (
                      <Checkbox
                        labelClassName='product-pricing-option'
                        label={currency.name}
                        key={currency.name}
                        inputId={`product-pricing-option-${currency.key}`}
                        checked={offerCurr[currency.acceptKey]}
                        onChange={(e) => {
                          offerCurr[`set${currency.acceptKey}`](
                            e.target.checked
                          )
                          window.dataLayer.push({
                            event: 'createListing',
                            eventProps: {
                              category: 'Create Listing',
                              action: `Ask For Offers: Currency ${currency.name}`,
                            },
                            userId: user ? user.id : undefined,
                            email: user ? user.email : undefined,
                          })
                        }}
                      />
                    )
                  })}
                <Checkbox
                  labelClassName='product-pricing-option'
                  label={<Trans i18nKey='wishlistItems' />}
                  checked={acceptWishlist}
                  onChange={(e) => {
                    const acceptWishlist = e.target.checked
                    if (acceptWishlist) getWishlists(false, true)
                    setRun(true)
                    setAcceptWishlist(acceptWishlist)
                    window.dataLayer.push({
                      event: 'createListing',
                      eventProps: {
                        category: 'Create Listing',
                        action: 'Ask For Offers: Wishlist Items',
                      },
                      userId: user ? user.id : undefined,
                      email: user ? user.email : undefined,
                    })
                  }}
                />
                {acceptWishlist && (
                  <div className='add-wishlist-select'>
                    <StyledSelect
                      value={offerWishlist.value}
                      options={userWishlists}
                      onChange={(t) => {
                        setOfferWishlist(true)
                        setOfferWishlistId(t.value)
                      }}
                    />
                  </div>
                )}
                {acceptWishlist && item.mode === 'service' && (
                  <div className='product-note'>
                    {!offerCurr.offerBells && !offerCurr.offerNmt ? (
                      <Trans i18nKey='serviceAcceptWishlistOnly' />
                    ) : (
                      <Trans i18nKey='serviceAcceptWishlist' />
                    )}
                  </div>
                )}
              </div>
            )}
          </StyledSectionDiv>
        )}
        {selling === false && isAuction === false && (
          <div className='wishlist-lookingfor-select'>
            <Trans i18nKey='selectWishlist' />
            <StyledSelect
              value={userWishlists.find((uw) => uw.value === wishlist)}
              options={userWishlists}
              onChange={(t) => {
                setRun(true)
                setWishlist(t.value === 'Looking For' ? '' : t.value)
                window.dataLayer.push({
                  event: 'createListing',
                  eventProps: {
                    category: 'Create Listing',
                    action: 'Select Wishlist',
                  },
                  userId: user ? user.id : undefined,
                  email: user ? user.email : undefined,
                })
              }}
            />
          </div>
        )}
      </div>
      {process.env.NODE_ENV === 'production' &&
        game.has('LISTINGS:CAPTCHA') &&
        !user.skip_captcha &&
        !managedWidget && (
          <Turnstile
            siteKey={TURNSTILE_CAPTCHA_KEY}
            ref={captchaRefInvisible}
            options={{
              size: 'invisible',
            }}
            onError={(code) => {
              setManagedWidget(true)
              if (captchaRefInvisible?.current) {
                captchaRefInvisible.current.reset()
              }
              setError('Captcha could not be verified; please try again.')
              webhookErr(`Captcha err code: ${code}`)
            }}
            onExpire={() => {
              if (captchaRefInvisible?.current)
                captchaRefInvisible.current.reset()
            }}
          />
        )}
      {process.env.NODE_ENV === 'production' &&
        game.has('LISTINGS:CAPTCHA') &&
        !user.skip_captcha &&
        managedWidget && (
          <Turnstile
            siteKey={TURNSTILE_MANAGED_KEY}
            ref={captchaRefManaged}
            onSuccess={(token) => setCaptcha(token)}
            onError={(code) => {
              if (captchaRefManaged?.current) captchaRefManaged.current.reset()
              setError('Captcha could not be verified; please try again.')
              webhookErr(`Captcha err code: ${code}`)
            }}
            onExpire={() => {
              if (captchaRefManaged?.current) captchaRefManaged.current.reset()
            }}
          />
        )}
      <div style={{ marginTop: '5px' }}>
        <button
          className='create-listing-btn'
          onClick={create}
          aria-label={
            editing ? 'Editing' : isCompactView ? 'Create Trade' : 'Add Listing'
          }
        >
          <Trans
            i18nKey={
              editing
                ? 'editing'
                : isCompactView
                ? 'createTrade'
                : getModeLabel(item.mode, 'addListing')
            }
          />
        </button>
        <CancelLink onClick={close}>
          <Trans i18nKey='cancel' />
        </CancelLink>
      </div>
      {!loading && <Tutorial steps={tutorialSteps} run={isTourRunning} />}
    </div>
  )
}

export default CreateListing
