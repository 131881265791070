import React, { useState } from 'react'

import http from '../../../services/http'
import can from '../../../services/rbac'

import Modal from '../../Modal'
import User from '../../User'
import Alert from '../../Alert'

const deleteOffer = ({ id }) => {
  return http.del('/offers', { id })
}

const requestDeleteOffer = ({ id }) => {
  return http.del('/offers/request', { id })
}

const denyRequest = ({ id }) => {
  return http.put('/offers/request/deny', { id })
}

const ModActions = ({ offer, updateOffers, adminPanel }) => {
  const [currAlert, setAlert] = useState('')
  const removeAlert = () => {
    setAlert('')
  }

  return (
    <div className='offer-actions'>
      {can('OFFERS:DELETE') && (
        <div className='offer-action-btn-container'>
          <Modal
            title='Delete Offer'
            onConfirm={() => {
              deleteOffer({ id: offer.id }).then((res) => {
                if (res.error)
                  return setAlert(`Error in deleting offer: ${res.error}`)
                updateOffers({ remove: offer.id })
              })
            }}
            label='Confirm'
            buttonLabel='Delete Offer'
            btnStyle={{ marginBottom: 8 }}
            body={
              <div>
                Please ensure that this is a troll offer and should be deleted.
              </div>
            }
            onCancel={() => {}}
            keyDownEnable
          />
          {can('OFFERS:DENY_REQUEST') && offer.request_delete && adminPanel && (
            <Modal
              title='Deny Request'
              onConfirm={() => {
                denyRequest({ id: offer.id }).then((res) => {
                  if (res.error)
                    return setAlert(`Error in denying request: ${res.error}`)
                  updateOffers({ remove: offer.id })
                })
              }}
              label='Yes, dismiss it'
              buttonLabel='Deny Request'
              btnClass='btn-alt'
              body={<div>Are you sure you want to deny this request?</div>}
              onCancel={() => {}}
              keyDownEnable
            />
          )}
          {offer.request_delete && offer.requester_id && adminPanel && (
            <div className='request-delete-info-container'>
              <span style={{ display: 'flex' }}>
                <span style={{ marginRight: '8px' }}>Requested by:</span>
                {offer.requester_id.username ? (
                  <User
                    user={{
                      ...offer.requester_id,
                    }}
                    isAdmin
                  />
                ) : (
                  'N/A'
                )}
              </span>
              <span style={{ display: 'flex' }}>
                <span style={{ marginRight: '8px' }}>Offered by:</span>
                {offer?.buyer?.id && offer?.buyer?.username ? (
                  <User
                    user={{
                      id: offer.buyer.id,
                      username: offer.buyer.username,
                    }}
                    isAdmin
                  />
                ) : (
                  'N/A'
                )}
              </span>
            </div>
          )}
        </div>
      )}
      {can('OFFERS:REQUEST_DELETE') && (
        <div className='offer-action-btn-container'>
          {offer.request_delete ? (
            <div>Delete Requested</div>
          ) : (
            <Modal
              title='Request Delete Offer'
              onConfirm={() => {
                requestDeleteOffer({ id: offer.id }).then((res) => {
                  if (res.error)
                    return setAlert(
                      `Error in requesting deleting offer: ${res.error}`
                    )
                  updateOffers({
                    update: { id: offer.id, data: { request_delete: true } },
                  })
                })
              }}
              label='Confirm'
              buttonLabel='Request Delete'
              btnClass='btn-alt'
              body={
                <div>
                  Please ensure that this is a troll offer and should be
                  deleted.
                </div>
              }
              onCancel={() => {}}
              keyDownEnable
            />
          )}
        </div>
      )}
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default ModActions
