import React, { useState, useEffect, useContext } from 'react'
import { FaDiscord, FaApple, FaGoogle, FaFacebook } from 'react-icons/fa'
import { Trans, useTranslation } from 'react-i18next'
import qs from 'qs'
import scriptjs from 'scriptjs'
import { GoogleLogin } from 'react-google-login'
import { Turnstile } from '@marsidev/react-turnstile'

import GameContext from '../../GameContext'
import { UserContext } from '../../contexts'
import http, { useQuery } from '../../services/http'
import { setJWT, getUser, setUser } from '../../services/users'
import { setDetails } from '../../services/details'
import { TURNSTILE_MANAGED_KEY, getRedirect } from '../../services/login'

import { StyledHyperLink, StyledLink } from '../../components/Styled'
import Alert from '../../components/Alert'
import Helmet from '../../components/Helmet'
import Loading from '../../components/Loading'
import Modal from '../../components/Modal'
import VerifyEmailModal from '../../components/VerifyEmailModal'
import RobloxLogin from '../../components/Login/RobloxLogin'
import { getFrontendQuests } from '../../components/GetDetails'

import LoginForm from './LoginForm'

import './style.css'
import BattleNetLogin from '../../components/Login/BattleNetLogin'

scriptjs.get(
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  () => {
    const params = {
      clientId:
        process.env.REACT_APP_DEPLOY === 'nookazon'
          ? 'org.nookazon.client'
          : 'org.traderie.client',
      redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
      scope: 'email',
    }
    // @ts-ignore
    if (window.AppleID) window.AppleID.auth.init(params)
  }
)

const isProd = process.env.REACT_APP_STRIPE_ENV === 'production'
const isNookazon = process.env.REACT_APP_DEPLOY === 'nookazon'

export const getDiscordUrl = () => {
  let discordUrl = isProd
    ? `https://discord.com/api/oauth2/authorize?client_id=745370510803599461&redirect_uri=https%3A%2F%2Ftraderie.com%2Flogin&response_type=code&scope=identify%20email%20guilds.join&state=traderie&prompt=none`
    : `https://discord.com/api/oauth2/authorize?client_id=745370510803599461&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin&response_type=code&scope=identify%20email%20guilds.join&state=traderie&prompt=none`
  if (isNookazon) {
    discordUrl = isProd
      ? 'https://discord.com/api/oauth2/authorize?client_id=697180254552326174&redirect_uri=https%3A%2F%2Fnookazon.com%2Flogin&response_type=code&scope=identify+email%20guilds.join&state=public&prompt=none'
      : 'https://discord.com/api/oauth2/authorize?client_id=697180254552326174&redirect_uri=http%3A%2F%2Flocalhost%3A9000%2Flogin&response_type=code&scope=identify+email%20guilds.join&state=public&prompt=none'
  }
  return discordUrl
}

export const facebookUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=774572744508640&redirect_uri=${
  process.env.NODE_ENV === 'production'
    ? 'https%3A%2F%2Ftraderie.com%2Flogin'
    : 'http%3A%2F%2Flocalhost%3A3000%2Flogin'
}&state=fb_login&scope=email`

const Login = ({ history, location }) => {
  const [creating, setCreating] = useState(false)
  const [verify, setVerify] = useState(false)
  const [email, setEmail] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [method, setMethod] = useState('')
  const [merging, setMerging] = useState(false)
  const [oauth, setOauth] = useState(false)
  const [oauthUsername, setOauthUsername] = useState('')
  const [currAlert, setAlert] = useState('')

  const { game } = useContext(GameContext)
  const { setUser: setCUser } = useContext(UserContext)

  const { t } = useTranslation()

  const isOverwolf = localStorage.getItem('overwolf')

  const handleGoogleLogin = async (googleData) => {
    if (googleData.error) {
      if (
        googleData.error !== 'popup_closed_by_user' &&
        googleData.error !== 'idpiframe_initialization_failed'
      )
        setAlert(`Failed to login: ${googleData.error}`)
    } else {
      setCreating(true)
      registerGoogleLogin(googleData.tokenId)
    }
  }

  const registerGoogleLogin = (googleToken) => {
    const source = localStorage.getItem('fromRef') || null
    const reroute = localStorage.getItem('loginReroute') || ''
    const user = getUser()

    http
      .post(`/register`, {
        curr_user: user,
        googleToken,
        source,
        currGame: reroute,
      })
      .then((res) => {
        postLogin(res, 'Google', res.sub)
      })
  }

  const postLogin = async (res, method, oauth?: any) => {
    const reroute = localStorage.getItem('loginReroute') || ''

    if (res.error) {
      setCreating(false)
      if (res.error.includes('account already exists')) {
        const newState = {
          merging: true,
          method,
          oauth: oauth || res.oauth,
        }
        localStorage.setItem('oauth_method', method.toLowerCase())
        localStorage.setItem('oauth_id', oauth || res.oauth)
        if (res.oauthUsername) {
          localStorage.setItem('oauth_username', res.oauthUsername)
          // @ts-ignore
          newState.oauthUsername = res.oauthUsername
        }
        if (
          // @ts-ignore
          window.ReactNativeWebView &&
          // @ts-ignore
          window.ReactNativeWebView.postMessage
        ) {
          // @ts-ignore
          newState.merging = 'true'
          // @ts-ignore
          window.ReactNativeWebView.postMessage(JSON.stringify(newState))
        } else {
          setMerging(true)
          setMethod(method)
          setOauth(newState.oauth)
          // @ts-ignore
          if (newState.oauthUsername) setOauthUsername(newState.oauthUsername)
        }
      } else {
        setAlert(res.error)
      }
      history.push('/login')
      return
    }

    setJWT(res.jwt)
    setUser(res.user)
    setCUser(res.user)
    if (res.details) setDetails(res.details)
    localStorage.setItem('currentTutorial', 'Offer')
    await getFrontendQuests()
    localStorage.removeItem('fromRef')

    // @ts-ignore
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage)
      // @ts-ignore
      window.ReactNativeWebView.postMessage(res.jwt)

    const redirect = getRedirect()

    const needsEmail = res.newAccount && !res.user.email
    if (res.newAccount) localStorage.setItem('currentTutorial', 'Offer')

    const loginRedirect = needsEmail
      ? '/complete-profile'
      : redirect
      ? redirect
      : reroute

    // !TODO: change url
    if (isOverwolf) {
      if (window.opener) {
        window.opener.document.location.href = `https://traderie.com${loginRedirect}`
        window.close()
      } else {
        window.document.location.href = `https://traderie.com${loginRedirect}`
      }
    }

    history.push({
      pathname: loginRedirect,
      state: { oauth: res.oauth },
    })
  }

  const verifyEmail = (verify) => {
    setVerify(verify)
  }

  useEffect(() => {
    if (isOverwolf) window.parent.postMessage({ action: 'disableAds' }, '*')

    return () => {
      if (isOverwolf) window.parent.postMessage({ action: 'enableAds' }, '*')
    }
  }, [isOverwolf])

  useEffect(() => {
    let query = useQuery(location.search)
    let state = ''
    // @ts-ignore
    state = query.get('state')
    let reroute = localStorage.getItem('loginReroute') || ''

    const code = query.get('code')

    let apple_login = query.get('apple_login')

    const googleToken = query.get('google_token')
    // @ts-ignore
    const googleApp = JSON.parse(query.get('google_login'))

    let facebookApp = query.get('facebook_app')
    let source = localStorage.getItem('fromRef') || null

    if (code) {
      let newMethod = 'Discord'
      if (state === 'fb_login') newMethod = 'Facebook'
      if (state === 'roblox_login') newMethod = 'Roblox'
      setCreating(true)
      setMethod(newMethod)
      let user = getUser()
      http
        .post(`/register`, {
          curr_user: user,
          code,
          codeVerifier: query.get('codeVerifier'),
          state,
          source,
          facebookApp,
          currGame: reroute,
        })
        .then((res) => postLogin(res, newMethod))
    }
    if (apple_login) {
      setCreating(true)
      setMethod('Apple')
      const user = getUser()
      http
        .post(`/register`, {
          curr_user: user,
          apple_login,
          source,
          currGame: reroute,
        })
        .then((res) => postLogin(res, 'Apple'))
    }
    if (googleToken) {
      setCreating(true)
      setMethod('Google')
      registerGoogleLogin(googleToken)
    }
    if (googleApp) {
      setCreating(true)
      setMethod('Google')
      const user = getUser()
      http
        .post(`/register`, {
          curr_user: user,
          googleApp,
          source,
          currGame: reroute,
        })
        .then((res) => {
          postLogin(res, 'Google', googleApp.sub)
        })
    }
  }, [JSON.stringify(location.search)])

  const isApp = window.localStorage.getItem('app')
  const { code, oauth_token, apple_login } = qs.parse(
    location.search.substring(1)
  )
  let captchaRef

  const removeAlert = () => {
    setAlert('')
    if (currAlert.includes('If your email address matches')) {
      history.push('/login')
    }
  }

  if (creating || (isApp && (code || oauth_token || apple_login)))
    return <Loading />

  return (
    <div className='login'>
      <Helmet />
      <div className='container'>
        {merging && (
          // @ts-ignore
          <Modal
            open={merging}
            onCancel={() => {
              setMerging(false)
            }}
            title={
              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                <Trans i18nKey='mergeAccounts' />
              </div>
            }
            onConfirm={() => {
              http
                .post('/sendMergeEmail', {
                  email,
                  method,
                  oauth,
                  oauthUsername,
                  captcha,
                  captchaManaged: true,
                })
                .then((res) => {
                  if (res.error) {
                    // TODO double check this was actually always wrong with 2 params instead of 1
                    localStorage.removeItem('oauth_method')
                    localStorage.removeItem('oauth_id')
                    localStorage.removeItem('oauth_username')
                    history.push('/login')
                    return setAlert(res.error)
                  }
                  setMerging(false)
                  return setAlert(t('matchEmailToMerge'))
                })
            }}
            label={<Trans i18nKey='mergeAccounts' />}
            style={{ width: 600 }}
            body={
              <div>
                <Trans
                  i18nKey='mergeAccountDescription'
                  values={{ loginMethod: method }}
                />
                <input
                  // TODO check max length prop
                  // @ts-ignore
                  maxlength={500}
                  className='role-input'
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                  style={{ marginBottom: '10px' }}
                />
                <Turnstile
                  // @ts-ignore
                  siteKey={TURNSTILE_MANAGED_KEY}
                  ref={captchaRef}
                  onSuccess={(token) => setCaptcha(token)}
                  onError={() => {
                    if (captchaRef?.current) captchaRef.current.reset()
                  }}
                  onExpire={() => {
                    if (captchaRef?.current) captchaRef.current.reset()
                  }}
                />
              </div>
            }
          />
        )}
        <h1>
          <Trans i18nKey='logIn' />
        </h1>
        {isOverwolf ? (
          <button
            onClick={() => {
              window.open(getDiscordUrl())
            }}
            className='discord-login oauth-link overwolf-login'
            aria-label='Continue with Discord'
          >
            <FaDiscord style={{ marginRight: 5 }} />
            <Trans i18nKey='logInDiscord' />
          </button>
        ) : (
          <a
            href={getDiscordUrl()}
            className='discord-login oauth-link'
            aria-label='Continue with Discord'
          >
            <FaDiscord style={{ marginRight: 5 }} />
            <Trans i18nKey='logInDiscord' />
          </a>
        )}
        <button
          onClick={() => {
            if (isOverwolf) {
              // @ts-ignore
              window.AppleID.auth.signIn({ usePopup: true })
            } else {
              // @ts-ignore
              window.AppleID.auth.signIn()
            }
          }}
          className='apple-login'
          aria-label='Log In with Apple'
        >
          <FaApple style={{ marginRight: 5 }} />
          <Trans i18nKey='logInApple' />
        </button>
        <GoogleLogin
          // @ts-ignore
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              className='google-login'
              aria-label='Log In With Google'
            >
              <FaGoogle style={{ marginRight: 5 }} />{' '}
              <Trans i18nKey='logInGoogle' />
            </button>
          )}
          onSuccess={handleGoogleLogin}
          onFailure={handleGoogleLogin}
          cookiePolicy={'single_host_origin'}
        />
        <RobloxLogin isOverwolf={isOverwolf} />
        {process.env.REACT_APP_DEPLOY === 'traderie' && (
          <BattleNetLogin isOverwolf={isOverwolf} />
        )}
        {isOverwolf ? (
          <a
            onClick={() => window.open(facebookUrl)}
            className='facebook-login oauth-link overwolf-login'
            aria-label='Continue with Facebook'
          >
            <FaFacebook style={{ marginRight: 5 }} />
            <Trans i18nKey='logInFacebook' />
          </a>
        ) : (
          <a
            href={facebookUrl}
            className='facebook-login oauth-link'
            aria-label='Continue with Facebook'
          >
            <FaFacebook style={{ marginRight: 5 }} />
            <Trans i18nKey='logInFacebook' />
          </a>
        )}
        <div className='or'>
          <div className='or-line'></div>
          <span>or</span>
          <div className='or-line'></div>
        </div>
        {game.site !== 'Nookazon' && (
          <span className='nookazon-login-msg'>
            <p style={{ marginBottom: 15, textAlign: 'center' }}>
              <Trans i18nKey='nookazonAccountLogin' /> Just log in.
            </p>
          </span>
        )}
        <LoginForm history={history} verifyEmail={verifyEmail} />
        <span className='sign-up-link'>
          <p>
            <Trans i18nKey='dontHaveAccount' />
            &nbsp;
            <StyledLink
              to={`/signup`}
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              style={{ textDecoration: 'underline' }}
              aria-label='Sign up'
            >
              <Trans i18nKey='signUp' />!
            </StyledLink>
          </p>
        </span>
        <div className='login-switch-container'>
          <StyledLink to='/verify-email' aria-label='Resend Verification'>
            <Trans i18nKey='verifyResend' />
          </StyledLink>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <StyledHyperLink
            href={
              game.site === 'Nookazon'
                ? 'https://bit.ly/nookazonappeals'
                : 'https://bit.ly/TraderieAppealForm'
            }
            aria-label='Appeal a ban'
          >
            <Trans i18nKey='appeal' />
          </StyledHyperLink>
        </div>
        <VerifyEmailModal history={history} open={verify} />
      </div>
      {currAlert !== '' && <Alert onClick={removeAlert} alert={currAlert} />}
    </div>
  )
}

export default Login
